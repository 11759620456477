import transformToPascalCase from '../../Settings/Utilities/TransformToPascalCase';
import { lowerCaseFirstLetter } from '../../Dashboard/utilities/uppercaseFirstLetter';
import createMarkup from '../../Dashboard/helpers/createMarkup';
import { AttachmentLink } from '../../Dashboard/key_updates/KeyUpdateNewContent';
import { useCreateDescriptionSections } from '../../Dashboard/UserContent/ui/PublishedContentPage';
import React from 'react';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';

const ReportsInfluenceContent = (props) => {
  const { currentNew } = props;
  const data = currentNew;
  const { type, attachments, contentVersion } = data;
  const page = TransformFromPascalCase(type).replace(' ', '-');
  const { createDescriptionSections } = useCreateDescriptionSections();
  const descriptionSections = createDescriptionSections({ version: contentVersion });

  return (
    <div className='px-2 specific-content-container'>
      <div className={`user-content-page-main`}>
        <div className=''>
          {descriptionSections.map((item) => {
            const { property, title } = item;
            const content =
              data[
                `${page === 'policy-asks' ? 'policyAsk' : lowerCaseFirstLetter(transformToPascalCase(page))}Content`
              ]?.[property];
            const contentWithoutHTML =
              !!content && typeof content === 'string' ? content.replace(/<\/?[^>]+(>|$)/g, '') : '';
            return (
              <React.Fragment key={`item-${property}`}>
                {!!contentWithoutHTML.trim() && (
                  <>
                    <h3 className='title-h5-bold mb-2'>{title}</h3>
                    <div
                      className='mb-5 paragraph-general content-text-color published-content-description'
                      dangerouslySetInnerHTML={createMarkup(content)}
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
          <div>
            {attachments.length > 0 && (
              <div className='mb-5'>
                <h3 className='title-h5-m-bold mb-3'>Attachments</h3>
                {attachments.map((attachment) => (
                  <AttachmentLink key={`attachment-${attachment}`} url={attachment} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsInfluenceContent;

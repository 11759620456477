import React from 'react';
const FeedNoAvailable = (props) => {
  const { noResults } = props;
  return (
    <div className='feeds-no-available text-center h-100'>
      <p>
        {noResults ? 'No updates found' : 'This feed is no longer available'}. For the latest political updates related
        to your interests, visit{' '}
        <a
          href={`${process.env.REACT_APP_APPURL}?utm_campaign=embed-widget-unavailable`}
          rel='noreferrer'
          target='_blank'
        >
          policymogul.com
        </a>
      </p>
    </div>
  );
};

export default FeedNoAvailable;

import React, { useState, useEffect, useRef } from 'react';
import { useContext } from 'react';
import { initialCrmEmailState, store } from '../../../../Store';
import CrmEmailDiscardPopup from '../CrmEmailDiscardPopup/CrmEmailDiscardPopup';
import _ from 'lodash';
import CrmEmailComponentSkeleton from './CrmEmailComponentSkeleton';
import EmailComponentBody, { getInitialSenderEmailAddress } from './EmailComponentBody';
import EmailComponentTabs from './EmailComponentTabs';
import SentPerformance from './SentPerformance/SentPerformance';
import EmailRecipients from './EmailRecipients/EmailRecipients';
import useGetEmailAddresses from '../useGetEmailAddresses';
import addMetaTags from '../../../utilities/addMetaTags';
import hasAnchorTag from '../../../utilities/hasAnchorTag';
import useEmailComponentFunctions from '../useEmailComponentFunctions';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { useNavigate } from 'react-router-dom-v5-compat';
import useGeneralApiCall from '../../../apiCalls/useGeneralApiCall';
import { useSuggestionFunctions } from '../../../../WritingAssistant/WritingCreate';
import Axios from 'axios';
import useOutsideElement from '../../../utilities/useOutsideElement';
import { applyEmailSignatureToBody } from './emailSignatureFunctions';

const EmailComponent = ({ emailId }) => {
  const params = useParams();
  let { crmEmailId } = params;
  if (emailId) {
    crmEmailId = emailId;
  }
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    currentEmailDetails,
    listDomainsVerified,
    emailStateAfterSave,
    emailSelectedContacts,
    activeTabEmailSent,
    emailAddresses,
    emailPerformanceOnLoad,
    emailPerformanceResult,
    team,
    activePlan,
    showSendPreviewPopUp,
  } = state;
  const { subject } = currentEmailDetails;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingWritingAssistant, setIsLoadingWritingAssistant] = useState(false);
  const [editorOnLoad, setEditorOnLoad] = useState(true);
  // eslint-disable-next-line
  const [leaveEmailPageCondition, setLeaveEmailPageCondition] = useState(true);
  const [changePathConfirmed, setChangePathConfirmed] = useState(false);
  const [showLeavePopUp, setShowLeavePopUp] = useState(null);
  const [initialEmail, setInitialEmail] = useState(emailStateAfterSave);
  const [initialToList, setInitialToList] = useState([]);
  const [searchValueRecipients, setSearchValueRecipients] = useState('');
  const [activeRecipientsOption, setActiveRecipientsOption] = useState('All');
  const performanceSection = location.pathname.includes('email-performance');
  const autofocusFieldFromUrl = useRef(null);
  const { getEmail } = useEmailComponentFunctions();

  const navigate = useNavigate();

  const { subscriptionStatus } = activePlan;
  const trialUser = subscriptionStatus === 'InTrial';
  const componentMounted = useRef(true);

  const emailContainerRef = useRef();

  useGetEmailAddresses();
  const setActiveTabEmailSent = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'activeTabEmailSent',
      value: value,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailComponentOnLoad',
      value: isLoading,
    });
  }, [isLoading]);

  useEffect(() => {
    if (location.state?.prepopulatedActiveTab) {
      setActiveTabEmailSent(location.state.prepopulatedActiveTab);
    }
    if (location.state?.reipientsTab) {
      setActiveRecipientsOption(location.state.reipientsTab);
    }
    if (location.state?.autofocusFieldFromUrl) {
      autofocusFieldFromUrl.current = location.state?.autofocusFieldFromUrl;
    }
  }, [location.state]);

  useEffect(() => {
    if (activeTabEmailSent !== 'Sent') {
      setEditorOnLoad(true);
    }
  }, [activeTabEmailSent]);

  useEffect(() => {
    return () => {
      setActiveTabEmailSent('Email');
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'newContactFromSelect',
        value: null,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'currentEmailDetails',
        value: initialCrmEmailState(),
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailSelectedContacts',
        value: [],
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'listDomainsVerified',
        value: {
          verifiedDomains: [],
          allDomains: [],
          verifiedEmailAddresses: [],
          allEmailAddresses: [],
          domainsProccessing: true,
        },
      });
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setInitialEmail(emailStateAfterSave);
    setInitialToList(
      emailStateAfterSave.sendRecords.map((record) => ({ id: record.crmContactId, email: record.emailAddress }))
    );
  }, [emailStateAfterSave]);

  const resetEmailStateAfterSave = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailStateAfterSave',
      value: initialCrmEmailState(),
    });
  };

  useOutsideElement(
    emailContainerRef,
    (target) => {
      const targetHasLink = target.href ?? target.closest('a')?.href;
      const preparedLink = targetHasLink?.replace(window.location.origin, '');
      if (targetHasLink) {
        if (!leaveEmailPageCondition) {
          setShowLeavePopUp(preparedLink);
        }
      }
    },
    [changePathConfirmed, leaveEmailPageCondition]
  );

  useEffect(() => {
    if (changePathConfirmed && showLeavePopUp) {
      resetEmailStateAfterSave();
      navigate(showLeavePopUp);
    }
  }, [changePathConfirmed]);

  useEffect(() => {
    const allowLeavePage =
      currentEmailDetails.status === 'Sent' ||
      currentEmailDetails.status === 'PartiallySent' ||
      performanceSection ||
      (currentEmailDetails.status !== 'Sent' &&
        currentEmailDetails.status !== 'PartiallySent' &&
        _.isEqual(
          {
            from: initialEmail?.senderEmailAddressId,
            subject: initialEmail?.subject,
            body: initialEmail?.body?.replace(/(?:\r\n|\r|\n)/g, ''),
            emailDomainId: currentEmailDetails.status ? initialEmail?.emailDomainId : 1,
          },
          {
            from: currentEmailDetails?.senderEmailAddressId,
            subject: currentEmailDetails?.subject,
            body: currentEmailDetails?.body?.replace(/(?:\r\n|\r|\n)/g, ''),
            emailDomainId: currentEmailDetails.status ? currentEmailDetails?.emailDomainId : 1,
          }
        ) &&
        _.isEqual(initialToList.sort(), emailSelectedContacts.sort()));
    setLeaveEmailPageCondition(allowLeavePage);
  }, [currentEmailDetails, initialEmail, emailStateAfterSave, emailSelectedContacts, initialToList]);

  useEffect(() => {
    if (!!listDomainsVerified.verifiedDomains.length && currentEmailDetails.emailDomainId === null) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'currentEmailDetails',
        value: {
          ...currentEmailDetails,
          emailDomainId: listDomainsVerified.verifiedDomains[0].id,
        },
      });
    }
    if (
      !listDomainsVerified.verifiedDomains.length &&
      !listDomainsVerified.verifiedEmailAddresses.length &&
      !listDomainsVerified.domainsProccessing &&
      !location.pathname.includes('/email-performance')
    ) {
      navigate('/influence/emails', { replace: true });
    }
  }, [listDomainsVerified]);

  const asyncFn = async (source) => {
    if (activeTabEmailSent === 'Email') {
      setIsLoading(true);
    }
    let hideSideBar = false;
    try {
      if (!listDomainsVerified.domainsProccessing) {
        let result = await getEmail({ emailId: location?.state?.cloneEmailId ?? emailId, source });
        let crmEmailModel = result;
        if (result?.status === 403 || result?.status === 404) {
          navigate('/influence/emails');
        }
        if (!!crmEmailModel) {
          // OP: Add email signature to email
          crmEmailModel.body = applyEmailSignatureToBody({
            emailAddressItem: getInitialSenderEmailAddress(crmEmailModel.senderEmailAddressId, emailAddresses),
            emailBody: crmEmailModel.body,
            disableChange: true,
          });
          setInitialEmail(crmEmailModel);
          setInitialToList(
            crmEmailModel.sendRecords.map((record) => ({ id: record.crmContactId, email: record.emailAddress }))
          );
          if (
            (crmEmailModel.status === 'Sent' || crmEmailModel.status === 'PartiallySent') &&
            !performanceSection &&
            !location?.state?.cloneEmailId
          ) {
            if (location.pathname.startsWith('/influence/emails')) {
              navigate(`/influence/email-performance/${crmEmailModel.crmEmailId}`);
            }
            hideSideBar = true;
          }
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'currentEmailDetails',
            value: location?.state?.cloneEmailId ? handleClone(crmEmailModel) : crmEmailModel,
          });
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'hideInfluenceSidebar',
            value: hideSideBar,
          });
          let title = `${!!crmEmailModel?.subject?.length ? `${crmEmailModel?.subject} - ` : ''}${
            crmEmailModel?.status ? crmEmailModel?.status : 'Email'
          } - PolicyMogul`;
          addMetaTags({ title, hash: null, location, dispatch });
          setIsLoading(false);
        } else {
          let title = `Create new email - Influence - PolicyMogul`;
          addMetaTags({ title, hash: null, location, dispatch });
          //OP: Add email signature to new draft email
          let emailModelWithSignature = initialEmail;
          emailModelWithSignature.body = applyEmailSignatureToBody({
            emailAddressItem: getInitialSenderEmailAddress(null, emailAddresses),
            emailBody: emailModelWithSignature.body,
          });
          setInitialEmail(emailModelWithSignature);
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'currentEmailDetails',
            value: emailModelWithSignature,
          });
          if (
            location?.state?.writingAssistantId &&
            listDomainsVerified?.verifiedDomains?.length &&
            listDomainsVerified.verifiedEmailAddresses.length
          ) {
            let result = await createEmailFromWritingAssistant();
            if (result) {
              setIsLoading(false);
            }
          }
        }
        if (location.pathname === '/influence/emails/create') {
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    try {
      asyncFn(source);
    } catch {}
    return () => {
      source.cancel('Email cancelled by the user');
    };
  }, [crmEmailId, listDomainsVerified.domainsProccessing]);

  const handleFrom = (item) => {
    let newCurrentEmailDetails = {
      ...currentEmailDetails,
      senderEmailAddressId: item?.id,
      senderDisplayName: item?.displayName,
    };
    newCurrentEmailDetails.body = applyEmailSignatureToBody({
      emailAddressItem: item,
      emailBody: currentEmailDetails.body,
    });

    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'currentEmailDetails',
      value: newCurrentEmailDetails,
    });

    const lastSenderEmailAddress = {
      account: team?.myself.emailAddress,
      emailAddressId: item?.id,
    };
    localStorage.setItem('lastSenderEmailAddress', JSON.stringify(lastSenderEmailAddress));
  };

  const handleSubject = (event) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'currentEmailDetails',
      value: {
        ...currentEmailDetails,
        subject: event.target.value,
        title: event.target.value,
      },
    });
  };

  const handleBody = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'currentEmailDetails',
      value: { ...currentEmailDetails, body: value, subject },
    });
  };

  const initialToValue = () => {
    let prepopulatedContacts = null;
    if (location.state) {
      prepopulatedContacts = location.state.prepopulatedContacts;
    }
    if (prepopulatedContacts) {
      return prepopulatedContacts.sort((a, b) => {
        if (a.label[0] > b.label[0]) return 1;
        if (a.label[0] < b.label[0]) return -1;
        return 0;
      });
    } else if (currentEmailDetails.sendRecords.length > 0) {
      return currentEmailDetails.sendRecords.map((record) => {
        return {
          value: record.crmContactId,
          imageId: record.imageId,
          label: record.fullName,
          id: record.crmContactId,
          email: record.emailAddress,
        };
      });
    } else {
      return [];
    }
  };

  const sentEmail =
    !emailPerformanceOnLoad &&
    (currentEmailDetails?.status === 'Sent' || currentEmailDetails?.status === 'PartiallySent');
  const disabledToCondition = sentEmail;
  const bodyConditionDependOnLocation =
    //condition for email-performance page
    (sentEmail &&
      activeTabEmailSent === 'Email' &&
      (!location.state?.prepopulatedActiveTab || location.state?.prepopulatedActiveTab === 'Email')) ||
    //condition for draft/create email page
    (!sentEmail && !emailId);

  const bodyCondition =
    (!isLoading || (isLoading && location?.state?.writingAssistantId)) &&
    (!!listDomainsVerified.verifiedDomains.length || !!listDomainsVerified.verifiedEmailAddresses.length) &&
    !listDomainsVerified.domainsProccessing &&
    ((!emailPerformanceOnLoad && performanceSection) || !performanceSection) &&
    bodyConditionDependOnLocation;

  const performanceCondition =
    activeTabEmailSent === 'Performance' || location.state?.prepopulatedActiveTab === 'Performance';

  const recipientsCondition =
    activeTabEmailSent === 'Recipients' || location.state?.prepopulatedActiveTab === 'Recipients';

  const skeletonCondition =
    (isLoading ||
      editorOnLoad ||
      listDomainsVerified.domainsProccessing ||
      (emailPerformanceOnLoad && performanceSection)) &&
    activeTabEmailSent !== 'Recipients' &&
    activeTabEmailSent !== 'Performance';

  const tabsCondition = emailPerformanceResult.length > 0 && performanceSection;

  //CREATE EMAIL SUBJECT AND BODY FROM WRITING ASSISTANT
  const { generalApiCall } = useGeneralApiCall();
  const { getSuggestion } = useSuggestionFunctions();
  const handleClone = (content) => {
    if (!!content) {
      const {
        body,
        subject,
        title,
        sendRecords,
        senderDisplayName,
        senderEmailAddressId,
        senderEmailAddress,
        sendRecordCount,
      } = content;
      let email = {
        body,
        subject,
        title,
        senderDisplayName,
        senderEmailAddressId,
        senderEmailAddress,
        sendRecordCount,
        sendRecords: location?.state?.notIncludeRecipient ? [] : sendRecords,
      };
      return email;
    }
  };

  const createEmailFromWritingAssistant = async () => {
    setIsLoadingWritingAssistant(true);
    const pathname = `/api/writing-assistant-generated-content/${location?.state?.writingAssistantId}`;
    const method = 'get';
    const results = await generalApiCall({ pathname, method, needsAuthentication: true });
    if (results) {
      let bodyHasSubject = results?.body?.match(/Subject(.*?)\n\n/g);
      let initialBody = !!bodyHasSubject ? results?.body?.replaceAll(/Subject(.*?)\n\n/g, '') : results?.body;
      let bodyContainsDearSentence = initialBody?.match(/Dear(.*?)\n\n/g);
      let resultsBody = bodyContainsDearSentence
        ? initialBody?.replaceAll(
            /Dear(.*?)\n\n/g,
            `<p style="font-family: Lato, sans-serif; font-size: 15px; margin: 0; color: #00122b;">Dear&nbsp;<img id="salutation_text" style="pointer-events: none; margin-bottom: -6px;" contenteditable="false" src="https://cdn.policymogul.com/images/salutation_small.svg" alt="salutation">,&nbsp;</p>`
          )
        : initialBody;

      let emailTitle = bodyHasSubject ? bodyHasSubject[0]?.replace('Subject: ', '') : results?.title;
      let bodyCall = await getSuggestion({
        prompt: `Format this with HTML paragraph tags:${resultsBody}.`,
      });
      let body = bodyCall?.choices?.[0]?.message?.content;

      body = body?.replaceAll(
        '<p>',
        `<p style="font-family: Arial, Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; font-size: 15px; margin: 0; color: #00122b;">`
      );
      body = body?.replaceAll('</p>', '</p><br/>');
      //OP: add email signature to email body
      body = applyEmailSignatureToBody({
        emailAddressItem: getInitialSenderEmailAddress(null, emailAddresses),
        emailBody: body,
      });
      let newEmailState = {
        ...initialCrmEmailState(),
        subject: emailTitle,
        title: emailTitle,
        body: `${body}`,
        updateFromAiAssistant: true,
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'currentEmailDetails', value: newEmailState });
      setIsLoadingWritingAssistant(false);
    }
  };

  return (
    <>
      {showLeavePopUp && (
        <CrmEmailDiscardPopup
          setShowPopup={setShowLeavePopUp}
          discardFunction={() => {
            setChangePathConfirmed(true);
          }}
        />
      )}
      <div
        className={`${sentEmail || performanceSection ? 'main-content-sent-email' : 'main-content px-md-4 pr-xl-5'} ${
          performanceSection ? '' : 'px-md-4 px-3'
        } h-100 email-component-wrapper`}
        ref={emailContainerRef}
      >
        <div className='d-flex flex-column h-100'>
          <div className='row mx-0 h-100'>
            <div className='flex-grow-1 h-100 position-relative' style={isLoading ? { overflow: 'hidden' } : null}>
              {tabsCondition && (
                <EmailComponentTabs
                  activeTab={activeTabEmailSent}
                  setActiveTab={setActiveTabEmailSent}
                  sentQntty={currentEmailDetails?.sendRecordCount}
                  emailId={performanceSection}
                  searchValue={searchValueRecipients}
                  setSearchValue={setSearchValueRecipients}
                />
              )}
              {bodyCondition ? (
                <div className={sentEmail && !performanceSection ? `email-component-body-sent h-100` : 'h-100'}>
                  <EmailComponentBody
                    currentEmailDetails={currentEmailDetails}
                    handleFrom={handleFrom}
                    disabledCondition={sentEmail}
                    disabledToCondition={disabledToCondition}
                    handleSubject={handleSubject}
                    handleBody={handleBody}
                    initialToValue={initialToValue}
                    emailAddresses={emailAddresses}
                    autofocusFieldFromUrl={autofocusFieldFromUrl}
                    setEditorOnLoad={setEditorOnLoad}
                    trialUser={trialUser}
                    setActiveTab={setActiveTabEmailSent}
                    setActiveRecipientsOption={setActiveRecipientsOption}
                    skeletonCondition={skeletonCondition}
                    isLoadingWritingAssistant={isLoadingWritingAssistant}
                    showSendPreviewPopUp={showSendPreviewPopUp}
                  />
                </div>
              ) : (
                <>
                  {(!performanceSection || (performanceSection && activeTabEmailSent === 'Email')) && (
                    <CrmEmailComponentSkeleton />
                  )}
                </>
              )}
              {performanceCondition && (
                <SentPerformance
                  emailId={performanceSection}
                  setActiveRecipientsOption={setActiveRecipientsOption}
                  setActiveTab={setActiveTabEmailSent}
                  hasLink={hasAnchorTag(currentEmailDetails?.body)}
                  currentEmailDetails={currentEmailDetails}
                />
              )}
              {recipientsCondition && (
                <EmailRecipients
                  sendRecords={currentEmailDetails.sendRecords}
                  emailId={performanceSection}
                  searchValue={searchValueRecipients}
                  setSearchValue={setSearchValueRecipients}
                  activeOption={activeRecipientsOption}
                  setActiveOption={setActiveRecipientsOption}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailComponent;

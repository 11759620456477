import React, { useState } from 'react';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import EndTeamTrialModal from './EndTeamTrialModal';
import SetTeamTrialModal from './SetUpdateTeamTrialModal';
import UpdateTeamTrialModal from './SetUpdateTeamTrialModal';
import { normalizedDateUK } from '../../Dashboard/utilities/normalizedDateUK';
import CustomScrollbar from '../../Common/CustomScrollbar';
const TeamPlan = (props) => {
  const { team, endTeamTrialRequest, setTeamTrialRequest, updateTeamTrialRequest } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const [showEndTeamTrialModal, setShowEndTeamTrialModal] = useState(false);
  const [showSetTeamTrialModal, setShowSetTeamTrialModal] = useState(false);
  const [showUpdateTeamTrialModal, setShowUpdateTeamTrialModal] = useState(false);

  const showHideEndTeamTrialModal = () => {
    setShowEndTeamTrialModal(!showEndTeamTrialModal);
  };

  const showHideSetTeamTrialModal = () => {
    setShowSetTeamTrialModal(!showSetTeamTrialModal);
  };

  const showHideUpdateTeamTrialModal = () => {
    setShowUpdateTeamTrialModal(!showUpdateTeamTrialModal);
  };

  const trialEnded = team.subscriptionStatus.toLowerCase() === 'trialended';
  const inTrial = team.subscriptionStatus.toLowerCase() === 'intrial';

  const ShowEndTrialButton = inTrial;
  const ShowSetTrialButton =
    team.subscriptionStatus.toLowerCase() === 'onfreeplan' ||
    team.subscriptionStatus.toLowerCase() === 'trialended' ||
    team.subscriptionStatus.toLowerCase() === 'subscriptionended';

  const ShowUpdateTrialButton = inTrial;
  const showStartExtend = trialEnded ? 'Extend' : 'Start';
  return (
    <>
      <div className='row mx-0 justify-content-between py-4 align-items-center' style={{ height: '84px' }}>
        <h3 className='main-title mb-0'>Plan Information</h3>
      </div>

      <div className='widget-padding'>
        <div
          className='box-top-rounded bg-white admin-general-content p-4'
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
            <div className='container widget-padding'>
              <div className='row'>
                <div className='col-lg-2 col-xs-6'>
                  <b className='nowrap-item'>Plan: </b>
                </div>
                <div className='col-lg-6 col-xs-6'>
                  {team.plan} ({team.planCode})
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-2 col-xs-6'>
                  <b className='nowrap-item'>Plan Status: </b>
                </div>
                <div className='col-lg-6 col-xs-6'>{team.subscriptionStatus}</div>
              </div>
              {inTrial && (
                <div className='row'>
                  <div className='col-lg-2 col-xs-6'>
                    <b className='nowrap-item'>Trial Ends At : </b>
                  </div>
                  <div className='col-lg-6 col-xs-6'>{normalizedDateUK(team.trialEndDate).format('DD MMM, YYYY')}</div>
                </div>
              )}
              <div className='row'>
                {ShowEndTrialButton && (
                  <div className='col-lg-3 col-xs-12'>
                    <button className='btn btn-danger' onClick={showHideEndTeamTrialModal}>
                      End Team Trial
                    </button>
                  </div>
                )}
                {ShowSetTrialButton && (
                  <div className='col-lg-3 col-xs-12'>
                    <button className='btn btn-info' onClick={showHideSetTeamTrialModal}>
                      {showStartExtend} Team Trial
                    </button>
                  </div>
                )}
                {ShowUpdateTrialButton && (
                  <div className='col-lg-3 col-xs-12'>
                    <button className='btn btn-info' onClick={showHideUpdateTeamTrialModal}>
                      Update Team Trial
                    </button>
                  </div>
                )}
              </div>

              <EndTeamTrialModal
                teamId={team.id}
                showModal={showEndTeamTrialModal}
                closeModal={showHideEndTeamTrialModal}
                endTeamTrialRequest={endTeamTrialRequest}
              ></EndTeamTrialModal>

              <SetTeamTrialModal
                team={team}
                showModal={showSetTeamTrialModal}
                closeModal={showHideSetTeamTrialModal}
                actionText={showStartExtend}
                actionTeamTrial={setTeamTrialRequest}
              ></SetTeamTrialModal>

              <UpdateTeamTrialModal
                team={team}
                showModal={showUpdateTeamTrialModal}
                closeModal={showHideUpdateTeamTrialModal}
                actionText={'Update'}
                actionTeamTrial={updateTeamTrialRequest}
              ></UpdateTeamTrialModal>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </>
  );
};

export default TeamPlan;

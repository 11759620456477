import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom-v5-compat';
import normalize from '../../Dashboard/utilities/normalizeString';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import { AdminStore } from '../AdminState';

const useAdminAgencyUser = () => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state;
  const agencyUser = organisation?.planCode?.startsWith('Agency');
  const newAgencyPlan = agencyUser && !!organisation?.planAllowMultipleTeams;

  return { agencyUser, newAgencyPlan };
};

const UserSideNav = (props) => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state;
  const { hubspotCompanyUrl } = organisation ?? {};

  const { teamId } = props;
  const { agencyUser, newAgencyPlan } = useAdminAgencyUser();

  const items = [
    {
      name: 'Users',
      icon: 'team',
    },
    {
      name: agencyUser || newAgencyPlan ? 'Clients' : 'Topics',
      path: newAgencyPlan ? 'clients' : 'topics',
      icon: 'topics',
    },
    {
      name: 'AI summary input',
      path: 'organisation',
      icon: 'suitcase',
    },
    {
      name: 'Subscription',
      icon: 'billing',
      path: 'plan',
    },
    {
      name: 'Email domains',
      icon: 'email',
      path: 'crm',
    },
    {
      name: 'Hubspot page',
      icon: 'hubspot',
      externalUrl: hubspotCompanyUrl,
    },
  ];

  const isMobile = useIsMobile();

  return (
    <div className={`${isMobile ? 'w-100' : 'side-left-element'}`}>
      <div className='sidebar-nav settings-navigation pr-3 py-lg-4'>
        <h3 className='section-sidebar-subtitle pl-2 pl-lg-5 mb-3'>User page</h3>
        <ul className='px-0 policy-list d-flex flex-row flex-lg-column '>
          {items
            .filter(
              (item) =>
                (item.name === 'AI summary input' && !agencyUser && organisation?.planCode) ||
                item.name !== 'AI summary input'
            )
            .map((item, index) => {
              const { name, path, externalUrl } = item;
              const emptyDescriptionCondition =
                !organisation?.teams?.[0]?.organisationDescription && name === 'AI summary input';
              return (
                <li key={`item-${index}`} className='flex-grow-1'>
                  <NavLink
                    to={externalUrl ?? `/admin/organisation/${teamId}/${path ? path : normalize(name)}`}
                    target={externalUrl ? '_blank' : undefined}
                    className={`admin-sidebar-item general-button w-100 text-left d-block px-2 pl-lg-5 sidebar-item setting-item d-flex`}
                  >
                    <span className={`icon-container-${item.icon} ${emptyDescriptionCondition ? 'text-dark-red' : ''}`}>
                      <span className={`political-updates-nav-icon icon-${item.icon}`} />
                    </span>
                    <span className={`${emptyDescriptionCondition ? 'text-dark-red' : ''}`}>{item.name}</span>{' '}
                    {emptyDescriptionCondition && (
                      <span
                        className='icon-warning-circle-thick mr-2 title-h3 ml-1 text-dark-red'
                        style={{ marginTop: '2px' }}
                      ></span>
                    )}
                    {name === 'Hubspot page' && <span className='icon-external-link'></span>}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export { useAdminAgencyUser };
export default UserSideNav;

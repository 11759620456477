import React, { useContext } from 'react';
import { store } from '../../Store.js';
import createEmailAlertName from './Helpers/createEmailAlertName.js';
import createFrequencyName from './Helpers/createFrequencyName.js';
const TopicEmailAlerts = (props) => {
  const { emailAlerts } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { IsSavingKeywordList } = state;
  const disabledEdit = IsSavingKeywordList;

  return (
    <>
      {emailAlerts.length > 0 && (
        <>
          {emailAlerts.map((item, index) => {
            const { frequency, timeOfDay, id, dayOfWeek, paused, alertTypes } = item;
            const slackAlert = alertTypes.includes('Slack');
            const emailAlert = alertTypes.includes('Email');

            return (
              <div
                className={`row mx-0 align-items-center topic-email-alert-container py-2 px-3 my-2 ${disabledEdit ? 'topic-email-disabled' : ''}`}
                key={`alert${id}${index}`}
              >
                <div className='pr-2'>
                  {emailAlert && (
                    <span
                      className='icon-email paragraph-p3 d-inline-block'
                      style={{ verticalAlign: 'middle', margin: '-2px 5px 0px 0px' }}
                    />
                  )}
                  {slackAlert && (
                    <img
                      className='d-inline-block'
                      style={{ verticalAlign: 'middle', width: '19px', margin: '-3px 5px 0px 0px' }}
                      src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                      alt='slack logo'
                    />
                  )}
                </div>

                <p className='mb-0 topics-item-name_alert topic-item-name_alert-individual'>
                  {createEmailAlertName(frequency)}{' '}
                  <span className='main-light-text'>({createFrequencyName(frequency, dayOfWeek, timeOfDay)})</span>
                  {paused && (
                    <span className='text-red ml-2'>
                      <em>Currently paused</em>
                    </span>
                  )}
                </p>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default TopicEmailAlerts;

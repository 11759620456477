import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import useIsMobile from '../../utilities/useIsMobile';
import insertTrackableLinkPopup from '../../helpers/insertTrackableLinkPopup';
import { insertListTinyEditor } from '../../utilities/insertListTinyEditor';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useOutsideElement from '../../utilities/useOutsideElement';
import { Portal } from 'react-portal';
import strip_tags from '../../utilities/stripTags';

const DescriptionTinyEditor = React.forwardRef((props, ref) => {
  const {
    value,
    setValue,
    index,
    placeholder = '',
    onChangeFunction,
    reportWidget,
    additionalSetupFunction,
    editorToEdit,
    minHeight = 223,
    additionalButtons = '',
    selectedColor,
    setSelectedColor,
    keyupfunction,
    modifyItemLayout,
    onPasteFunction,
    onInitFunction,
    addVerticalScroll,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [bookmark, setBookmark] = useState(null);

  const onChange = (content, editor) => {
    if (onChangeFunction) {
      onChangeFunction(content, editor);
    } else {
      setValue(content);
    }
  };
  const isMobile = useIsMobile(480);
  let plugins = [
    'lists',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
    'autoresize',
  ];
  if (!reportWidget) {
    plugins = [...plugins, 'autolink', 'link'];
  }

  return (
    <>
      <div className='position-relative' style={{ minHeight: `${minHeight}px` }}>
        {isLoading && (
          <div
            className='bg-white border rounded position-absolute z-max w-100 left-0 top-0 px-4 py-3'
            style={{ height: `${minHeight}px` }}
          >
            <SkeletonTheme baseColor='#fff'>
              <Skeleton width={'100%'} height={'19px'} />
              <Skeleton width={'80%'} height={'19px'} />
            </SkeletonTheme>
          </div>
        )}
        <Editor
          ref={ref}
          modifyItemLayout={modifyItemLayout}
          selectedColor={selectedColor}
          tinymceScriptSrc={process.env.REACT_APP_APPURL + '/tinymce/tinymce.min.js'}
          licenseKey='gpl'
          onInit={(evt, editor) => {
            if (editorToEdit) {
              editorToEdit.current = editor;
            }
            setIsLoading(false);
            if (onInitFunction) {
              onInitFunction(editor);
            }
          }}
          onFocus={(evt, editor) => {
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
            if (selectedColor !== '676a75') {
              editor.execCommand('mceApplyTextcolor', 'forecolor', `#${selectedColor}`);
            }
          }}
          value={value}
          init={{
            autoresize_bottom_margin: 0,
            height: '100%',
            menubar: false,
            placeholder,
            paste_preprocess: function (pl, o) {
              o.content = strip_tags(o.content, '<b><u><i><p><li><strong>');
            },
            plugins,
            browser_spellcheck: true,
            toolbar: `customBold | customItalic | customBulletList | customNumList ${reportWidget ? '| customColorPicker | insertMacrosButton' : '| customLink'}${additionalButtons}`,
            content_style: `@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Work+Sans:wght@300&display=swap'); .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
              color: #bababa;
            } ${reportWidget ? `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks){min-height: ${minHeight - 75}px; cursor: text;} p{margin: 0; line-height: 1.4; color: #676a75;}` : ''} a{color: ${reportWidget ? '#676a75' : '#0094cc'}; text-decoration: ${reportWidget ? 'none' : 'underline'}} ol, ul{padding-left:24px} body { font-family: Lato, sans-serif; font-size:16px; padding-left:12px; margin-top:12px !important;  line-height: 1.6;  margin-left:${
              isMobile ? 0 : 15
            }px; color:#00122b; ${addVerticalScroll ? 'overflow-y:auto !important' : ''}}`,
            textcolor_rows: '3',
            extended_valid_elements: 'span[*],a[href|target=_blank]',
            toolbar_location: 'top',
            min_height: minHeight,
            link_assume_external_targets: 'http',
            convert_urls: false,
            default_link_target: '_blank',
            target_list: [{ title: 'New page', value: '_blank' }],
            link_title: false,
            anchor_top: false,
            anchor_bottom: false,
            setup: function (editor) {
              editor.on('init', function (e) {
                if (index === 0) {
                  editor.selection.select(editor.getBody(), true);
                  editor.selection.collapse(false);
                  editor.focus();
                }
              });
              editor.on('NodeChange', function (e) {
                if (e.element?.tagName === 'P') {
                  const toolbarButtons = editor.editorContainer.querySelectorAll('.tox-tbtn');
                  toolbarButtons[2]?.classList.remove('tox-tbtn--enabled');
                  toolbarButtons[3]?.classList.remove('tox-tbtn--enabled');
                }
              });
              editor.on('GetSelectionRange', function (e) {
                const toolbarButtons = editor.editorContainer.querySelectorAll('.tox-tbtn');
                if (e.range.commonAncestorContainer?.parentElement?.parentElement?.tagName === 'OL') {
                  toolbarButtons[3].classList.add('tox-tbtn--enabled');
                } else if (e.range.commonAncestorContainer?.parentElement?.parentElement?.tagName === 'UL') {
                  toolbarButtons[2].classList.add('tox-tbtn--enabled');
                }
              });
              editor.on('ExecCommand', function (e) {
                if (e.command === 'mceLink') {
                  let dialog = document.getElementsByClassName('tox-dialog');
                  let dialogBackdrop = document.getElementsByClassName('tox-dialog-wrap__backdrop');
                  setTimeout(() => {
                    insertTrackableLinkPopup(dialog, dialogBackdrop, editor, true);
                  }, 10);
                }
              });
              editor.ui.registry.addToggleButton('customBold', {
                icon: 'bold',
                onAction: (api) => {
                  editor.execCommand('mceToggleFormat', false, 'bold');
                },
                onSetup: function (api) {
                  editor.formatter.formatChanged('bold', function (state) {
                    api.setActive(state);
                  });
                },
              });
              editor.ui.registry.addToggleButton('customItalic', {
                icon: 'italic',
                onAction: (api) => {
                  editor.execCommand('mceToggleFormat', false, 'italic');
                },
                onSetup: function (api) {
                  editor.formatter.formatChanged('italic', function (state) {
                    api.setActive(state);
                  });
                },
              });
              editor.ui.registry.addToggleButton('customLink', {
                icon: 'link',
                onAction: (api) => {
                  editor.execCommand('mceLink', false, 'link');
                },
                onSetup: function (api) {
                  editor.formatter.formatChanged('link', function (state) {
                    api.setActive(state);
                  });
                },
              });
              editor.ui.registry.addToggleButton('customBulletList', {
                icon: 'unordered-list',
                onAction: (api) => {
                  if (api.isActive()) {
                    editor.execCommand('RemoveList');
                  } else {
                    editor.execCommand('InsertUnorderedList', false, {
                      'list-item-attributes': { class: 'mylistitemclass' },
                      'list-attributes': { id: 'mylist', style: 'list-style-type: disc' },
                    });
                  }
                  api.setActive(!api.isActive());
                },
              });
              editor.ui.registry.addToggleButton('customNumList', {
                icon: 'ordered-list',
                onAction: (api) => {
                  if (api.isActive()) {
                    editor.execCommand('RemoveList');
                  } else {
                    editor.execCommand('InsertOrderedList', false, {
                      'list-item-attributes': { class: 'mylistitemclass' },
                      'list-attributes': { id: 'mylist', style: 'list-style-type: decimal' },
                    });
                  }
                  api.setActive(!api.isActive());
                },
              });
              editor.ui.registry.addButton('customColorPicker', {
                icon: 'false',
                text: 'select color',
                onAction: (api) => {
                  const currBookmark = editor.selection.bookmarkManager.getBookmark();
                  setBookmark(currBookmark);
                  setOpenColorPicker(!openColorPicker);
                },
              });

              if (additionalSetupFunction) {
                additionalSetupFunction(editor, ref);
              }
              const descriptionEditor = true;
              editor.on('KeyUp', (e) => {
                insertListTinyEditor(e, editor, descriptionEditor);
                if (keyupfunction) {
                  keyupfunction(e);
                }
              });
              if (onPasteFunction) {
                editor.on('paste', () => {
                  onPasteFunction();
                });
              }
            },
          }}
          onEditorChange={onChange}
        />
      </div>
      {openColorPicker && (
        <Portal>
          <CustomColorPicker
            setOpenColorPicker={setOpenColorPicker}
            editor={editorToEdit?.current}
            bookmark={bookmark}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </Portal>
      )}
    </>
  );
});

const CustomColorPicker = (props) => {
  const { setOpenColorPicker, editor, bookmark, selectedColor, setSelectedColor } = props;
  const colors = [
    '49b16a',
    'f1c410',
    'e03d2c',
    '9b6ead',
    '3997d3',
    '0b9179',
    'e67d22',
    'ba3829',
    '7c4393',
    '2470a2',
    'bdc2c7',
    '95a5a6',
    '7e8c8d',
    '676a75',
    '404453',
  ];
  const parent = editor?.container?.parentElement;
  const btn = parent?.querySelectorAll('.tox-tbtn');
  const customColorBtn = btn?.[4];
  const { top, left, height, width } = customColorBtn?.getBoundingClientRect() ?? {};

  const selectColor = (color) => {
    editor.selection.select(editor.getBody(), true);
    editor.selection.collapse(false);
    editor.focus();
    editor.selection.bookmarkManager.moveToBookmark(bookmark);
    editor.execCommand('mceApplyTextcolor', 'forecolor', `#${color}`);
    setSelectedColor(color);
    if (customColorBtn) {
      let btn = customColorBtn.querySelector('.tox-icon');
      btn.style.backgroundColor = `#${color}`;
    }
    setOpenColorPicker(false);
  };

  const picker = useRef();
  const hideFilters = () => {
    setOpenColorPicker(false);
  };
  useOutsideElement(picker, hideFilters);

  return (
    <>
      <div className='general-close-background z-max' onClick={() => setOpenColorPicker(false)} />
      <div
        ref={picker}
        className='z-max position-absolute commentary-custom-color-picker'
        style={{ top: `${top + height}px`, left: `${left + width / 2 - 75}px` }}
      >
        <div className='d-flex flex-wrap rounded'>
          {colors.map((color) => (
            <button
              key={`picker-${color}`}
              onClick={() => selectColor(color)}
              className={`general-button color-selector ${selectedColor === color ? 'selected' : ''}`}
              style={{ backgroundColor: `#${color}` }}
            ></button>
          ))}
        </div>
      </div>
    </>
  );
};

export default DescriptionTinyEditor;

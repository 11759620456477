import MicroModal from 'micromodal';
import React, { useEffect } from 'react';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import EmailAlertInformation from './EmailAlertInformation';
import createEmailAlertName from './Helpers/createEmailAlertName';

const ResumeTopicEmailAlerts = (props) => {
  const { setOpenTopicEmailAlerts, currentTopicAlerts } = props;
  useEffect(() => {
    MicroModal.show('resume-topic-email-alert', {
      awaitCloseAnimation: true,
    });
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('resume-topic-email-alert');
    setTimeout(() => {
      setOpenTopicEmailAlerts(false);
    }, 300);
  };

  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <div
      className={`modal micromodal-slide topic-modal create-email-alert-simple-modal`}
      id={`resume-topic-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div className='modal__container pt-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
          <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3 mt-3 mt-lg-0'>
            You have {currentTopicAlerts.length} alert{currentTopicAlerts.length > 1 ? 's' : ''} associated with this{' '}
            {topicOrClientWord}
          </h3>
          <div className='px-lg-5 py-4 py-lg-5 px-3'>
            <>
              {currentTopicAlerts.map((item) => {
                const { id, paused, frequency, alertTypes } = item;
                const slackAlert = alertTypes.includes('Slack');
                const emailAlert = alertTypes.includes('Email');
                return (
                  <div className='email-alerts-individual-item resume-email-alert-item d-flex' key={`email-alert${id}`}>
                    <div className='text-right'>
                      {emailAlert && (
                        <span
                          className='icon-email paragraph-p3 d-inline-block'
                          style={{ verticalAlign: 'middle', margin: '-2px 8px 0px 0px' }}
                        />
                      )}
                      {slackAlert && (
                        <img
                          className='d-inline-block'
                          style={{ verticalAlign: 'middle', width: '19px', margin: '-3px 8px 0px 0px' }}
                          src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                          alt='slack logo'
                        />
                      )}
                    </div>
                    <div>
                      <p className='mb-0'>
                        {createEmailAlertName(frequency)}
                        <EmailAlertInformation emailAlert={item} />
                      </p>
                      {paused && (
                        <p className='mb-0 text-red paragraph-p2'>
                          <em>Currently paused</em>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          <div className='border-top px-lg-5 py-3 px-3'>
            <a
              href='/settings/alerts'
              className='simple-link-button general-button open-external-tab-active'
              target='_blank'
              rel='noopener noreferrer'
            >
              Manage alerts
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeTopicEmailAlerts;

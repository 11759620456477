import React, { useState } from 'react';
import { useHeightContainer } from '../../../Dashboard/utilities/useHeightContainer';
import { transformUpcomingEventsAlerts } from '../../../Settings/EmailAlerts/controlEventsAlerts';
import ListOfEmailAlerts from '../../../Settings/EmailAlerts/ui/ListOfEmailAlerts';
import UpcomingEventsList from '../../../Settings/EmailAlerts/ui/UpcomingEventsList';
import AdminCreateEmailAlert from './AdminCreateEmailAlert';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useAdminAgencyUser } from '../UserSideNav';
import EmailAlertsForClients from '../../../Settings/EmailAlerts/ui/EmailAlertsForClients';

const AdminUserPage = (props) => {
  const {
    userInformation,
    pauseEmailAlert,
    teamSubscriptions,
    adminNewsletterControl,
    onlySendRelevant,
    clientsAlerts,
    clients,
    teamIdState,
  } = props;
  const { fullName, emailAddress, alertSettings } = userInformation ?? {};
  const { createUpcomingEvent, deleteUpcomingEvent, onlySendEmailEventsRelevant } = adminNewsletterControl;

  const [heightContainer, containerRef] = useHeightContainer();
  const [isOpenEditEmailAlert, setIsOpenEditEmailAlert] = useState(false);
  const [emailAlertId, setEmailAlertId] = useState('');
  const [teamTopics, setTeamTopics] = useState(props?.teamTopics);
  const { setTeamId } = teamIdState ?? {};

  const { newAgencyPlan } = useAdminAgencyUser();

  return (
    <div className={`pt-4 pt-lg-5`}>
      <div className='settings-box settings-content-component'>
        <div className='section-title padding-topic-component py-4'>
          <div className='row mx-0 justify-content-between align-items-center'>
            <div>
              <h3 className='main-title mb-0'>{fullName}</h3>
              <p className='main-light-text d-flex align-items-center paragraph-p2 email-alerts-user-info mt-3 mt-lg-1 mb-0'>
                <span>Email alerts will be sent to {emailAddress}</span>
              </p>
            </div>
          </div>
        </div>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'main-content-scrollbar content-page-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div className='pb-5'>
            {newAgencyPlan ? (
              <EmailAlertsForClients
                clientsAlerts={clientsAlerts}
                clients={clients}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                setEmailAlertId={setEmailAlertId}
                allowAlerts={
                  teamSubscriptions?.allowWeekdailyAlerts &&
                  teamSubscriptions?.allowWeeklyAlerts &&
                  teamSubscriptions?.allowImmediateAlerts
                }
                setTeamTopics={setTeamTopics}
                setTeamId={setTeamId}
                pauseEmailAlert={pauseEmailAlert}
              />
            ) : (
              <ListOfEmailAlerts
                keywordsLists={teamTopics}
                emailAlerts={alertSettings}
                setEmailAlertId={setEmailAlertId}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                stateExpanded={false}
                pauseEmailAlert={pauseEmailAlert}
                addEmailAlertButton={() => (
                  <div className='d-flex align-items-center'>
                    <button
                      className='general-button simple-link-button pl-3 py-0'
                      onClick={() => {
                        setIsOpenEditEmailAlert(true);
                      }}
                    >
                      + Add an alert
                    </button>
                  </div>
                )}
              />
            )}
            <UpcomingEventsList
              allUpcomingEvents={transformUpcomingEventsAlerts(userInformation?.newsletterSubscriptions)}
              createUpcomingEvent={createUpcomingEvent}
              deleteUpcomingEvent={deleteUpcomingEvent}
              onlySendEmailEventsRelevant={onlySendEmailEventsRelevant}
              allowEventNewsletters={teamSubscriptions?.allowEventNewsletters}
              onlySendRelevant={onlySendRelevant}
              newAgencyPlan={newAgencyPlan}
            />
          </div>
        </CustomScrollbar>
      </div>
      {isOpenEditEmailAlert && (
        <AdminCreateEmailAlert
          {...props}
          keywordsLists={teamTopics}
          emailAlerts={alertSettings}
          emailAlertId={emailAlertId}
          setEmailAlertId={setEmailAlertId}
          setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
        />
      )}
    </div>
  );
};

export default AdminUserPage;

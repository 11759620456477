import MicroModal from 'micromodal';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { store } from '../../../../Store';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import { useRef } from 'react';
import thousandCommas from '../../../helpers/thousandCommas';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import useContactsApiCalls from '../useContactsApiCalls';
import CustomHighlighter from '../../../utilities/CustomHighlighter';

function AddTagsModal({ hideAddTagsModal, selectedCrmContacts, resetSelectedContacts, totalHits }) {
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults, contactsTags, showAddTagsModalCrmContacts } = state;
  const { preselectedContact } = showAddTagsModalCrmContacts;
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { addTagsToContacts } = useContactsApiCalls();

  useEffect(() => {
    MicroModal.show('resume-topic-email-alert', {
      awaitCloseAnimation: true,
    });
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('resume-topic-email-alert');
    setTimeout(() => {
      hideAddTagsModal();
    }, 300);
  };

  const contactsCount = preselectedContact
    ? 1
    : selectedAllVisible.length > 0
      ? selectedAllVisible.length
      : selectedIds.length > 0
        ? selectedIds.length
        : totalHits;

  //API CALL TO ADD TAGS TO CONTACTS
  const addTagsToContactsHandler = async () => {
    setIsLoading(true);
    const contactIds = preselectedContact
      ? [preselectedContact]
      : selectedAllVisible.length > 0
        ? selectedAllVisible
        : selectedIds;
    let result = await addTagsToContacts({ tags: tags.map((tag) => tag.value), contactIds, preselectedContact });
    if (result) {
      createNotification(
        'success',
        `Tags added to ${thousandCommas(contactsCount)} contact${contactsCount > 1 ? 's' : ''} successfully`
      );
      let newCrmContacts;
      if (selectedAll && !preselectedContact) {
        newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
          return {
            ...stakeholder,
            tags: [
              ...stakeholder.tags,
              ...tags.filter((tag) => !stakeholder.tags.includes(tag.value)).map((tag) => tag.value),
            ],
          };
        });
      } else {
        newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
          if (contactIds.includes(stakeholder.id)) {
            return {
              ...stakeholder,
              tags: [
                ...stakeholder.tags,
                ...tags.filter((tag) => !stakeholder.tags.includes(tag.value)).map((tag) => tag.value),
              ],
            };
          } else {
            return stakeholder;
          }
        });
      }

      // ADD TAG TO GLOBAL STORE TOO, TO AVOID RELOAD CONTACTS
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contentResults',
        value: {
          ...contentResults,
          [activeResults]: {
            CrmContacts: {
              ...contentResults[activeResults].CrmContacts,
              hits: newCrmContacts,
            },
          },
        },
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contactsTags',
        value: [...contactsTags, ...tags.filter((tag) => !contactsTags.find((item) => item.value === tag.value))],
      });
      resetSelectedContacts();
      setTags([]);
      closeMicroModal();
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`modal micromodal-slide topic-modal create-download-chart-simple-modal`}
      id={`resume-topic-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{ minHeight: 'unset' }}
        >
          <div>
            <AddTagsContent
              titleText={
                contactsCount > 1
                  ? `${thousandCommas(contactsCount)} contacts`
                  : `${
                      contentResults[activeResults]?.CrmContacts?.hits?.find(
                        (contact) => contact?.id === (preselectedContact ? preselectedContact : selectedIds[0])
                      )?.title
                    }`
              }
              contactsCount={contactsCount}
              isLoading={isLoading}
              addTagsToContacts={addTagsToContactsHandler}
              setTags={setTags}
              tags={tags}
              closeMicroModal={closeMicroModal}
              searchIcon
            />
          </div>

          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
export const defaultRenderLayout = (tagComponents, inputComponent) => {
  return (
    <>
      <div className='d-flex flex-wrap align-items-center' style={{ minHeight: '36px' }}>
        {tagComponents}
        <span></span>
        <span className='flex-grow-1' style={{ flex: '1 1' }}>
          {inputComponent}
        </span>
      </div>
    </>
  );
};

const AddTagsContent = (props) => {
  const {
    titleText,
    tags,
    setTags,
    isLoading,
    addTagsToContacts,
    closeMicroModal,
    contactsCount,
    customContact,
    onChangeCallback,
    autofocus,
    searchIcon,
  } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { contactsTags } = state;

  const selectRef = useRef();

  const createOption = (label) => ({
    label,
    value: label,
  });
  const [options, setOptions] = useState(contactsTags);

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    setTags([...tags, newOption]);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: 'transparent',
      outline: 'none',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'transparent',
      },
      backgroundColor: 'transparent',
      cursor: 'text',
      marginLeft: customContact ? undefined : '40px',
    }),
    option: (styles, props) => {
      const newValue = props.data['__isNew__'];
      return {
        ...styles,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        backgroundColor: props.isFocused ? '#0094cc' : 'transparent',
        cursor: 'pointer',
        color: props.isFocused ? '#fff' : '#00122b',
        fontSize: '16px',
        ':active': {
          ...styles[':active'],
          backgroundColor: 'transparent',
        },
        ':hover': {
          ...styles[':hover'],
          color: '#fff',
          backgroundColor: '#0094cc',
        },
        ':focus': {
          ...styles[':focus'],
          color: newValue ? '#0094cc' : '#fff',
          backgroundColor: '#0094cc',
        },
      };
    },
    indicatorsContainer: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: '120px',
      overflow: 'auto',
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      boxShadow: 'none',
      marginTop: '2px',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: customContact ? '180px !important' : undefined,
      border: customContact ? '0.5px solid #ececec' : undefined,
      borderRadius: customContact ? '5px' : undefined,
      width: customContact ? 'calc(100% + 2px)' : undefined,
      marginLeft: customContact ? '-1px' : undefined,
    }),
    container: (styles) => ({
      ...styles,
      borderBottom: customContact ? undefined : '0.5px solid #ececec',
    }),
  };

  const Option = (props) => {
    const input = props.selectProps.inputValue;
    const value = props.data.value;
    const newValue = props.data['__isNew__'];

    return (
      <components.Option {...props}>
        <div className='select-option-wrapper'>
          {newValue && (
            <span className='react-select-create-tag-message'>
              <span className='icon-tag icon-tag-create-option'></span> Create new tag:&nbsp;
            </span>
          )}
          <CustomHighlighter
            highlightClassName='highlight-text'
            searchWord={input}
            textToHighlight={newValue ? `'${value}'` : value || ''}
          />
        </div>
      </components.Option>
    );
  };
  const CustomMultiValueLabel = (props) => {
    return (
      <div className='tags-select-multivalue-item'>
        <span className='icon-tag icon-tag-value-label'></span>
        <div>
          <components.MultiValueLabel {...props} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectRef.current && autofocus) {
      selectRef.current.focus();
    }
  }, [selectRef.current]);

  return (
    <>
      {!customContact && (
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-4 px-3'>
          {`Add tag${tags.length > 1 ? 's' : ''} to ${titleText}`}
        </h3>
      )}
      <div
        className={`tags-select-creatable ${customContact ? 'tags-select-creatable-custom-contact' : ''}`}
        style={{ height: customContact ? undefined : '350px' }}
      >
        {searchIcon && <span className={`icon-search`}></span>}
        <CreatableSelect
          isMulti
          ref={selectRef}
          isDisabled={isLoading}
          isClearable
          onChange={(newValue) => {
            if (onChangeCallback) onChangeCallback();
            setTags(newValue);
          }}
          onInputChange={() => {
            if (onChangeCallback) onChangeCallback();
          }}
          onCreateOption={handleCreate}
          options={options}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              if (tags.some((tag) => tag.value === e.target.value)) {
                e.target.value = '';
              }
            }
          }}
          value={tags}
          menuIsOpen={customContact ? undefined : true}
          placeholder={'Type to search or create a new tag...'}
          noOptionsMessage={() => null}
          components={{
            Option,
            MultiValueLabel: CustomMultiValueLabel,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
          }}
          styles={colourStyles}
          classNamePrefix={'react-custom-select-tags'}
        />
      </div>
      {!customContact && (
        <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white'>
          <button
            onClick={() => {
              addTagsToContacts(closeMicroModal);
            }}
            className='general-button action-button px-3 py-2'
            disabled={isLoading || tags.length === 0}
          >
            {isLoading ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 auto',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : (
              `Tag ${contactsCount} user${contactsCount > 1 ? 's' : ''}`
            )}
          </button>
        </div>
      )}
    </>
  );
};

export { AddTagsContent };
export const defaultRenderTag = (props) => {
  let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
  return (
    <span
      key={key}
      {...other}
      style={{
        color: `#00122B`,
        paddingRight: true ? '25px' : '10px',
      }}
    >
      {getTagDisplayValue(tag)}
      {!disabled && <button className={`${classNameRemove} general-button`} onClick={(e) => onRemove(key)} />}
    </span>
  );
};
export default AddTagsModal;

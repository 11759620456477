import React, { useEffect, useState } from 'react';
import TransformFromPascalCase from '../Settings/Utilities/TransformFromPascalCase';
import { Navigation, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import normalize from '../Dashboard/utilities/normalizeString';
import 'swiper/css';
import 'swiper/css/navigation';

const MenuItems = (props) => {
  const [swiperRef, setSwiperRef] = useState();
  const {
    resources,
    clickResources,
    typeOfContent,
    clickOnArrow,
    updatePosition,
    resourcesSelected,
    enabled = true,
    design,
  } = props;
  const { barColour } = design ?? {};

  useEffect(() => {
    if (updatePosition && !!swiperRef?.slideTo) {
      let resourcesArray = resources.split(',');
      let position = resourcesArray.indexOf(typeOfContent);
      swiperRef.slideTo(position);
    }
  }, [typeOfContent, swiperRef]);

  useEffect(() => {
    if (!!swiperRef?.update) {
      swiperRef.update();
    }
  }, [resources]);

  const finalResources = resources.split(',')?.filter((item) => item !== '');

  return (
    <>
      {resources !== '' && (
        <div
          className={`menu-embed-container menu-embed-items embed-padding`}
          style={{ backgroundColor: barColour ?? '#f9f9f9' }}
        >
          <ul className='policy-list px-0 mb-0 '>
            <Swiper
              onSwiper={setSwiperRef}
              modules={[Navigation, Mousewheel]}
              slidesPerView={'auto'}
              navigation
              className={`embed-menu-swiper embed-padding`}
              mousewheel
              breakpoints={{
                550: {
                  slidesPerView: 'auto',
                },
              }}
              onSlideChange={(swiper) => {
                if (window.innerWidth < 550 && !!clickOnArrow) {
                  clickOnArrow(swiper.activeIndex);
                }
              }}
              enabled={enabled}
            >
              {finalResources.map((item, index) => (
                <SwiperSlide key={`item${index}`} className={`slide-menu-${normalize(item)}`}>
                  <li>
                    <button
                      className={`general-button menu-item-${normalize(item)} ${typeOfContent === item ? 'active' : ''}`}
                      onClick={async () => {
                        clickResources(item);
                      }}
                    >
                      {item === 'Events' ? 'Upcoming events' : TransformFromPascalCase(item)}{' '}
                      {resourcesSelected ? resourcesSelected(item) : ''}
                    </button>
                  </li>
                </SwiperSlide>
              ))}
            </Swiper>
          </ul>
        </div>
      )}
    </>
  );
};

export default MenuItems;

import React, { useRef, useState } from 'react';
import { useContext } from 'react';
import { ContentUnauthenticatedInstructions } from '../../../Settings/EmailDomains/ui/EmailDomainContainer';
import { store } from '../../../Store';
import useCollapse from 'react-collapsed';
import AddEmailDomainForm from '../../../Settings/EmailDomains/ui/AddEmailDomainForm';
import { useHeightContainer } from '../../utilities/useHeightContainer';
import ShareInstructionsButton from '../../../Settings/EmailDomains/ui/ShareInstructionsButton';
import DomainUnauthanticatedHeader from '../../../Settings/EmailDomains/ui/DomainUnauthanticatedHeader';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useEmailDomainFunctions from '../../hooks/useEmailDomainFunctions';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';

const SendEmailFromDifferentAddress = ({ domainsList, loadInitialInformation, allowAddDomain }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const [isExpanded, setOpen] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className={`unauthenticated-section`}>
        <p
          className={`no-domains-action ${newAgencyPlan ? 'no-domains-action-agency' : ''}`}
          data-value={isExpanded ? 'expanded' : ''}
          data-tooltip-content={
            allowAddDomain
              ? undefined
              : `Only team owners and admins can register new domains and sender email addresses`
          }
          data-tooltip-id={'settings-tooltip'}
          data-tooltip-class-name={'add-domain-tooltip'}
          {...getToggleProps({
            onClick: () => (allowAddDomain ? setOpen((prev) => !prev) : undefined),
          })}
          tabIndex={-1}
          style={{ cursor: allowAddDomain ? undefined : 'default' }}
        >
          {newAgencyPlan
            ? `Set up your first email sender for ${team?.teamName}`
            : `Send emails from a different email address`}
        </p>
        <div {...getCollapseProps()} className='no-domains-add-domain-form'>
          <AddEmailDomainForm noDomainsPage domainsList={domainsList} loadInitialInformation={loadInitialInformation} />
        </div>
      </div>
    </>
  );
};

function EmailsOnBoarding() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, listDomainsVerified } = state;
  const [heightContainer, containerRef] = useHeightContainer();

  const emailDomains = useRef();
  emailDomains.current = listDomainsVerified.allDomains;

  const [emailAddresses, setEmailAddresses] = useState(listDomainsVerified.allEmailAddresses);

  const { myself } = team;
  const allowAddDomain = myself.role === 'Admin' || myself.role === 'Owner';

  const [addNewDomainProccessing, setAddNewDomainProccessing] = useState(false);
  const { addDomainSenderRequest, getEmailAddressesCall, addEmailDomainListRequest } = useEmailDomainFunctions();
  const { newAgencyPlan } = useTopicOrClientWord();

  const handleAddEmailAddress = async () => {
    setAddNewDomainProccessing(true);
    let displayName = team.myself.fullName;
    let domainName = team.myself.emailAddress.split('@')[1];
    let userName = team.myself.emailAddress.split('@')[0];
    let senderResult;
    let result = await addEmailDomainListRequest({
      domainName,
      isAdminPage: false,
      teamId: team.teamId,
    });
    if (result?.id) {
      senderResult = await addDomainSenderRequest({
        emailDomainId: result.id,
        displayName,
        userName,
        isAdminPage: false,
      });
    }
    if (senderResult) {
      await getEmailAddressesRequest();
    }
    setAddNewDomainProccessing(false);
  };

  const getEmailAddressesRequest = async () => {
    const emailDomains = await getEmailAddressesCall();
    setEmailAddresses(emailDomains);
  };

  const сontentUnauthenticatedInstructionsDomain = (domains) => {
    const sortedDomains = domains.sort(function (a, b) {
      return a.id - b.id;
    });
    return sortedDomains[0];
  };

  const unverifiedEmail =
    !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length;

  return (
    <div className='contact-list-scrollbar-table-no-data contact-list-scrollbar-table-no-data-emails'>
      <div ref={containerRef} />
      <CustomScrollbar
        className={'main-content-scrollbar content-page-scrollbar'}
        style={{ height: `${heightContainer}px` }}
        maximalThumbYSize={100}
      >
        <div className='emails-onboarding'>
          <div className='domains-header'>
            <p className='domains-title'>
              Get set up to send emails {newAgencyPlan ? ` on behalf of ${team?.teamName}` : ''}
            </p>
            <p className='domains-subtitle'>
              {!!emailDomains.current.length
                ? 'This typically takes a few minutes with a bit of technical know-how'
                : 'This will enable you to send emails from your own email address'}
            </p>
          </div>
          <div
            className='domains-unauthenticated-container'
            style={{
              backgroundColor:
                !!emailAddresses.length && !emailDomains.current.length ? 'rgba(32, 128, 30, 0.23)' : undefined,
            }}
          >
            <DomainUnauthanticatedHeader
              domain={!!emailDomains.current.length}
              confirmed={!!emailAddresses.length && !emailDomains.current.length}
            />
            {!listDomainsVerified.domainsProccessing && (
              <>
                {!emailDomains.current.length && !emailAddresses.length && (
                  <div className='no-domains-action-container'>
                    {!newAgencyPlan && (
                      <div
                        className='unauthenticated-section default-add-domain'
                        onClick={allowAddDomain ? handleAddEmailAddress : undefined}
                        data-tooltip-content={
                          allowAddDomain
                            ? undefined
                            : `Only team owners and admins can register new domains and sender email addresses`
                        }
                        data-tooltip-id={'settings-tooltip'}
                        data-tooltip-class-name={'add-domain-tooltip'}
                      >
                        <p
                          className='no-domains-action-active'
                          style={{
                            cursor: allowAddDomain ? undefined : 'default',
                          }}
                        >
                          Send emails from {team.myself?.emailAddress}
                        </p>
                        {addNewDomainProccessing ? (
                          <i
                            className='fas fa-spinner fa-spin'
                            style={{
                              color: '#0094cc',
                              marginTop: '2px',
                            }}
                          ></i>
                        ) : (
                          <button
                            className='back-to-emails-button'
                            style={{
                              cursor: allowAddDomain ? undefined : 'default',
                            }}
                          />
                        )}
                      </div>
                    )}
                    <SendEmailFromDifferentAddress
                      domainsList={emailDomains.current}
                      loadInitialInformation={getEmailAddressesRequest}
                      allowAddDomain={allowAddDomain}
                    />
                  </div>
                )}
                {unverifiedEmail && (
                  <>
                    {!!emailDomains.current.length && (
                      <>
                        <ShareInstructionsButton
                          emailDomain={сontentUnauthenticatedInstructionsDomain(emailDomains.current)}
                        />
                        <div className='unauthenticated-section'>
                          <ContentUnauthenticatedInstructions
                            emailDomain={сontentUnauthenticatedInstructionsDomain(emailDomains.current)}
                            statusText={
                              сontentUnauthenticatedInstructionsDomain(emailDomains.current).verificationStatus ===
                              'Success'
                                ? 'Verified'
                                : сontentUnauthenticatedInstructionsDomain(emailDomains.current).verificationStatus
                            }
                          />
                        </div>
                      </>
                    )}
                    {!!emailAddresses.length &&
                      !emailDomains.current.length &&
                      emailAddresses.map((email) => {
                        let statusText = email.isVerified ? 'Authenticated' : 'Unauthenticated';
                        return (
                          <div className='unauthenticated-section'>
                            <div className='unauthenticated-section-instruction'>
                              <div className='section-instruction-title'>
                                <span>
                                  {email.userName}@{email.crmDomainName}
                                </span>
                                <span className='domain-status' data-value={statusText}>
                                  {statusText}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </CustomScrollbar>
      <ReactTooltip
        id={'settings-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'add-domain-tooltip'}
        backgroundColor={'#00122B'}
        textColor={'#fff'}
        multiline={true}
        arrowColor={'transparent'}
        place={'top'}
      />
    </div>
  );
}

export default EmailsOnBoarding;

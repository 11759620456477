import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import RelevantStakeholders from './ui/RelevantStakeholders';
import useCallMostRelevantStakeholders from './useCallMostRelevantStakeholders';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';

const RelevantStakeholdersContainer = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists } = state;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const [currentlyProcessing, setCurrentlyProcessing] = useState({ attempt: 0, state: false });
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const noTopicParameter = topicParameter === 'none';

  const [isLoading, setIsLoading] = useState(!noTopicParameter);
  const [stakeholdersData, setStakeholdersData] = useState({ graphData: [], images: [] });
  const isLoadedFirstTime = useRef(noTopicParameter);
  const mountedStakeholders = useRef(true);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { callRelevantStakeholders } = useCallMostRelevantStakeholders();
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();
  const loadData = useRef();
  loadData.current = async (source) => {
    try {
      if (!noTopicParameter && !!topicParameter) {
        if (!currentlyProcessing.state) {
          setIsLoading(true);
        }
        let topic = topicParameter
          ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
          : keywordsListsToUse[0];
        let results = await callRelevantStakeholders({ topic, source });
        if (mountedStakeholders.current) {
          if (results) {
            if (results.currentlyProcessing) {
              setCurrentlyProcessing({ attempt: currentlyProcessing.attempt + 1, state: true });
            } else {
              setCurrentlyProcessing({ attempt: 0, state: false });
              setStakeholdersData(results);
            }
          }
          if (isLoadedFirstTime.current) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: true });
          }
          if (results) {
            isLoadedFirstTime.current = true;
            updateTitleAnalytics();
            setIsLoading(false);
          }
        }
      }
      if (searchParameter) {
        setIsLoading(false);
        setStakeholdersData({ graphData: [], images: [] });
        isLoadedFirstTime.current = false;
      }
    } catch {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadData.current(source);
    return () => {
      source.cancel('');
    };
  }, [topicParameter, searchParameter]);

  const checkReadyState = useRef();
  checkReadyState.current = () => {
    const callAgainResults = () => {
      setTimeout(async () => {
        try {
          await loadData.current();
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    };
    if (currentlyProcessing.state) {
      callAgainResults();
    }
  };

  useEffect(() => {
    checkReadyState.current();
    return () => {
      if (currentlyProcessing.state) {
        source.cancel('Stakeholders canceled by the user.');
      }
    };
  }, [currentlyProcessing]);

  return (
    <RelevantStakeholders
      isLoading={isLoading}
      stakeholdersData={stakeholdersData}
      isLoadedFirstTime={isLoadedFirstTime}
      currentlyProcessing={currentlyProcessing.state}
      setStakeholdersData={setStakeholdersData}
    />
  );
};

export default RelevantStakeholdersContainer;

import React, { useEffect, useRef } from 'react';
import ConsultationItem from '../Dashboard/consultations/consultationItem';
import ItemNew from '../Dashboard/key_updates/ItemNew';
import LegislationItem from '../Dashboard/Legislation/LegislationItem';
import ParliamentaryItem from '../Dashboard/Parliamentary_record/ParlamentaryItem';
import TweetItem from '../Dashboard/social_media/TweetItem';
import CalendarEmbed from './CalendarEmbed';
import FeedNoAvailable from './FeedNoAvailable';
import createTypeOfResource from '../Dashboard/helpers/createTypeOfResource';
import SkeletonKeyUpdates from '../Dashboard/key_updates/SkeletonKeyUpdates';
import CustomScrollbar from '../Common/CustomScrollbar';
import Axios from 'axios';

const EmbedList = (props) => {
  const {
    results,
    typeOfContent,
    createFunctionOnClick,
    keywordListId,
    load_more_results,
    setIsLoading,
    apiCallPerContent,
    setResults,
    isLoading,
    scrollbarContainer,
    customResults,
    background,
    topic,
    userId,
  } = props;
  const switchingResource = useRef(false);

  const handleScroll = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    const scrolledToBottom = scrollTop === scrollHeight - clientHeight;
    if (scrolledToBottom && typeOfContent !== 'Events') {
      load_more_results();
    }
  };

  const dispatchApiCall = async (source) => {
    setIsLoading(true);
    let results = await apiCallPerContent(createTypeOfResource(typeOfContent), null, source);
    if (results) {
      setResults(results?.embedWidgetsAllowed ? results : null);
      setIsLoading(false);
    } else {
      if (!switchingResource.current) {
        setResults(null);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (!customResults && (!!topic || !!userId)) {
      dispatchApiCall(source);
    }
    return () => {
      switchingResource.current = true;
      source.cancel('Embed cancelled');
    };
  }, [typeOfContent, topic, userId]);

  useEffect(() => {
    if (!isLoading) {
      let view = scrollbarContainer?.current?.view;
      if (!!view) {
        if (view?.scrollHeight > view?.clientHeight) {
          view?.classList?.add('element-with-scroll');
        } else {
          view?.classList?.remove('element-with-scroll');
        }
      }
    }
  }, [results, isLoading]);

  const resultsToUse = customResults ?? results;
  return (
    <>
      {isLoading ? (
        <div className='skeleton-embed-container flex-grow-1 overflow-hidden'>
          <SkeletonKeyUpdates />
        </div>
      ) : (
        <div className='embed-padding flex-grow-1 pt-3 pb-1' style={{ backgroundColor: background }}>
          {results === null && !customResults && <FeedNoAvailable />}
          {resultsToUse?.hits?.length > 0 && (
            <CustomScrollbar
              className={'main-content-scrollbar'}
              maximalThumbYSize={100}
              onScroll={(scrollValues) => {
                if (!customResults) {
                  handleScroll(scrollValues);
                }
              }}
              ref={scrollbarContainer}
            >
              {typeOfContent === 'Events' && <CalendarEmbed elements={results.hits} keywordListId={keywordListId} />}
              {typeOfContent !== 'Events' && (
                <ul className='policy-list px-1 all-open-consultations items-list pb-1'>
                  {resultsToUse.hits.map((item, index, array) => {
                    const explanations = results.explanations;
                    const itemOnclick = createFunctionOnClick(item);
                    const embedShowTags = false;
                    const itemFromWidget = true;
                    const props = {
                      index,
                      item,
                      array,
                      explanations,
                      itemOnclick,
                      embedShowTags,
                      itemFromWidget,
                      notShowShare: true,
                    };
                    switch (typeOfContent) {
                      case 'Legislation':
                        return <LegislationItem key={`${typeOfContent}-item-${index}`} {...props} />;
                      case 'ParliamentaryRecord':
                        return <ParliamentaryItem key={`${typeOfContent}-item-${index}`} {...props} />;
                      case 'Tweets':
                        return (
                          <TweetItem
                            key={`${typeOfContent}-item-${index}`}
                            {...props}
                            itemFromWidget={!!keywordListId}
                            keywordListId={keywordListId}
                          />
                        );
                      case 'KeyUpdates':
                      case 'HandPickedKeyUpdates':
                        return <ItemNew key={`${typeOfContent}-item-${index}`} {...props} />;
                      case 'Consultations':
                        return <ConsultationItem key={`${typeOfContent}-item-${index}`} {...props} />;
                      default:
                        return '';
                    }
                  })}
                </ul>
              )}
            </CustomScrollbar>
          )}

          {resultsToUse?.hits?.length === 0 && (
            <div className='flex-grow-1'>
              <FeedNoAvailable noResults />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmbedList;

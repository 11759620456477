import React from 'react';
import EmailAddressesDropDown from '../../../../Settings/EmailAddressesList/EmailAddressesDropDown';
import CrmContactsSelect from '../CrmContactsSelect/CrmContactsSelect';
import TinyEditor from '../TinyEditor/TinyEditor';
import InfoTooltip from './SentPerformance/InfoTooltip';
import useIsMobile from '../../../utilities/useIsMobile';
import { EmailBodySkeleton } from './CrmEmailComponentSkeleton';
import { LoadingSummaryText } from '../../../UserContent/ui/CreatingSummaryLoading';

export const getInitialSenderEmailAddress = (id, emailAddresses) => {
  let defaultCrmEmailAddressId = JSON.parse(localStorage.getItem('lastSenderEmailAddress'))?.emailAddressId;
  let crmEmailAddressId = id ?? defaultCrmEmailAddressId;
  return emailAddresses.find((address) => address.id === crmEmailAddressId);
};

const FromToFields = ({
  disabledCondition,
  emailAddresses,
  currentEmailDetails,
  handleFrom,
  disabledToCondition,
  initialToValue,
  autofocusField,
  isMobile,
  setActiveTab,
  setActiveRecipientsOption,
}) => {
  return (
    <div className={`email-component-section ${!isMobile ? 'email-component-side-section' : ''}`}>
      <div className='email-component-from'>
        <span className='labelText text-700'>From</span>
        <div
          style={{
            marginLeft: !disabledCondition && isMobile ? '16px' : undefined,
          }}
        >
          <EmailAddressesDropDown
            senderAddresses={emailAddresses}
            value={getInitialSenderEmailAddress(currentEmailDetails.senderEmailAddressId, emailAddresses)}
            handleChange={handleFrom}
            disabledCondition={disabledCondition}
            emailBody
            sideBar={!isMobile}
          />
        </div>
      </div>
      <div
        className={`email-component-from email-component-to-section ${
          !isMobile ? 'flex-grow-1 d-flex flex-column' : ''
        }`}
      >
        <span className='labelText d-flex text-700'>
          To{' '}
          {!disabledToCondition && (
            <InfoTooltip
              text={'Each message will be sent separately. Recipients will not see each other.'}
              senderDropdown
            />
          )}
        </span>

        {initialToValue() && (
          <CrmContactsSelect
            initialValues={initialToValue()}
            disabled={disabledToCondition}
            autoFocus={autofocusField() === 'to'}
            sideBar={!isMobile}
            sentEmail={currentEmailDetails?.status === 'Sent' || currentEmailDetails?.status === 'PartiallySent'}
            setActiveTab={setActiveTab}
            setActiveRecipientsOption={setActiveRecipientsOption}
          />
        )}
      </div>
    </div>
  );
};

function EmailComponentBody({
  currentEmailDetails,
  disabledCondition,
  disabledToCondition,
  handleSubject,
  handleBody,
  initialToValue,
  emailAddresses,
  handleFrom,
  autofocusFieldFromUrl,
  setEditorOnLoad,
  setActiveTab,
  setActiveRecipientsOption,
  skeletonCondition,
  isLoadingWritingAssistant,
  showSendPreviewPopUp,
}) {
  const isMobile = useIsMobile(768);
  const autofocusField = () => {
    if (!showSendPreviewPopUp) {
      if (!currentEmailDetails?.subject.length) {
        return 'subject';
      } else if (!currentEmailDetails?.sendRecords?.length || autofocusFieldFromUrl?.current === 'to') {
        return 'to';
      } else {
        return 'body';
      }
    }
  };

  return (
    <div className='h-100 email-component-body'>
      {isLoadingWritingAssistant ? (
        <div className='email-component-container flex-grow-1 position-relative d-flex align-items-center justify-content-center'>
          <LoadingSummaryText />
        </div>
      ) : (
        <div
          className={`email-component-container flex-grow-1 position-relative ${
            currentEmailDetails.status === 'Sent' ? 'email-component-container-sent' : ''
          }`}
          style={skeletonCondition ? { border: 'none' } : null}
        >
          {skeletonCondition && <EmailBodySkeleton />}
          <div
            className='h-100 d-flex flex-column'
            style={{
              opacity: skeletonCondition ? 0 : 1,
              position: skeletonCondition ? 'fixed' : undefined,
            }}
          >
            {isMobile && (
              <FromToFields
                disabledCondition={disabledCondition}
                emailAddresses={emailAddresses}
                currentEmailDetails={currentEmailDetails}
                handleFrom={handleFrom}
                disabledToCondition={disabledToCondition}
                initialToValue={initialToValue}
                autofocusField={autofocusField}
                isMobile={isMobile}
                setActiveTab={setActiveTab}
                setActiveRecipientsOption={setActiveRecipientsOption}
              />
            )}
            <div className='email-component-section email-subject-section'>
              <div>
                <input
                  className='email-component-input email-component-input-subject'
                  type='text'
                  id='email-component-subject-input'
                  value={currentEmailDetails.subject}
                  disabled={disabledCondition}
                  onChange={handleSubject}
                  spellCheck={false}
                  placeholder='Subject'
                  autoFocus={autofocusField() === 'subject'}
                  autoComplete='off'
                />
              </div>
            </div>
            <TinyEditor
              stateContent={currentEmailDetails.body}
              handleBody={handleBody}
              disabled={disabledCondition}
              autoFocus={autofocusField() === 'body'}
              setEditorOnLoad={setEditorOnLoad}
              key={currentEmailDetails.senderEmailAddressId}
            />
          </div>
        </div>
      )}

      {!isMobile && (
        <FromToFields
          disabledCondition={disabledCondition}
          emailAddresses={emailAddresses}
          currentEmailDetails={currentEmailDetails}
          handleFrom={handleFrom}
          disabledToCondition={disabledToCondition}
          initialToValue={initialToValue}
          autofocusField={autofocusField}
          setActiveTab={setActiveTab}
          setActiveRecipientsOption={setActiveRecipientsOption}
        />
      )}
    </div>
  );
}

export default EmailComponentBody;

import { useHeightContainer } from '../../utilities/useHeightContainer';
import { FilterItem } from './CommonFilter';
import ToggleFilter from './ToggleFilter';
import SortFilter from './SortFilter';
import { DateFilter } from './DateFilter';
import SortMode from './SortMode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useCollapse from 'react-collapsed';
import normalize from '../../utilities/normalizeString';
import useIsMobile from '../../utilities/useIsMobile';
import FiltersData from '../FiltersData';
import FilterRegisterPopup from './FilterRegisterPopup';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../../Store';
import CreateAccountPopup from '../../components/CreateAccountPopup';
import { useMainSectionParameter } from '../../helpers/useMainSectionParameter';
import ResearchPopUp from '../../SubNav/ui/ResearchPopUp';
import ParliamentaryRecordMultipleHierarchy from './ParliamentaryRecordMultipleHierarchy';
import ReferenceTopicWidget from '../../SubNav/ReferenceTopicsWidget';
import MembershipSelectFilter from './MembershipSelectFilter';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import { filterFiltersOptionDependOnLocation } from '../filterFiltersOptionDependOnLocation';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import ShowRelevantExtract from '../../key_updates/ShowRelevantExtract';

const OutsideFilters = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, search, referenceState, keywordsLists, readyKeywordLists, openResearchPopup } = state;

  const { activeSearch, list } = search;
  const { activeReference } = referenceState;
  const { subscriptionStatus, code, enableReferenceSection, enableAdvancedSearch } = activePlan;
  const {
    getDefaultSectionFilters,
    filtersState,
    applyFiltersFunction,
    filterTypeModifiers,
    section,
    hideFiltersQntty,
    useInDropdown,
  } = props;
  const searchMode = activeSearch || activeReference;

  const [heightContainer, containerRef] = useHeightContainer();
  const [heightContainerInsideBox, containerRefInsideBox] = useHeightContainer();

  const sectionFilters = getDefaultSectionFilters(filtersState);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const params = useParams();

  const { searchResultsSection, inboxSection } = useMainSectionParameter(params);

  const trialUser =
    (subscriptionStatus === 'InTrial' && code.includes('Mogul')) ||
    subscriptionStatus === 'TrialEnded' ||
    !enableReferenceSection;
  const allowedUsers = (isAuthenticated && !trialUser) || inboxSection;
  const selectOption = () => {
    return ({ filter, newValue }) => {
      if (allowedUsers) {
        let newFilters = filterTypeModifiers({ filter, newValue });
        applyFiltersFunction(newFilters);
      } else {
        setShowRegisterPopup(true);
      }
    };
  };

  const updateScrollBarPosition = useRef();
  const scrollBarRef = useRef();
  updateScrollBarPosition.current = () => {
    scrollBarRef.current.scrollToLeft();
    scrollBarRef.current.scrollToTop();
  };

  useEffect(() => {
    updateScrollBarPosition.current();
  }, [list, location.pathname]);

  useEffect(() => {
    if (!activeReference) {
      updateScrollBarPosition.current();
    }
  }, [activeReference]);

  //REFERENCE ELEMENTS NEW WIDGET ON THE OUTSIDE FILTERS
  const [showPopup, setShowPopup] = useState(false);
  const [keywordsSection, setKeywordsSection] = useState('keywords');

  const showPopupFromEmptyMessage = useRef();
  showPopupFromEmptyMessage.current = () => {
    if (openResearchPopup) {
      setShowPopup(true);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'openResearchPopup',
        value: false,
      });
    }
  };
  useEffect(() => {
    showPopupFromEmptyMessage.current();
  }, [openResearchPopup]);

  const closeKeywordsSection = () => {
    setKeywordsSection('keywords');
  };

  const relevantExtractCondition = !useInDropdown && location.pathname?.includes('/key-updates');

  return (
    <>
      <div ref={containerRef} className='outside-filters reference-main-content'>
        <div className={relevantExtractCondition ? '' : `${useInDropdown ? '' : 'outside-filters-around-container'}`}>
          <CustomScrollbar
            className={'main-content-scrollbar top-content-scrollbar'}
            style={{ height: `${heightContainer}px` }}
            maximalThumbYSize={100}
            ref={scrollBarRef}
          >
            {relevantExtractCondition && <ShowRelevantExtract />}
            <div className={relevantExtractCondition ? `outside-filters-around-container` : ''}>
              <div
                ref={containerRefInsideBox}
                className='outside-filters-box'
                style={{ minHeight: `${heightContainerInsideBox}px` }}
              >
                {searchResultsSection && readyKeywordLists && enableAdvancedSearch && (
                  <div className='outside-filters-spacing-container py-4 border-bottom'>
                    <ReferenceTopicWidget
                      keywordsLists={keywordsLists}
                      setShowPopup={setShowPopup}
                      keywordsSection={keywordsSection}
                      setKeywordsSection={setKeywordsSection}
                    />
                  </div>
                )}
                <div className='outside-filters-spacing-container'>
                  {sectionFilters
                    .filter((item) => !item.excludeFromPopup)
                    .slice(hideFiltersQntty ? hideFiltersQntty + 1 : 0, undefined)
                    .map((item, index) => {
                      const { hidden, name } = item;
                      const showFilter = !hidden;
                      const renderFilter = showFilter;
                      const filteredOptionsItem = filterFiltersOptionDependOnLocation({
                        item,
                        filters: sectionFilters,
                      });
                      const filterProps = {
                        filter: filteredOptionsItem,
                        selectOption: selectOption(),
                      };
                      return (
                        <React.Fragment key={`section-filter-${index}-${normalize(name)}`}>
                          {renderFilter && (
                            <OutsideFilter
                              {...filterProps}
                              sectionFilters={sectionFilters}
                              section={section}
                              allowedUsers={allowedUsers}
                              searchMode={searchMode}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
      {showRegisterPopup && isAuthenticated && (
        <FilterRegisterPopup
          identifier={'filters-register-popup'}
          setShowPopup={setShowRegisterPopup}
          additionalClass={'export-message-popup'}
          proMessage={!enableReferenceSection && isAuthenticated}
        />
      )}
      {showRegisterPopup && !isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to filter results'}
          setShowPopup={setShowRegisterPopup}
        />
      )}
      {showPopup && (
        <ResearchPopUp
          keywordsLists={keywordsLists.filter((item) => item.id !== null)}
          identifier={'research-popup'}
          setShowPopup={setShowPopup}
          additionalCloseAction={closeKeywordsSection}
          keywordsSection={keywordsSection}
          referenceState={referenceState}
        />
      )}
    </>
  );
};

const OutsideFilter = (props) => {
  const location = useLocation();
  const { filter, list, selectOption, allowedUsers } = props;
  const { name, type, optionSelected, value, options, disabled, subtextClassName } = filter;
  const expandedConditional = () => {
    if (location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers')) {
      return false;
    }
    return true;
  };
  const [isExpanded, setExpanded] = useState(expandedConditional());
  const [isExpandedOnAction, setExpandedOnAction] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const isMobile = useIsMobile(1280);
  const transformName = () => {
    if (isMobile) {
      switch (name) {
        case 'Exclude closed consultations':
          return 'Exclude closed';
        case 'Search within results':
          return 'Search within';
        default:
          return name;
      }
    }
    return name;
  };

  const subtext = () => {
    let text = '';
    if (type === 'search' && value !== '') {
      text = value;
    } else if (!!optionSelected && type !== 'membership') {
      text = optionSelected;
    } else if (!type || type === 'parliamentaryRecordMultipleHierarchy' || type === 'membership') {
      text = options
        .filter((option) => option.selected)
        .flatMap((option) =>
          option.optionSelected && !option.optionSelected.includes('All') ? option.optionSelected : option.name
        )
        .join(', ');
    }
    return text;
  };

  const clearIndividualFilter = () => {
    let newValue;
    let referenceFilters = JSON.parse(JSON.stringify(FiltersData()));
    let filterPosition = referenceFilters.findIndex((item) => item.name === name && item.type === type);
    let filter = referenceFilters[filterPosition];
    if (type === 'search') {
      newValue = '';
    } else if (type === 'date') {
      newValue = {
        optionSelected: filter.optionSelected,
        options: filter.options,
      };
    } else {
      newValue = filter.options;
    }
    selectOption({ filter, newValue });
    setExpanded(true);
  };
  const { agencyUser } = useTopicOrClientWord();

  return (
    <div className={`popup-outside-filter-container`}>
      {type !== 'toggle' && (
        <>
          <div
            {...getToggleProps({
              onClick: () => {
                if (!disabled) {
                  setExpanded((prevExpanded) => !prevExpanded);
                  setExpandedOnAction(!isExpanded);
                }
              },
            })}
          >
            <h3
              className={`mb-0 title-h5-bold dropdown-item-element d-block ${
                isExpanded ? 'open' : ''
              } ${disabled ? 'main-light-text' : ''}`}
            >
              {transformName()}
              {disabled && (
                <span
                  className='ml-2 icon-padlock d-inline-block'
                  data-tooltip-content={`Upgrade to ${agencyUser ? '' : 'Mogul or '}Enterprise for sentiment analysis`}
                  data-tooltip-id={'general-tooltip'}
                />
              )}
            </h3>
            {!isExpanded && subtext() !== '' && (
              <p className='mb-0 paragraph-p2 main-light-text outside-filter-subtext'>
                <span className={subtextClassName ? `${subtextClassName} ${normalize(optionSelected)}` : ''}>
                  {subtext()}
                </span>
                {type !== 'sort' && type !== 'sortMode' && (
                  <button
                    className='general-button action-delete-text d-inline-block ml-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      clearIndividualFilter();
                    }}
                  >
                    Clear
                  </button>
                )}
              </p>
            )}
          </div>
          <div {...getCollapseProps()}>
            <div className={`pt-${type === 'sort' || type === 'date' ? 3 : 4} outside-collapsing-options`}>
              {type === 'search' && <OutsideSearchFilter {...props} isExpanded={isExpandedOnAction} />}
              {type === 'sort' && <SortFilter {...props} notIncludeName setExpanded={setExpanded} />}
              {type === 'date' && <DateFilter {...props} notIncludeName notShowCalendar={!allowedUsers} />}
              {type === 'sortMode' && <SortMode {...props} list={list} notIncludeName />}
              {type === 'parliamentaryRecordMultipleHierarchy' && (
                <ParliamentaryRecordMultipleHierarchy {...props} notIncludeName />
              )}
              {(!type || type === 'membership') && (
                <OutsideCommonFilter {...props} isExpandedParent={isExpandedOnAction} />
              )}
            </div>
          </div>
        </>
      )}
      {type === 'toggle' && <ToggleFilter {...props} />}
    </div>
  );
};

const OutsideSearchFilter = (props) => {
  const { filter, selectOption, isExpanded } = props;
  const { value } = filter;
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        inputSearchRef.current.focus();
      }, 100);
    }
  }, [isExpanded]);

  const inputSearchRef = useRef();
  const updateCurrentValue = useRef();
  updateCurrentValue.current = () => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  };

  useEffect(() => {
    updateCurrentValue.current();
  }, [value]);

  return (
    <div className='pb-2'>
      <div className='flex-centered box-rounded simple-input-box'>
        <span className='icon-search title-h4 mr-2' style={{ color: '#9c9c9c' }} />
        <div className='flex-grow-1'>
          <input
            ref={inputSearchRef}
            className='search-main-input w-100'
            type='text'
            value={currentValue}
            onChange={(e) => {
              let newValue = e.target.value;
              setCurrentValue(newValue);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                selectOption({ filter, newValue: currentValue });
                e.target.blur();
              }
            }}
          />
        </div>
        <div
          className='close-icon-container'
          style={{
            visibility: value !== '' ? 'visible' : 'hidden',
          }}
        >
          <button
            className='general-button close-icon'
            onClick={() => {
              selectOption({ filter, newValue: '' });
              setCurrentValue('');
            }}
          />
        </div>
      </div>
    </div>
  );
};

const OutsideCommonFilter = (props) => {
  const { filter, selectOption, allowedUsers, isExpandedParent } = props;
  const { options, name, type } = filter;
  let splitItemValue = 4;

  const changeFilter = (selected, index, name) => {
    let newValue = [...options?.filter((item) => !item.hidden)];
    let currentOption = newValue[index];
    if (allowedUsers) {
      if (name) {
        if (!selected) {
          currentOption.selected = true;
        }
        currentOption.optionSelected = name;
      } else {
        currentOption.selected = selected ? !selected : true;
      }
    }
    selectOption({ filter, newValue });
  };
  const moreOptions = () => {
    let seeMoreOptions = [];
    if (name === 'Party' || name === 'Region') {
      seeMoreOptions = options.slice(splitItemValue, options.length);
    }
    return seeMoreOptions;
  };

  const optionsToRender = () => {
    if (name === 'Party' || name === 'Region') {
      return options.slice(0, splitItemValue);
    }
    return options;
  };

  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      <div className='common-outside-filter'>
        {type === 'membership' && <MembershipSelectFilter {...props} notIncludeName isExpanded={isExpandedParent} />}
        {optionsToRender()
          .filter((item) => !item.hidden)
          .map((option, index) => (
            <FilterItem
              option={option}
              options={options}
              key={`option-filter${index}`}
              index={index}
              changeFilter={changeFilter}
              notRow
            />
          ))}

        {moreOptions().length > 0 && (
          <>
            <div {...getCollapseProps()}>
              {moreOptions().map((option, index) => (
                <FilterItem
                  option={option}
                  options={options}
                  key={`option-filter${index + splitItemValue}`}
                  index={index + splitItemValue}
                  changeFilter={changeFilter}
                  notRow
                />
              ))}
            </div>

            <button
              className={`d-inline-block simple-link-button general-button showitem-button ${
                isExpanded ? 'closeitem-button' : ''
              }`}
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
            >
              Show {isExpanded ? 'less' : 'more'}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default OutsideFilters;

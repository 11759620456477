import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { forwardRef } from 'react';

const CustomScrollbar = forwardRef((props, ref) => {
  const { children, onScroll } = props;
  const { maximalThumbYSize, minimalThumbYSize, ...restProps } = props;

  const handleTrackOpacity = (val) => {
    setTimeout(() => {
      if (ref?.current?.trackVertical?.style) {
        ref.current.trackVertical.style.opacity = val;
      }
    }, 100);
  };

  return (
    <Scrollbars
      ref={ref}
      {...restProps}
      onScroll={({ target }) => {
        if (onScroll) {
          onScroll(target);
        }
      }}
      renderView={(props) => (
        <div {...props} className='ScrollbarsCustom-Content' style={{ ...props?.style, height: 'calc(100% + 17px)' }} />
      )}
      onMouseOver={() => handleTrackOpacity(1)}
      onMouseLeave={() => handleTrackOpacity(0)}
      renderTrackHorizontal={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            cursor: 'pointer',
            borderRadius: '4px',
            position: 'absolute',
            overflow: 'hidden',
            background: 'rgba(0, 0, 0, 0)',
            userSelect: 'none',
            height: '10px',
            width: 'calc(100% - 20px)',
            bottom: '0px',
            left: '10px',
          }}
          className='ScrollbarsCustom-Track ScrollbarsCustom-TrackX'
        />
      )}
      renderTrackVertical={(props) => (
        <div
          {...props}
          style={{
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: '4px',
            background: 'rgba(0, 0, 0, 0.1)',
            userSelect: 'none',
            width: '10px',
            height: 'calc(100% - 20px)',
            top: '10px',
            right: '0px',
          }}
          className='ScrollbarsCustom-Track ScrollbarsCustom-TrackY'
        />
      )}
      renderThumbHorizontal={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            cursor: 'pointer',
            borderRadius: '4px',
            background: 'rgba(0, 0, 0, 0.4)',
          }}
          className='ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbX'
        />
      )}
      renderThumbVertical={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            cursor: 'pointer',
            borderRadius: '4px',
            background: 'rgba(0, 0, 0, 0.4)',
          }}
          className='ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY'
        />
      )}
      thumbMinSize={minimalThumbYSize ?? 30}
      thumbSize={maximalThumbYSize}
    >
      {children}
    </Scrollbars>
  );
});

export default CustomScrollbar;

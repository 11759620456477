import dayjs from 'dayjs';
import { useContext } from 'react';
import { store } from '../../Store';
import { compileData } from '../../Dashboard/Analytics/MentionsByTopicContainer';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';

const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const useCallPoliticalMentionsByTopic = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsState } = state;
  const { generalApiCall } = useGeneralApiCall();

  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const callPoliticalMentionsByTopic = async (topic, source) => {
    try {
      if (topic) {
        let value = {};
        let rawData;
        let parliamentaryRecord = await parliamentaryRecordCall(topic, source);
        value = {
          Contributions: parliamentaryRecord?.parliamentaryRecords?.series?.find((item) => item.bucket === null)
            ?.datapoints,
        };
        if (showTweets) {
          value = {
            ...value,
            Tweets: parliamentaryRecord?.tweets?.series?.find((item) => item.bucket === null)?.datapoints,
          };
        }
        rawData = value;
        let compiledDataRaw = compileData({ value }, analyticsState).raw;
        let data = {
          labels: compiledDataRaw.map((item) => item.date),
          value,
        };
        return { data, rawData };
      }
    } catch (error) {}
  };

  const parliamentaryRecordCall = async (topic, source) => {
    try {
      const pathname = `/api/graph-data/mentions-by-keyword-list`;
      const method = 'post';
      let requestProperties = {
        keywordListId: topic.id,
        from: dayjs(dayjs().subtract(5, 'month').startOf('day').format('YYYY-MM-DD'))
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: dayjs().add('1', 'day').format('YYYY-MM-DD'),
        interval: 'monthly',
        excludeTweets: !showTweets,
      };

      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
      return results;
    } catch (error) {}
  };

  return {
    callPoliticalMentionsByTopic,
  };
};

export default useCallPoliticalMentionsByTopic;

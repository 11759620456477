import 'leaflet/dist/leaflet.css';
import React, { useContext, useRef } from 'react';
import { GeoJSON, MapContainer, TileLayer, CircleMarker } from 'react-leaflet';

import { useState } from 'react';
import { useEffect } from 'react';
import ChoroplethChartLegend from './ChoroplethChartLegend';
import { createMarkerTooltip, removeMarkerTooltip } from './interestByRegionExternalTooltip';
import FrontbenchersCheckBox from './FrontbenchersCheckBox';
import normalize from '../../../utilities/normalizeString';
import useIsMobile from '../../../utilities/useIsMobile';
import useSelectedCountriesFilters from '../../useSelectedCountriesFilters';
import { EmptyStateRateLimit } from '../../AnalyticsDashboardContainer';
import { store } from '../../../../Store';
import Axios from 'axios';

const maxMarkerSize = 20;

const InterestByRegionChart = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;
  const {
    chartData,
    setActiveSection = () => {},
    chartDataKeys,
    map,
    setMap,
    data,
    setData,
    regionsMapping,
    scrollFromChart,
    dashboard,
    analyticsState,
    rightPanelState,
    allowFrontbenchers,
    setAllowFrontbenchers,
    setRightPanelState,
    highlightGraphOnHover,
    height,
    isFromPopup,
    reportWidget,
    setIsMapResizing,
  } = props;

  const [maxTotalVal, setMaxTotalVal] = useState(null);
  const [regionTotalValues, setRegionTotalValues] = useState(null);

  const { typeOfChartInterestByRegion } = analyticsState;
  const { UKSelected } = useSelectedCountriesFilters();
  const geoJsonMap = useRef();

  const isMobile = useIsMobile(580);

  const getGeoJsonStyle = (feature) => {
    if (typeOfChartInterestByRegion === 'Bubble chart map') {
      return {
        weight: 1,
        color: '#d4d4d4',
        fillColor: '#ffffff',
        fillOpacity: 0.2,
      };
    } else {
      const regionName = regionsMapping[feature.properties.eer18nm];
      return {
        weight: 1,
        color: '#d4d4d4',
        fillColor: '#0094cc',
        fillOpacity: getRegionOpacity(regionName),
      };
    }
  };

  const getRegionOpacity = (regionName) => {
    let val = regionTotalValues?.[regionName];
    if (typeof val === 'undefined') {
      val = 0;
    }
    if (val === 0) {
      return 0.08;
    }
    return (val / maxTotalVal) * 0.7;
  };

  const loadMap = useRef();
  loadMap.current = async (source) => {
    try {
      const pathname = `/data/united_kingdom_administrative_eu_parliamentary_constituencies_1MB.geojson`;
      const method = 'get';
      let requestHeaders = {
        cancelToken: source?.token,
      };
      let results = await Axios[method](`${process.env.REACT_APP_CDNURL}${pathname}`, requestHeaders, '');
      if (results?.data) {
        setData(results.data.features);
      }
    } catch {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    loadMap.current(source);
    return () => {
      source.cancel('map cancelled by the user');
    };
  }, []);

  const getMarkerSize = (region) => {
    let radius = 0;
    let allowFrontbenchersDependency = 1;
    if (allowFrontbenchers) {
      allowFrontbenchersDependency = 1.1;
    }
    if (chartData[region]) {
      radius = Object.values(chartData[region]).reduce((prev, cur) => prev + cur, 0);
    }
    return (radius / maxTotalVal) * maxMarkerSize * allowFrontbenchersDependency;
  };

  useEffect(() => {
    const markerValues = Object.values(chartData).map((values) =>
      Object.values(values).reduce((prev, cur) => prev + (cur ?? 0), 0)
    );

    let regionValues = {};
    Object.keys(chartData).forEach((region) => {
      regionValues[region] = Object.values(chartData[region]).reduce((prev, cur) => prev + (cur ?? 0), 0);
    });

    let maxTotalValue = Math.max(...markerValues);
    setMaxTotalVal(maxTotalValue);
    setRegionTotalValues(regionValues);
  }, [chartData, typeOfChartInterestByRegion]);

  const getMarkerPosition = (region) => {
    let markerFeature = data?.find((feature) => regionsMapping[feature.properties.eer18nm] === region);
    return [markerFeature.properties.lat, markerFeature.properties.long];
  };

  const setActiveSectionHandler = (region) => {
    const index = chartDataKeys.findIndex((item) => item === region);
    if (index >= 0) {
      setActiveSection(region);
    }
  };

  useEffect(() => {
    if (map) {
      if (map?.['_container']) {
        //remove leaflet logo
        const leafletLogo = map['_container'].querySelectorAll('.leaflet-control-attribution');
        if (leafletLogo.length > 0) {
          leafletLogo[0].remove();
        }
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      if (map?.['_container']) {
        const paths = Array.from(document.getElementsByClassName('leaflet-interactive'));
        paths.forEach((path) =>
          path.classList.add(
            `leaflet-region-style-${typeOfChartInterestByRegion === 'Bubble chart map' ? 'buble' : 'heat'}`
          )
        );
      }
    }
  }, [map, typeOfChartInterestByRegion, chartData]);

  useEffect(() => {
    if (map) {
      setTimeout(function () {
        if (!!geoJsonMap.current) {
          map.invalidateSize(true);
          if (props.boardPosition) {
            map.fitBounds(geoJsonMap.current?.getBounds());
            setIsMapResizing(false);
          }
        }
      }, 100);
    }
  }, [rightPanelState?.open, geoJsonMap.current, props.boardPosition]);

  const geoJsonMouseOutHandle = function (feature, layer) {
    layer.setStyle({
      weight: 1,
      fillOpacity:
        typeOfChartInterestByRegion === 'Bubble chart map'
          ? 0.2
          : getRegionOpacity(regionsMapping[feature.properties.eer18nm]),
    });
  };

  const getTooltipCoordinates = (e) => {
    if (e.originalEvent) {
      return {
        x: e.originalEvent.pageX,
        y: e.originalEvent.pageY,
      };
    } else if (e.target._path) {
      return {
        x: e.target._path.getBoundingClientRect().left + e.target._path.getBoundingClientRect().width / 2,
        y: e.target._path.getBoundingClientRect().top + e.target._path.getBoundingClientRect().height / 2,
      };
    }
  };

  useEffect(() => {
    return () => {
      removeMarkerTooltip();
    };
  }, []);

  return (
    <>
      {analyticsRateLimitReached ? (
        <EmptyStateRateLimit />
      ) : (
        <>
          {!!data?.length && (
            <div
              style={{
                height: height ?? '100%',
                position: 'absolute',
                top: dashboard ? '0.5px' : isMobile ? '45px' : '-40px',
                width: 'calc(100% - 1px)',
                left: '0',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: dashboard ? '5px' : 0,
                borderBottomLeftRadius: dashboard ? '5px' : 0,
              }}
              className='map-chart'
            >
              <MapContainer
                center={[55.0004851, dashboard || isFromPopup ? -6 : -3.2261503]}
                zoom={dashboard || isFromPopup ? 4.5 : 5.1}
                style={{ height: '100%' }}
                ref={setMap}
                zoomDelta={0.1}
                zoomSnap={0.1}
                zoomControl={false}
                boxZoom={false}
                scrollWheelZoom={false}
                touchZoom={false}
                dragging={false}
                doubleClickZoom={false}
              >
                <TileLayer url='https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png' />
                <GeoJSON
                  ref={geoJsonMap}
                  key={`${typeOfChartInterestByRegion}-${JSON.stringify(chartData)}-${maxTotalVal}-geojson`}
                  data={data}
                  style={getGeoJsonStyle}
                  onEachFeature={function (feature, layer) {
                    const region = regionsMapping[feature?.properties?.eer18nm];
                    layer.on('mouseover', (e) => {
                      if (!reportWidget) {
                        if (typeOfChartInterestByRegion === 'Heat map' || !Object.keys(chartData[region]).length) {
                          createMarkerTooltip({
                            ...getTooltipCoordinates(e),
                            properties: chartData[region],
                            region: region,
                          });
                        }
                      }
                    });
                    layer.on('mouseout', () => {
                      geoJsonMouseOutHandle(feature, layer);
                      if (!reportWidget) {
                        removeMarkerTooltip();
                      }
                    });
                    layer.on('click', function () {
                      if (scrollFromChart) {
                        scrollFromChart(region);
                      }
                      if (highlightGraphOnHover) {
                        highlightGraphOnHover({ item: region, onClick: true });
                      }
                      removeMarkerTooltip();
                    });
                  }}
                ></GeoJSON>
                {maxTotalVal &&
                  typeOfChartInterestByRegion === 'Bubble chart map' &&
                  Object.keys(chartData).map((region, k) => {
                    if (!!Object.keys(chartData[region]).length) {
                      return (
                        <CircleMarker
                          key={`${JSON.stringify(chartData)}-${k}-${maxTotalVal}`}
                          center={getMarkerPosition(region)}
                          radius={getMarkerSize(region)}
                          fillOpacity={0.5}
                          stroke={false}
                          pathOptions={{ color: '#0094cc' }}
                          eventHandlers={{
                            mouseover: (e) => {
                              if (map?._targets && !reportWidget) {
                                createMarkerTooltip({
                                  ...getTooltipCoordinates(e),
                                  properties: chartData[region],
                                  region: region,
                                });

                                e.target.setStyle({
                                  fillOpacity: 0.9,
                                });
                                e.target.setRadius(getMarkerSize(region) * 1.15);
                                setActiveSectionHandler(region);
                                const regionName = Object.keys(regionsMapping).find(
                                  (name) => regionsMapping[name] === region
                                );
                                const chartRegion = Object.values(map?._targets)?.find(
                                  (item) => item?.feature?.properties?.eer18nm === regionName
                                );
                                if (chartRegion) {
                                  chartRegion.fireEvent('mouseover');
                                }
                              }
                            },
                            mouseout: (e) => {
                              removeMarkerTooltip();

                              if (map?._targets) {
                                e.target.setStyle({
                                  fillOpacity: 0.5,
                                });
                                e.target.setRadius(getMarkerSize(region));
                                const chartRegion = Object.values(map._targets)?.find(
                                  (item) => item?.feature?.properties?.eer18nm === region
                                );
                                if (chartRegion) {
                                  chartRegion.fireEvent('mouseout');
                                }
                              }
                            },
                            click: () => {
                              if (scrollFromChart) {
                                scrollFromChart(region);
                              }
                              if (highlightGraphOnHover) {
                                highlightGraphOnHover({
                                  item: region,
                                  onClick: true,
                                });
                              }
                            },
                          }}
                        ></CircleMarker>
                      );
                    } else {
                      return null;
                    }
                  })}
                <ChoroplethChartLegend
                  map={map}
                  maxValue={maxTotalVal}
                  dashboard={dashboard}
                  typeOfChartInterestByRegion={typeOfChartInterestByRegion}
                  key={`${normalize(typeOfChartInterestByRegion)}-legend-key`}
                  reportWidget={reportWidget}
                />
              </MapContainer>
              {!dashboard && (
                <FrontbenchersCheckBox
                  allowFrontbenchers={allowFrontbenchers}
                  setAllowFrontbenchers={setAllowFrontbenchers}
                  setRightPanelState={setRightPanelState}
                  rightPanelState={rightPanelState}
                  hide={!UKSelected}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
export default InterestByRegionChart;

import { useEffect, useRef, useState } from 'react';
import ModalComponent from '../../Common/Modal';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions';
import ReportLogo from '../../Reports/ui/ReportLogo';
import { useContext } from 'react';
import { store } from '../../Store';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import useOrganizationInfoDescriptionFunctions from '../../Dashboard/hooks/useOrganizationInfoDescriptionFunctions';
import createNotification from '../Utilities/CreateNotification';
import getActivePlan from '../Utilities/getActivePlan';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const CreateClient = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isOpen, setIsOpen, clientsListState, activeClientState, edit, setIsOpenPopup } = props;
  const { clientsList, setClientList } = clientsListState;
  const { activeClient } = activeClientState ?? {};

  const [clientData, setClientData] = useState({
    teamName: '',
    organisationLogo: null,
    organisationWebsite: '',
    organisationDescription: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogotype, setIsLoadingLogotype] = useState(false);
  const modifyClientData = ({ property, newValue }) => {
    setClientData({ ...clientData, [property]: newValue });
  };

  const { generalApiCall } = useGeneralApiCall();
  const { setCurrentTeam } = useClientsFunctions();
  const {
    removeAndUpdateOrganizationDescription,
    removeAndUpdateTeamAdminOrganizationDescription,
    removeOrganizationDescription,
    removeTeamAdminOrganizationDescription,
  } = useOrganizationInfoDescriptionFunctions();

  const { getAccessToken } = useGetAccessToken();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const adminPage = location?.pathname?.startsWith('/admin');

  const clientNameElement = useRef();
  const closeModal = () => {
    setIsOpen(false);
    if (!!setIsOpenPopup) {
      setIsOpenPopup(false);
    }
    setTimeout(() => {
      if (!edit) {
        setClientData({
          teamName: '',
          organisationLogo: null,
          organisationWebsite: '',
          organisationDescription: '',
        });
        const params = new URLSearchParams(location.search);
        params.delete('create');
        navigate({ search: params.toString() }, { replace: true });
      }
      setIsLoading(false);
    }, 200);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        clientNameElement.current?.focus();
      }
    }, 300);
  }, [isOpen]);

  useEffect(() => {
    if (edit) {
      setClientData(activeClient);
    }
  }, [activeClient, isOpen]);

  const createEditClient = async (source) => {
    try {
      const pathname = `/api/${adminPage ? 'admin/add-or-update-team' : 'team'}`;
      const method = 'post';
      let requestProperties = { ...clientData };

      if (edit) {
        requestProperties = {
          ...requestProperties,
          teamId: activeClient?.teamId,
        };
      }

      if (adminPage) {
        requestProperties = {
          ...requestProperties,
          organisationId: params?.id,
        };
      }

      let result = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        noShowErrorMessage: true,
      });

      if (!!result) {
        if (clientData?.organisationDescription === '') {
          const requestProperties = {
            value: '',
            teamId: result?.teamId,
          };
          const removeDescription = adminPage
            ? await removeTeamAdminOrganizationDescription(requestProperties)
            : await removeOrganizationDescription(requestProperties);
          if (!!removeDescription) {
            result = { ...result, organisationDescription: '' };
          }
        }

        if (!!clientData?.organisationDescription) {
          const requestProperties = {
            value: clientData?.organisationDescription,
            teamId: result?.teamId,
          };
          let teamDescription = adminPage
            ? await removeAndUpdateTeamAdminOrganizationDescription(requestProperties)
            : await removeAndUpdateOrganizationDescription(requestProperties);

          if (!!teamDescription) {
            result = { ...result, organisationDescription: teamDescription?.organisationDescription };
          }
        }

        if (edit) {
          let newClientList = [...clientsList];
          let position = newClientList.findIndex((item) => item.teamId === activeClient?.teamId);
          if (position >= 0) {
            newClientList[position] = { ...newClientList[position], ...result };
          }
          setClientList(newClientList);
        } else {
          setClientList([result, ...clientsList]);
          if (!adminPage) {
            await setCurrentTeam({ teamId: result?.teamId });
          }
        }
        if (!adminPage) {
          //AE:Necessary to update master topic
          const activePlan = await getActivePlan(getAccessToken);
          if (!!activePlan) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
          }
        }
        dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
        createNotification('success', `Client ${edit ? 'updated' : 'created'} successfully`);
        closeModal();
      }
    } catch (error) {}
  };

  const typingWebsite = useRef(null);

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closeModal} maxWidth={600}>
      <h3 className='border-bottom px-4 py-3 title-h4-bold'>{edit ? 'Edit' : 'Create'} a client</h3>
      <div className='p-4'>
        <div className='flex-centered align-items-start'>
          <div className='flex-grow-1 pr-5'>
            <div className='mb-3'>
              <label className='font-weight-bold' htmlFor='clientName'>
                Client name
              </label>
              <input
                autoComplete='off'
                className='main-input form-control'
                ref={clientNameElement}
                id='clientName'
                value={clientData?.teamName}
                onChange={(e) => modifyClientData({ property: 'teamName', newValue: e.target.value })}
              />
            </div>

            <div className='mb-4'>
              <label className='font-weight-bold' htmlFor='website'>
                Client website
              </label>
              <input
                autoComplete='off'
                id='website'
                value={clientData?.organisationWebsite ?? ''}
                onChange={(e) => {
                  setIsLoadingLogotype(true);
                  modifyClientData({ property: 'organisationWebsite', newValue: e.target.value });
                }}
                className='form-control main-input'
                onKeyUp={(e) => {
                  let value = e.target.value;
                  if (typingWebsite.current) clearTimeout(typingWebsite.current);
                  typingWebsite.current = setTimeout(() => {
                    if (value !== '') {
                      let logo = new Image();
                      let url = `https://logo.clearbit.com/${value}?size=180`;
                      logo.src = url;
                      logo.onload = () => {
                        modifyClientData({
                          property: 'organisationLogo',
                          newValue: `https://logo.clearbit.com/${value}?size=180`,
                        });
                        setIsLoadingLogotype(false);
                      };
                      logo.onerror = () => {
                        setIsLoadingLogotype(false);
                      };
                    } else {
                      setIsLoadingLogotype(false);
                    }
                  }, 500);
                }}
              />
            </div>
          </div>
          <div>
            <label className='font-weight-bold' htmlFor='website'>
              Client logo
            </label>

            <div className='create-client-container-logo'>
              <ReportLogo
                logo={clientData?.organisationLogo}
                setLogo={(logo) => modifyClientData({ property: 'organisationLogo', newValue: logo })}
                notShowWebsite={true}
              />
            </div>
          </div>
        </div>

        <div>
          <label className='font-weight-bold mb-0' htmlFor='website'>
            Client description
          </label>
          <p className='mb-3 main-light-text paragraph-p2'>
            This enables you to create tailored summaries of any update in one click
          </p>

          <textarea
            autoComplete='off'
            className='main-input writing-textarea '
            value={clientData?.organisationDescription ?? ''}
            onChange={(e) => modifyClientData({ property: 'organisationDescription', newValue: e.target.value })}
          />
        </div>

        <div className='text-right py-4'>
          <button
            className='px-4 py-2 action-button general-button'
            disabled={
              isLoading ||
              isLoadingLogotype ||
              (edit
                ? activeClient?.teamName === clientData?.teamName &&
                  activeClient?.organisationLogo === clientData?.organisationLogo &&
                  activeClient?.organisationWebsite === clientData?.organisationWebsite &&
                  activeClient?.organisationDescription === clientData?.organisationDescription
                : clientData?.teamName === '')
            }
            onClick={async (e) => {
              setIsLoading(true);
              await createEditClient();
            }}
          >
            {isLoading && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />}
            {edit ? 'Save changes' : 'Create client'}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default CreateClient;

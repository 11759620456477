import { useContext, useState } from 'react';
import { useEffect, useRef } from 'react';
import { store } from '../../Store';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import AddTopicButton from '../../Dashboard/Navigation/AddTopicButton';
import normalize from '../../Dashboard/utilities/normalizeString';
import createNotification from '../Utilities/CreateNotification';
import CustomScrollbar from '../../Common/CustomScrollbar';

const WebsiteSettingsTopicsLocal = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const keywordsLists = props?.keywordsLists ?? state?.keywordsLists;
  const {
    selectedTopic,
    setSelectedTopic,
    selectedTopics,
    setSelectedTopics,
    multipleTopics,
    changeTopicsWord,
    client,
  } = props;

  const referenceHeight = 230;
  const elements = useRef([]);
  const [height, setHeight] = useState(referenceHeight);
  const { transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();
  const listsContainer = useRef(null);

  const calculateMaxHeightContainer = () => {
    if (elements.current?.length > 0) {
      let height = 16; //padding-top of box
      for (let i = 0; i < elements.current.length; i++) {
        let el = elements.current[i];
        let currentHeight = height + el.getBoundingClientRect().height; //margin value
        height = currentHeight;
      }
      if (height < referenceHeight) {
        setHeight(height);
      }
    }
  };

  useEffect(() => {
    calculateMaxHeightContainer();
  }, []);

  const masterTopic = keywordsLists.find((item) => item.id === null);
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const masterTopicSelected = multipleTopics
    ? selectedTopics?.length === keywordsListsToUse?.length
    : masterTopic?.id === selectedTopic.id;

  const agencyPlanCondition = newAgencyPlan && keywordsLists?.length > 0;

  return (
    <div className='keyword-lists-website-feeds'>
      <CustomScrollbar
        className={`main-content-scrollbar website-list-scroll-component main-content-section`}
        style={{ height: `${height}px`, marginTop: '8px' }}
        maximalThumbYSize={100}
      >
        <div
          className='website-popup-container-lists large-container'
          style={{ paddingBottom: '1px' }}
          ref={listsContainer}
        >
          <div className='login-navbar pb-2'>
            {!!masterTopic && (
              <div className='border-bottom pb-1'>
                <button
                  ref={(div) => (elements.current[0] = div)}
                  className='settings-button setting-item general-button d-block text-left w-100 px-4 position-relative'
                  onClick={() => {
                    if (multipleTopics) {
                      if (selectedTopics?.length === keywordsListsToUse?.length) {
                        setSelectedTopics([keywordsListsToUse[0]]);
                      } else {
                        setSelectedTopics(keywordsListsToUse);
                      }
                    } else {
                      setSelectedTopic(masterTopic);
                    }
                  }}
                >
                  <p className='mb-0 line-height-1-5'>
                    <span className='hidden-lines hidden-one-line' style={{ width: 'calc(100% - 65px' }}>
                      {changeTopicsWord && masterTopic?.name?.startsWith('All updates')
                        ? masterTopic?.name?.replace(
                            'All updates',
                            `All ${transformTopicOrClientWord({ plural: true })}`
                          )
                        : masterTopic?.name}
                    </span>
                  </p>
                  {masterTopicSelected && <span className='icon-tick topic-selected-mark paragraph-p4' />}
                </button>
              </div>
            )}
            {keywordsListsToUse?.length > 0 ? (
              <>
                {keywordsListsToUse?.map((item, index) => {
                  const { description, id, name } = item;
                  const selected = multipleTopics
                    ? !!selectedTopics.find((item) => item?.id === id)
                    : id === selectedTopic.id;
                  return (
                    <button
                      ref={(div) => (elements.current[!!masterTopic ? index + 1 : index] = div)}
                      className='settings-button setting-item general-button d-block text-left w-100 px-4 position-relative'
                      key={`topic-${normalize(name)}${index}`}
                      onClick={() => {
                        if (multipleTopics) {
                          let newItems = [...selectedTopics];
                          let itemPosition = newItems.findIndex((item) => item.id === id);
                          if (itemPosition >= 0) {
                            if (newItems.length === 1) {
                              createNotification('danger', 'You must have at least 1 topic selected');
                            } else {
                              newItems.splice(itemPosition, 1);
                            }
                            setSelectedTopics(newItems);
                          } else {
                            setSelectedTopics([...selectedTopics, item], item);
                          }
                        } else {
                          setSelectedTopic(item);
                        }
                      }}
                    >
                      <p className='mb-0 line-height-1-5'>
                        <span className='hidden-lines hidden-one-line' style={{ width: 'calc(100% - 65px' }}>
                          {name.startsWith('All updates')
                            ? name.replace('All updates', `All ${transformTopicOrClientWord({ plural: true })}`)
                            : name}
                        </span>
                      </p>

                      {description && <p className='topic-description-field mb-0 mt-1'>{description}</p>}
                      {selected && <span className='icon-tick topic-selected-mark paragraph-p4' />}
                    </button>
                  );
                })}
              </>
            ) : (
              <p className='mt-2 py-1 mb-0 main-light-text px-3' ref={(p) => (elements.current[0] = p)}>
                <em>You do not currently have any {transformTopicOrClientWord({ plural: true })} set up.</em>
              </p>
            )}
          </div>
        </div>
      </CustomScrollbar>
      {!agencyPlanCondition && <AddTopicButton client={client} keywordsLists={keywordsLists} />}
    </div>
  );
};

export default WebsiteSettingsTopicsLocal;

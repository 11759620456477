import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store.js';
import ContentRelated from './ContentRelated';
import axios from 'axios';
import callContentRelated from '../utilities/callContentRelated.js';
import normalizeString from '../utilities/normalizeString';
import SkeletonKeyUpdatesContent from './SkeletonKeyUpdatesContent.js';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import { useAuth0 } from '@auth0/auth0-react';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import { setFiltersLocalStorage } from '../utilities/setFiltersLocalStorage.js';
import addMetaTags from '../utilities/addMetaTags.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import KeyUpdateNewContent from './KeyUpdateNewContent.js';
import changeStakeholderLinks from '../helpers/changeStakeholderLinks.js';
import { getIdPositionOnSection, createNavigationLinksOnSection } from '../helpers/navigationFunctions.js';
import updateUnreadItems from '../helpers/updateUnreadItems.js';
import NavigationLinks from '../components/NavigationLinks.js';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords.js';
import useCallCollectContent from '../hooks/useCallCollectContent.js';
import useCallRelatedStakeholders from './useCallStakeholders.js';
import useCreateResults from '../ListControl/useCreateResults.js';
import useMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent.js';
import getUrlParam from '../utilities/getUrlParam.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const KeyUpdateContent = () => {
  const numberOfStakeHoldersToShow = parseInt(process.env.REACT_APP_RELATED_STAKEHOLDERS_TO_SHOW);
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, activeResults, net_api_url, search, activePlan, referenceState, loadingCreateSummary } =
    state;
  const { searchState, activeSearch } = search;
  const { enableSummariser } = activePlan;
  const { activeReference } = referenceState;
  const { KeyUpdates } = contentResults[activeResults];
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);
  const { id } = params;

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const getIdPosition = getIdPositionOnSection(KeyUpdates, id);
  const createNavigationLinks = createNavigationLinksOnSection(KeyUpdates);
  const { callRelatedStakeholders } = useCallRelatedStakeholders({});

  const [idPosition, setIdPosition] = useState(getIdPosition());
  const { queryHighlight, andKeywords } = useGetHighlightingKeywords();
  const { callCollectContent } = useCallCollectContent();

  const [navigationLinks, setNavigationLinks] = useState(createNavigationLinks(getIdPosition()));
  const barNavigationConditional =
    idPosition !== null && (navigationLinks.next !== null || navigationLinks.prev !== null);
  const [heightContainer, containerRef] = useHeightContainer();
  const topicParam = getUrlParam('topic-id');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let activePlanItems = Object.keys(activePlan);
    let stateReady = activePlanItems.length > 0;
    if ((stateReady || !isAuthenticated) && searchState) {
      setIdPosition(getIdPosition());
      setNavigationLinks(createNavigationLinks(getIdPosition()));
      loadNew(source);
    }

    return () => {
      source.cancel('Current new cancelled by the user.');
    };
  }, [id, searchState, activePlan, topicParam]);

  const { load_more_results } = useCreateResults({ propsSectionName: 'KeyUpdates' });
  const { getMatchingKeywordsListsAndFeedback } = useMatchingKeywordsListsForContent();

  const detectIfLoadMoreResults = () => {
    if (getIdPosition() !== null && getIdPosition() === KeyUpdates.hits.length - 1) {
      return true;
    }
    return false;
  };

  const loadNew = async (source) => {
    try {
      setIsLoadingRelatedContent(true);
      setIsLoading(true);
      //LOAD THE LIST IF IT IS THE LAST ITEM
      let listResults;
      if (detectIfLoadMoreResults()) {
        listResults = await load_more_results(source);
        setNavigationLinks(createNavigationLinks(getIdPosition()));
      }

      //SET IN LOCALSTORAGE
      let section = 'KeyupdatesContentPage';
      let filtersToApply = [];
      let token = isAuthenticated ? await getAccessToken() : '';
      setFiltersLocalStorage({ filtersToApply, section, state, dispatch, source, token, isAuthenticated });
      let contentRequest = {
        cancelToken: source.token,
      };
      if (isAuthenticated) {
        contentRequest.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      let newResult = await axios.get(`${net_api_url}/api/keyupdate/${id}`, contentRequest);
      const { objectId, objectType } = newResult.data;
      let propertiesHighlight = [objectId, objectType, queryHighlight, ChangeKeywordsExclusions(andKeywords), source];

      let contentHightlighted;
      let highlightCondition = activeSearch || activeReference;
      if (highlightCondition) {
        contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
      }

      let newData = newResult.data;
      let { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({ objectId });
      newData = { ...newData, feedback, keywordsFromLists, keywordsListsIds };

      if (highlightCondition && !!contentHightlighted) {
        const { highlightedTitle, highlightedHtmlBody } = contentHightlighted;
        const { subject, body } = newResult.data;
        newData = {
          ...newData,
          subjectHighlighted: !!highlightedTitle ? highlightedTitle : subject,
          body: !!highlightedHtmlBody ? highlightedHtmlBody : body,
        };
      }

      const updateResultsOnState = (newData) => {
        let data = {
          ...contentResults,
          [activeResults]: {
            KeyUpdates: newData,
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
      };

      let contentObject = !!listResults ? listResults[activeResults].KeyUpdates : KeyUpdates;
      let newSectionObject = contentObject;
      //REMOVE THE ITEM FROM THE LIST AND MARK AS READ
      let removeUnreadItem = updateUnreadItems({ idPosition, contentObject, getIdPosition });
      if (removeUnreadItem) {
        newSectionObject = removeUnreadItem;
      }
      let initialData = {
        ...newSectionObject,
        new: newData,
        currentId: parseInt(id),
      };
      updateResultsOnState(initialData);

      //UPDATE METATAGS
      let { descriptionMeta, imageId, subject } = newResult.data;
      let title = `${subject} - Key updates - PolicyMogul`;
      let hash = window.location.hash;
      let description = descriptionMeta;
      let contentUrl = `/key-updates/${id}/${normalizeString(subject)}`;
      let image = imageId;
      addMetaTags({ title, hash, location, dispatch, contentUrl, description, image });
      setIsLoading(false);

      //CALLS NECESSARY TO DO AFTER THE CONTENT IS SHOWN TO SPEED UP THE PAGE
      let contentRelated = await callContentRelated(objectId, objectType, source);
      const { contactIds } = newResult.data;
      const contactIdsConditional = !!contactIds?.length > 0;
      let contactsIdsCalled = [];

      if (contactIdsConditional) {
        let stakeholderFilters = [];

        for (let i = 0; i < numberOfStakeHoldersToShow; i++) {
          let contactId = contactIds[i];
          if (contactId !== undefined) {
            contactsIdsCalled.push(contactId);
            stakeholderFilters.push({ field: 'id', value: `${contactId}`, operator: 'str_eq' });
          }
        }

        let stakeHolderResults = await callRelatedStakeholders({ stakeholderFilters, source });
        let stakeholdersRendered = stakeHolderResults?.data?.hits;
        let contactsIdsFiltered = contactIds.filter((id) => !contactsIdsCalled.includes(id));

        while (
          contactsIdsFiltered?.length > numberOfStakeHoldersToShow &&
          stakeholdersRendered?.length < numberOfStakeHoldersToShow
        ) {
          let pageSize = numberOfStakeHoldersToShow - stakeholdersRendered?.length;
          let initialStakeholdersContactsIds = contactsIdsFiltered.slice(0, pageSize);
          let newStakeholdersFilters = initialStakeholdersContactsIds?.map((contactId) => {
            return { field: 'id', value: `${contactId}`, operator: 'str_eq' };
          });
          contactsIdsCalled = [...contactsIdsCalled, ...initialStakeholdersContactsIds];
          let newStakeholders = await callRelatedStakeholders({
            stakeholderFilters: newStakeholdersFilters,
            pageSize,
            source,
          });
          stakeholdersRendered = [...stakeholdersRendered, ...newStakeholders?.data?.hits];
        }

        contentRelated = {
          ...contentRelated,
          hits: { ...contentRelated['hits'], StakeHolders: stakeholdersRendered },
        };
      }
      let relatedData = {
        ...initialData,
        contentRelated: contentRelated,
        relatedStakeholders: contactIdsConditional ? contactIds.filter((id) => !contactsIdsCalled.includes(id)) : null,
        relatedStakeholdersRendered: contactIdsConditional ? contentRelated.hits.StakeHolders.length : 0,
      };
      updateResultsOnState(relatedData);
      setIsLoadingRelatedContent(false);
      await callCollectContent({ objectId, objectType, contentUrl });
    } catch (error) {}
  };

  let currentNew = !!KeyUpdates ? KeyUpdates.new : '';

  const keyupdateContentContainer = useCallback((node) => {
    if (node !== null) {
      let links = document.querySelectorAll('.keyupdate-content-container a');
      changeStakeholderLinks(links, navigate);
    }
  }, []);

  const contentContainer = useRef(null);

  return (
    <>
      {isLoading && <SkeletonKeyUpdatesContent />}
      {!isLoading && (
        <div ref={containerRef}>
          <div className='row px-0 piece-content-container'>
            <div className={`general-content-column padding-to-nav p-0`}>
              <div ref={contentContainer} />
              <div className='main-content-section content-container' style={{ height: `${heightContainer}px` }}>
                <CustomScrollbar
                  className={'simple-scrollbar indented-scrollbar-small'}
                  style={{ height: `${heightContainer - (barNavigationConditional ? 43 : 0)}px` }}
                  minimalThumbYSize={55}
                  maximalThumbYSize={100}
                >
                  <div className='main-update-content-container' ref={keyupdateContentContainer}>
                    <KeyUpdateNewContent
                      currentNew={currentNew}
                      contentContainer={contentContainer}
                      setIsLoading={setIsLoading}
                      hideSummary={enableSummariser !== undefined && !enableSummariser}
                    />
                  </div>
                </CustomScrollbar>
                <NavigationLinks
                  barNavigationConditional={barNavigationConditional}
                  navigationLinks={navigationLinks}
                  disabled={loadingCreateSummary}
                />
              </div>
            </div>
            <div className='related-content-column show-lg px-0'>
              <ContentRelated
                data={currentNew}
                content={KeyUpdates?.contentRelated}
                relatedStakeholders={KeyUpdates?.relatedStakeholders}
                relatedStakeholdersRendered={KeyUpdates?.relatedStakeholdersRendered}
                section={'key update'}
                mainSection={'KeyUpdates'}
                isLoadingRelatedContent={isLoadingRelatedContent}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
  //}
};

export default KeyUpdateContent;

import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { store } from '../../Store.js';
import { locationTopicDescriptor } from '../helpers/locationSearchDescriptor.js';
import parliamentarianUsers from '../sidebar/parliamentarianUsers.js';
import getUrlParam from '../utilities/getUrlParam.js';
import returnCorrectWPUrl from '../utilities/returnCorrectWPUrl';
import useIsMobile from '../utilities/useIsMobile.js';
import SimpleSearchBar from './SimpleSearchBar';
import dayjs from 'dayjs';
import { NavLink, useLocation } from 'react-router-dom-v5-compat';
import { AnalyticsPopup, ReportsPopup } from './NotFeaturePopups.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import { useSetListsIds } from '../MainSearchBar/setListIds.js';

const NavPages = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, activePlan, memoryMasterTopic, selectedCrmContacts, selectedStakeholders } = state;

  const { code, enableAnalytics, teamName, enableReports } = activePlan;
  const closeMenu = () => {
    let hamburgerMenu = document.getElementsByClassName('hamburger')[0];
    hamburgerMenu.classList.remove('is-active');
    dispatch({ type: 'SHOW_HIDE_MENU', value: true });
  };
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const firstList = keywordsLists?.[0];

  const isContactsSelected =
    selectedCrmContacts.selectedIds.length > 0 ||
    selectedCrmContacts.selectedAll ||
    selectedCrmContacts.selectedAllVisible.length > 0;
  const isStakeholdersSelected =
    selectedStakeholders.selectedIds.length > 0 ||
    selectedStakeholders.selectedAll ||
    selectedStakeholders.selectedAllVisible.length > 0;

  const activeSubNavCondition =
    (location.pathname.includes('/contacts') && isContactsSelected) ||
    (isStakeholdersSelected && location.pathname.includes('/stakeholder-mapping'));

  const createMonitorUrl = () => {
    let search;
    if (memoryMasterTopic && keywordsLists?.length > 1) {
      search = '?topic-id=all';
    } else {
      search = `${
        locationTopicDescriptor(location) !== '' && getUrlParam('topic-id') !== 'none'
          ? locationTopicDescriptor(location)
          : `${firstList ? `?topic-id=${firstList.id === null ? 'all' : firstList.id}` : ''}`
      }`;
    }
    return `/monitor-inbox/key-updates${search}`;
  };

  const monitorUrl = createMonitorUrl();
  const { stakeholderUrl, policymakerUrl, stakeholderTopicParam } = useResearchItemsUrl();
  const hideInfluenceSection =
    parliamentarianUsers(code) || (teamName === 'NCA' && user.email !== 'james.mitchell@nca.gov.uk');
  const isMobile = useIsMobile();
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <ul
        className={`policy-list d-flex flex-lg-row flex-column mb-0 px-0 nav-pages ${
          parliamentarianUsers(code) ? 'nav-pages-additional-links' : ''
        } pt-lg-0 ${isAuthenticated ? 'pt-3' : ''}`}
      >
        {isAuthenticated && Object.keys(activePlan).length > 0 && (
          <>
            {isMobile && (
              <li className='search-nav-button-container py-3 mb-2'>
                <div className='home-searchbar-container border-grey border rounded-button sidenav-searchbar'>
                  <SimpleSearchBar
                    homePage
                    enforceShortPlaceholder
                    closeFunction={() => {
                      closeMenu();
                    }}
                  />
                </div>
              </li>
            )}
            <li>
              <NavLink
                to={monitorUrl}
                className={({ isActive }) =>
                  `${isActive ? 'active-page' : ''} static-page-monitor topnav-monitor-link static-page-link ${
                    location.pathname.includes('/monitor-inbox') ? 'active-page ' : ''
                  }`
                }
                data-intercom-target='Monitor link'
                onClick={(e) => {
                  closeMenu();
                }}
              >
                Monitor
              </NavLink>
            </li>
            {parliamentarianUsers(code) && (
              <li>
                <NavLink
                  to={`/campaigns`}
                  className={({ isActive }) =>
                    `${isActive ? 'active-page' : ''} topnav-monitor-link static-page-link campaigns-page`
                  }
                  data-intercom-target='Campaigns link'
                  onClick={(e) => {
                    closeMenu();
                  }}
                >
                  Campaigns
                </NavLink>
              </li>
            )}
            {isMobile && (
              <li>
                <NavLink
                  to={`/calendar/${dayjs().format('YYYY-MM-DD')}`}
                  className={({ isActive }) => `${isActive ? 'active-page' : ''} static-page-link topnav-calendar-link`}
                  data-intercom-target='Calendar link'
                  onClick={(e) => {
                    closeMenu();
                  }}
                >
                  Calendar
                </NavLink>
              </li>
            )}
            {!hideInfluenceSection && (
              <li>
                <NavLink
                  to={`/influence${stakeholderTopicParam}`}
                  className={({ isActive }) =>
                    `${isActive ? `${activeSubNavCondition ? 'active-page-active-subnav' : 'active-page'}` : ''} static-page-link topnav-influence-link ${
                      location.pathname.includes('/influence') ? 'active-page ' : ''
                    }`
                  }
                  onClick={(e) => {
                    closeMenu();
                  }}
                  data-intercom-target='Influence link'
                >
                  Influence
                </NavLink>
              </li>
            )}
            {parliamentarianUsers(code) && isMobile && (
              <li>
                <NavLink
                  to={parliamentarianUsers(code) ? policymakerUrl : stakeholderUrl}
                  className={({ isActive }) =>
                    `${isActive ? 'active-page' : ''} static-page-link topnav-influence-link`
                  }
                  onClick={(e) => {
                    closeMenu();
                  }}
                  data-intercom-target='Parliamentarian stakeholder mapping link'
                >
                  Policymakers
                </NavLink>
              </li>
            )}
            {enableAnalytics && !parliamentarianUsers(code) && (
              <AnalyticsLink closeMenu={closeMenu} enableAnalytics={enableAnalytics} />
            )}
            {!newAgencyPlan && (
              <li>
                <NavLink
                  to={`/ai-assistant`}
                  className={`static-page-link topnav-influence-link ${
                    location.pathname.match(/\/ai-assistant\/content\/\w+/i)?.input ? 'ai-assistant-content' : ''
                  } ${location.pathname.includes('/ai-assistant') ? 'active-page' : ''}`}
                  onClick={(e) => {
                    closeMenu();
                  }}
                  data-intercom-target='Writing assistant link'
                >
                  AI assistant
                </NavLink>
              </li>
            )}
            {(!enableAnalytics || parliamentarianUsers(code)) && (
              <AnalyticsLink closeMenu={closeMenu} enableAnalytics={enableAnalytics} />
            )}
            <ReportsLink closeMenu={closeMenu} enableReports={enableReports} />
          </>
        )}
        {!isAuthenticated && (
          <>
            <li>
              <a href={`${returnCorrectWPUrl()}/product`} className='static-page-link'>
                Product
              </a>
            </li>
            <li>
              <NavLink
                to='/pricing'
                className={({ isActive }) => `${isActive ? 'active-page' : ''} static-page-link`}
                onClick={() => {
                  closeMenu();
                }}
              >
                Pricing
              </NavLink>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/customer-stories`} className='static-page-link'>
                Customers
              </a>
            </li>
            <li>
              <NavLink
                to='/data-hub'
                className={({ isActive }) =>
                  `static-page-link ${isActive || location.pathname.includes('/data-hub') ? 'active-page' : ''}`
                }
                onClick={() => {
                  closeMenu();
                }}
              >
                Data hub
              </NavLink>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/for-parliamentarians`} className='static-page-link'>
                For parliamentarians
              </a>
            </li>
          </>
        )}
      </ul>
    </>
  );
};

const AnalyticsLink = (props) => {
  const { closeMenu, enableAnalytics } = props;
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <li>
        <NavLink
          to={`/analytics/dashboard${
            getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all'
              ? `?topic-id=${getUrlParam('topic-id')}`
              : `${getUrlParam('search') && location.pathname.startsWith('/analytics') ? `${location.search}` : ''}`
          }`}
          className={({ isActive }) =>
            `${isActive ? 'active-page' : ''} static-page-link topnav-influence-link analytics-static-link ${
              location.pathname.match(/\/analytics\/\w+/i)?.input ? 'active-page' : ''
            }`
          }
          onClick={(e) => {
            if (!enableAnalytics) {
              e.preventDefault();
              setShowPopup(true);
            }
            closeMenu();
          }}
          data-intercom-target='Analytics page'
        >
          Analytics
        </NavLink>
      </li>
      <AnalyticsPopup isOpen={showPopup} setIsOpen={setShowPopup} />
    </>
  );
};

const ReportsLink = (props) => {
  const { closeMenu, enableReports } = props;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { agencyUser } = useTopicOrClientWord();
  return (
    <>
      {!agencyUser && (
        <li className='d-none d-lg-block'>
          <NavLink
            to={`/reports`}
            className={`static-page-link topnav-influence-link reports-static-link ${location.pathname.includes('/reports') ? 'active-page' : ''}`}
            onClick={(e) => {
              if (!enableReports) {
                e.preventDefault();
                setIsOpen(true);
              }
              closeMenu();
            }}
            data-intercom-target='Reports link'
          >
            Reports
          </NavLink>
        </li>
      )}
      <ReportsPopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const useResearchItemsUrl = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, modifiedListId } = state;
  const { getStoredListIds } = useSetListsIds();
  const storedLists = getStoredListIds({});
  let localStorageId;
  if (!!storedLists) {
    localStorageId = storedLists?.[0];
  }
  const stakeholdersfirstList = keywordsLists?.find((item) =>
    modifiedListId ? item.id === modifiedListId : localStorageId ? item.id === localStorageId : item.id !== null
  );

  const stakeholderTopicParam =
    getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all'
      ? `?topic-id=${getUrlParam('topic-id')}`
      : stakeholdersfirstList
        ? `?topic-id=${stakeholdersfirstList.id}`
        : '';

  const stakeholderUrl = `/influence/stakeholder-mapping${stakeholderTopicParam}`;
  const policymakerUrl = `/policymakers${stakeholderTopicParam}`;

  return { stakeholderUrl, policymakerUrl, stakeholderTopicParam };
};

export { useResearchItemsUrl };
export default NavPages;

import React, { useState, useRef, useCallback } from 'react';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';

const FrequencySelect = (props) => {
  const [showFrequency, setShowFrequency] = useState(false);
  const { changeFrequency, frequency, allow } = props;
  const frequencyElement = useRef(null);
  const hideDay = () => {
    setShowFrequency(false);
  };
  useOutsideElement(frequencyElement, hideDay);
  const createFrequencies = () => {
    let frequencies = ['Immediate', 'Weekdaily', 'Weekly'];
    let items = [];
    for (let i = 0; i < frequencies.length; i++) {
      items.push(
        <FrequencyItem
          key={`frequency-${i}`}
          frequency={frequencies[i]}
          selectedFrequency={frequency}
          setFrequency={setFrequency}
        />
      );
    }
    return items;
  };
  let setFrequency = (frequency) => {
    changeFrequency(frequency);
    setShowFrequency(false);
  };

  return (
    <div className='position-relative' ref={frequencyElement}>
      <p
        className={`mb-0 hour-select px-3 ${allow ? '' : 'disabled-hour-select'} ${showFrequency ? 'hour-select-opendropdown' : ''}`}
        onClick={() => {
          if (allow) {
            setShowFrequency(!showFrequency);
          }
        }}
      >
        {frequency === 'Weekdaily' ? 'Daily' : frequency}
      </p>

      {showFrequency && (
        <>
          <div className='frequency-dropdown'>
            <div className='scroll-container' id='frequency-dropdown'>
              {createFrequencies()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const FrequencyItem = (props) => {
  let { frequency, setFrequency, selectedFrequency } = props;
  let scrollDay = useCallback((node) => {
    if (node !== null) {
      if (frequency === selectedFrequency) {
        let top = node.getBoundingClientRect().top;
        let element = document.getElementById('frequency-dropdown');
        let elementTop = element.getBoundingClientRect().top;
        element.scrollTop = top - elementTop;
      }
    }
  }, []);
  return (
    <p
      className={`mb-0 frequency-item px-3 py-2 ${frequency === selectedFrequency ? 'frequency-item-selected' : ''}`}
      onClick={() => setFrequency(frequency)}
      ref={scrollDay}
    >
      {frequency === 'Weekdaily' ? 'Daily' : frequency}
    </p>
  );
};

export default FrequencySelect;

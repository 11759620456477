import React from 'react';
import useTopicOrClientWord from '../../../Dashboard/hooks/useTopicOrClientWord';
import PauseEmailAlert from '../../Topics/PauseEmailAlert';
import CreateANewEmailAlertButton from './CreateANewEmailAlertButton';

const ListOfEmailAlerts = (props) => {
  const { emailAlerts, allowAlerts, setIsOpenEditEmailAlert, addEmailAlertButton } = props;
  let messageConditional = emailAlerts?.length === 0;
  const { transformTopicOrClientWord } = useTopicOrClientWord();

  return (
    <>
      <div className='padding-topic-component border-bottom py-3 bg-salmon'>
        <div className='px-lg-2 px-xl-4'>
          <div className='flex-centered justify-content-between'>
            <h3 className='title-h4-bold email-based-title topics-item-digest-combining mb-0'>
              <span className='icon-topics-master'></span>Based on your {transformTopicOrClientWord({ plural: true })}
            </h3>
            {!addEmailAlertButton && (
              <CreateANewEmailAlertButton
                emailAlertsPage
                allowAlerts={allowAlerts}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
              />
            )}
            {addEmailAlertButton && <>{addEmailAlertButton()}</>}
          </div>
        </div>
      </div>
      {messageConditional ? (
        <div className='padding-topic-component'>
          <p className='py-4 mb-0 main-light-text px-lg-2 px-xl-4'>
            You do not currently have any email alerts set up for your {transformTopicOrClientWord({ plural: true })}
          </p>
        </div>
      ) : (
        <div className='padding-topic-component' style={{ marginTop: '-20px' }}>
          {emailAlerts?.map((item) => {
            const { id } = item;
            return <EmailAlertItem {...props} key={`email-alert-${id}`} item={item} />;
          })}
        </div>
      )}
    </>
  );
};
const EmailAlertItem = (props) => {
  const { setIsOpenEditEmailAlert, setEmailAlertId, item, additionalFunction } = props;
  const {
    nameToShow,
    keywordLists,
    frequencyName,
    resourcesName,
    digestCombiningAllTopics,
    contentTypesToReceive,
    alertTypes,
    sendEmbargoedKeyUpdatesImmediately,
  } = item;
  const { transformTopicOrClientWord } = useTopicOrClientWord();

  const EditTopicButton = ({ item }) => {
    return (
      <button
        className='general-button outline-button main-light-text paragraph-p3 edit-email-alert-button'
        onClick={(e) => {
          e.stopPropagation();
          setEmailAlertId(item.id);
          setIsOpenEditEmailAlert(true);
          if (additionalFunction) {
            additionalFunction();
          }
        }}
      >
        Edit <span className='icon-edit-pencil'></span>
      </button>
    );
  };
  const createTopicsNames = () => {
    let topicsList = digestCombiningAllTopics ? props.keywordsLists : keywordLists;
    let digestConditional = digestCombiningAllTopics && props.keywordsLists.length > 1;
    return (
      <>
        {digestConditional && (
          <a
            href={`/settings/${transformTopicOrClientWord({ plural: true })}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            All of your {transformTopicOrClientWord({ plural: true })}
          </a>
        )}
        {!digestConditional && (
          <>
            {topicsList.map((item, index) => (
              <a
                key={`topic-${item.id}`}
                target='_blank'
                rel='noopener noreferrer'
                href={`/settings/${transformTopicOrClientWord({ plural: true })}/${item.id}`}
              >
                {item.name}
                {index < topicsList.length - 1 ? ', ' : ''}
              </a>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <div className={`alert-list-main-container pb-5`}>
      <div className='px-lg-2 px-xl-4'>
        <div className={`row mx-0 justify-content-between align-items-center pt-5 pb-3 mt-3 mt-sm-0`}>
          <p className='topics-item-name_alert--text'>{nameToShow}</p>
          <div className='pause-edit-alert d-flex flex-row-reverse flex-sm-row '>
            <EditTopicButton item={item} />
            <PauseEmailAlert {...props} item={item} />
          </div>
        </div>

        <div className={`transition-element`}>
          <p className={`alert-list-text-item mb-2 topiclist-alert-icon`}>
            <span className='icon-topics-thin'></span>
            {createTopicsNames()}
          </p>
          <p className={`alert-list-text-item mb-2 frequency-alert-icon`}>
            <span className='icon-clock'></span>Sent{' '}
            {frequencyName === 'Immediate' ? 'as soon as it happens' : `on ${frequencyName}`}
          </p>
          {sendEmbargoedKeyUpdatesImmediately && (
            <p className={`alert-list-text-item mb-2 frequency-alert-icon`}>
              <span className='icon-clock'></span>Exception: News under embargo sent immediately
            </p>
          )}
          <p className={`alert-list-text-item resources-alert-icon`}>
            <span className='icon-description'></span>
            {resourcesName}
          </p>
          {process.env.REACT_APP_ENABLE_TWITTER === 'true' &&
            contentTypesToReceive.includes('Tweets') &&
            !contentTypesToReceive.includes('Retweets') && (
              <p className={`alert-list-text-item retweets-alert-icon mt-2`}>Excludes Retweets</p>
            )}
          <p className='alert-list-text-item mt-2'>
            {alertTypes.split(', ').map((item) => {
              return (
                <span className='d-inline-block mr-3' key={`alert-${item}`}>
                  {item === 'Slack' ? (
                    <img
                      style={{
                        verticalAlign: 'middle',
                        marginTop: '-3px',
                        width: '15px',
                        marginLeft: '-2px',
                        marginRight: '6px',
                      }}
                      className='d-inline-block'
                      src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                      alt='slack logo'
                    />
                  ) : (
                    <span
                      className='icon-email-rounded paragraph-p5 d-inline-block'
                      style={{ marginRight: '7px', marginLeft: '-1px' }}
                    />
                  )}
                  {item} alert
                </span>
              );
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export { EmailAlertItem };
export default ListOfEmailAlerts;

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import useCallCollectContent from '../../hooks/useCallCollectContent';
import useNumberOfUnseenItems from '../../hooks/useNumberOfUnseenItems';
import masterTopicObject from '../../MainSearchBar/masterTopicObject';
import addMetaTags from '../../utilities/addMetaTags';
import ChangeSearchQuery from '../../utilities/changeSearchQuery';
import getSearchWithinHash from '../../utilities/getSearchWithinHash';
import getUrlParam from '../../utilities/getUrlParam';
import normalize from '../../utilities/normalizeString';
import {
  searchIncludesTitleConditional,
  checkSearchTitleValue,
  relevantActivityFalseCondition,
} from '../searchIncludesTitle';
import Stakeholder from './ui/Stakeholder';
import useFilterFromType from './useFilterFromType';
import useContactsFunctionForStakeholders from './useContactsFunctionForStakeholder';
import { sendEmailToContacts } from '../../Influence/CrmContacts/CrmContactsTable/CrmContactsListBar/sendEmailToContacts';
import customApiCall from '../../apiCalls/customApiCall';
import getCurrentTeam from '../../../Settings/Team/getCurrentTeam';
import useGetAllDomains from '../../Influence/api/useGetAllDomains';
import useGetContactsTags from '../../Filters/useGetContactsTags';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import callHightlightContentAPI from '../../utilities/callHightlightContentAPI';
import useGetHighlightingKeywords from '../../hooks/useGetHighlighingKeywords';
import ChangeKeywordsExclusions from '../../utilities/changeKeywordExclusions';
import useGetAccessToken from '../../apiCalls/useGetAccessToken';

const StakeholderPage = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, search, activeResults, contentResults, keywordsLists, team, listDomainsVerified } = state;
  const { list, activeSearch, searchState } = search;
  const { enableStakeholders } = activePlan;
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { Stakeholders } = contentResults[activeResults];
  const { contentRelated } = Stakeholders ? Stakeholders : {};
  const stakeholderSearchCondition = isAuthenticated && enableStakeholders;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(false);
  const [clickOnSeeMore, setClickOnSeeMore] = useState(false);
  const { callCollectContent } = useCallCollectContent();

  useGetContactsTags();

  const { andKeywords, queryHighlight } = useGetHighlightingKeywords();

  const createLocalTopic = () => {
    if (getUrlParam('topic-id')) {
      let keywordListid = getUrlParam('topic-id') === 'all' ? null : parseInt(getUrlParam('topic-id'));
      let listFromParam = keywordsLists.find((item) => item.id === keywordListid);
      return listFromParam ? listFromParam : null;
    } else {
      return list;
    }
  };
  const [localTopic, setLocalTopic] = useState(createLocalTopic());
  const [searchPageQuery, setSearchPageQuery] = useState(
    getUrlParam('search') && stakeholderSearchCondition ? getUrlParam('search') : ''
  );
  const [filterSentiment, setFilterSentiment] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { id, contentType } = params;
  const { filterFromType, changeContentType, changeContentypeFromUrl } = useFilterFromType({ stakeholderPage: true });

  const loadNew = useRef();
  const dispatchInitialCall = useRef();
  const reloadOnSearch = useRef();
  const useFilterFromUrl = useRef(true);
  const { generalApiCall } = useGeneralApiCall();
  const { numberOfUnseenItems } = useNumberOfUnseenItems();
  const { verifyIfContactIsAdded } = useContactsFunctionForStakeholders();
  const { verifiedDomains, allDomains, domainsProccessing, allEmailAddresses, verifiedEmailAddresses } =
    useGetAllDomains();

  const activityContainer = useRef();
  const isMounted = useRef(false);

  const resourcesToShow = 3;
  const contentRelatedpageSize = 50;
  const searchParam = getUrlParam('search');
  const topicParam = getUrlParam('topic-id');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    dispatchInitialCall.current(source);
    return () => {
      source.cancel('Stakeholder item canceled by the user.');
    };
  }, [id, searchParam, searchState, activePlan, contentType]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'listDomainsVerified',
      value: { verifiedDomains, allDomains, domainsProccessing, allEmailAddresses, verifiedEmailAddresses },
    });
  }, [verifiedDomains, allDomains, domainsProccessing]);

  dispatchInitialCall.current = (source) => {
    try {
      if (location.pathname.includes('/monitor')) {
        let newUrl = location.pathname.replace('/monitor', '/influence');
        return navigate(newUrl);
      }
      let filters = useFilterFromUrl.current && getSearchWithinHash() ? getSearchWithinHash() : searchPageQuery;
      setSearchPageQuery(filters);
      let activePlanItems = Object.keys(activePlan);
      if ((activePlanItems.length > 0 || !isAuthenticated) && searchState) {
        loadNew.current(source);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (isMounted.current) {
      reloadOnSearch.current();
    }
  }, [searchParam, topicParam]);

  reloadOnSearch.current = () => {
    if (Stakeholders && Stakeholders.contact) {
      if (!!searchParam && searchParam !== searchPageQuery) {
        changeTopicOrQuery({ freeSearch: getUrlParam('search') });
      }
      if (topicParam && (localTopic === null || topicParam !== localTopic.id)) {
        changeTopicOrQuery({ topic: keywordsLists.find((item) => item.id === parseInt(topicParam)) });
      }
    }
  };

  loadNew.current = async (source) => {
    setIsLoading(true);
    const pathname = `/api/stakeholder/${id}`;
    const method = 'get';
    if (Object.keys(team)?.length === 0 && isAuthenticated) {
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
    }
    const content = await generalApiCall({
      pathname,
      method,
      needsAuthentication: isAuthenticated,
      requestSource: source,
      notShowErrorMessage: true,
    });
    if (content) {
      const queryToSearch =
        getUrlParam('search') &&
        stakeholderSearchCondition &&
        !searchIncludesTitleConditional(content.officalName) &&
        !relevantActivityFalseCondition()
          ? getUrlParam('search')
          : null;
      const relatedContent = await relatedContentCall({ content, freeSearch: queryToSearch, sourceProp: source });

      let profileContentHightlighted;
      if (!!(getUrlParam('and') || getUrlParam('search') || getUrlParam('or'))) {
        let propertiesHighlight = [
          content.objectId,
          content.objectType,
          queryHighlight,
          ChangeKeywordsExclusions(andKeywords),
          source,
        ];
        profileContentHightlighted = await callHightlightContentAPI(propertiesHighlight);
      }

      const latestTweets = showTweets ? await callLatestTweets({ content, sourceProp: source }) : null;
      let matchInformation;
      const contactInformation = isAuthenticated ? await verifyIfContactIsAdded({ contactId: content?.id }) : [];
      let ranking;
      if (!!localTopic?.id && localTopic?.id !== null && isAuthenticated) {
        ranking = await callAgregatedSentiment({ stakeholderId: content?.id, keywordListId: localTopic?.id });
        matchInformation = transformMatchingInformationCall({ ranking });
      }

      let resultsForState = {
        ...contentResults,
        [activeResults]: {
          Stakeholders: {
            ...Stakeholders,
            contact: {
              ...content,
              latestTweets: activeSearch && latestTweets && showTweets ? latestTweets?.hits : [],
              matchInformation: matchInformation ?? {},
              contactInformation,
              ranking,
              profile: profileContentHightlighted ? profileContentHightlighted?.highlightedHtmlBody : content.profile,
            },
            currentId: parseInt(id),
            contentRelated: relatedContent,
          },
        },
      };

      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
      useFilterFromUrl.current = false;
      setIsLoading(false);
      addMetaData({ content });
      isMounted.current = true;

      const listToSend =
        list !== null && list.id !== null && !getUrlParam('search') && stakeholderSearchCondition ? list : null;
      collectCall({ contact: content, topic: listToSend });
      await numberOfUnseenItems();
    }
  };

  const relatedContentCall = async ({
    content,
    pageNumber,
    topic,
    freeSearch,
    sentiment = filterSentiment,
    filtersProps,
    sourceProp,
  }) => {
    let contentRelated;
    const { officalName } = content;
    let commonProperties = {
      freeSearch,
      content,
      topic,
      filtersProps,
    };
    let commonPropertiesWithSentiment = {
      ...commonProperties,
      sentiment,
    };

    if (contentType === undefined) {
      let ParliamentaryRecord = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'ParliamentaryRecord',
      });
      let WrittenQuestion = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'WrittenQuestion',
        typeToUse: 'ParliamentaryRecord',
      });
      let HolyroodCountrySpecificAnswer = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'CountrySpecificWrittenAnswer',
        additionalFilters: [{ field: 'country', operator: 'str_eq', value: 'Scotland' }],
      });
      let SeneddCountrySpecificAnswer = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'CountrySpecificWrittenAnswer',
        additionalFilters: [{ field: 'country', operator: 'str_eq', value: 'Wales' }],
      });
      let WrittenAnswer = await callRelatedContent({ ...commonPropertiesWithSentiment, type: 'WrittenAnswer' });
      let KeyUpdate = await callRelatedContent({ ...commonProperties, type: 'KeyUpdate' });
      let EdmPrimary = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'Edm',
        filtersProps: [{ field: 'primarySponsorContactId', value: `${id}`, operator: 'STR_EQ' }],
      });
      let EdmSupported = await callRelatedContent({
        ...commonPropertiesWithSentiment,
        type: 'Edm',
        filtersProps: [{ field: 'signatoryContactIds', value: `${id}`, operator: 'STR_EQ' }],
      });
      let tweetCondition =
        stakeholderSearchCondition &&
        !searchIncludesTitleConditional(officalName) &&
        !relevantActivityFalseCondition() &&
        showTweets;
      let tweet = tweetCondition ? await callRelatedContent({ ...commonPropertiesWithSentiment, type: 'tweet' }) : [];
      contentRelated = {
        ParliamentaryRecord,
        KeyUpdate,
        WrittenQuestion,
        HolyroodCountrySpecificAnswer,
        SeneddCountrySpecificAnswer,
        WrittenAnswer,
        EdmPrimary,
        EdmSupported,
      };
      if (tweetCondition) {
        contentRelated.tweet = tweet;
      }
    } else {
      let contentTypeToUse = changeContentType(contentType);
      let typeToApply =
        contentTypeToUse === 'EdmPrimary' || contentTypeToUse === 'EdmSupported'
          ? 'Edm'
          : contentTypeToUse === 'HolyroodCountrySpecificAnswer' || contentTypeToUse === 'SeneddCountrySpecificAnswer'
            ? 'CountrySpecificWrittenAnswer'
            : contentTypeToUse;
      let filtersProps;
      let additionalFilters;
      if (contentType === 'early-day-motions') {
        filtersProps = [{ field: 'primarySponsorContactId', value: `${id}`, operator: 'STR_EQ' }];
      } else if (contentType === 'early-day-motions-supported') {
        filtersProps = [{ field: 'signatoryContactIds', value: `${id}`, operator: 'STR_EQ' }];
      } else if (contentTypeToUse === 'HolyroodCountrySpecificAnswer') {
        additionalFilters = [{ field: 'country', operator: 'str_eq', value: 'Scotland' }];
      } else if (contentTypeToUse === 'SeneddCountrySpecificAnswer') {
        additionalFilters = [{ field: 'country', operator: 'str_eq', value: 'Wales' }];
      }
      let properties = contentType === 'key-updates' ? commonProperties : commonPropertiesWithSentiment;
      let contentRelatedFromType = await callRelatedContent({
        ...properties,
        type: typeToApply,
        pageNumber,
        filtersProps,
        additionalFilters,
        typeToUse: contentTypeToUse === 'WrittenQuestion' ? 'ParliamentaryRecord' : undefined,
        sourceProp,
      });
      contentRelated = { [changeContentType(contentType)]: contentRelatedFromType };
    }

    return contentRelated;
  };

  const callRelatedContent = async (props) => {
    const {
      type,
      content,
      pageNumber,
      freeSearch,
      topic,
      sentiment,
      filtersProps,
      additionalFilters,
      typeToUse,
      sourceProp,
    } = props;
    const listToApply = topic ?? localTopic;
    const filters = filterFromType(type, content);

    let pathname = `/api/${!!listToApply?.id && enableStakeholders && !freeSearch ? 'search/query-by-keyword-list' : 'search/query'}`;
    let method = 'post';
    let requestProperties = {
      type: typeToUse ?? type,
      pageSize:
        contentType === undefined && changeContentType(contentType) !== type ? resourcesToShow : contentRelatedpageSize,
      filters,
      pageNumber: pageNumber ?? 1,
      sort: { field: 'dateTime', order: 'DESC' },
    };

    if (filtersProps) {
      requestProperties = {
        ...requestProperties,
        filters: filtersProps,
      };
    }

    if (additionalFilters) {
      requestProperties = {
        ...requestProperties,
        filters: [...filters, ...additionalFilters],
      };
    }

    if (sentiment) {
      requestProperties = {
        ...requestProperties,
        filters: [...requestProperties.filters, { field: 'sentiment', value: `${sentiment}`, operator: 'STR_EQ' }],
      };
    }

    if (type === 'WrittenQuestion') {
      requestProperties = {
        ...requestProperties,
        filters: [
          ...requestProperties.filters,
          { field: 'contentType', operator: 'str_eq', value: 'QuestionAndAnswer' },
          { field: 'contentType', operator: 'str_eq', value: 'ScotlandQuestionAndAnswer' },
          { field: 'contentType', operator: 'str_eq', value: 'WalesQuestionAndAnswer' },
        ],
      };
    }

    let bigInt = true;
    let sort;
    let collapseField;
    let parliamentaryRecordItem =
      type === 'WrittenQuestion' ||
      type === 'CountrySpecificWrittenAnswer' ||
      type === 'ParliamentaryRecord' ||
      type === 'WrittenAnswer' ||
      type === 'Edm';
    let needsAuthentication = isAuthenticated;

    if (type === 'WrittenQuestion' || type === 'CountrySpecificWrittenAnswer' || type === 'WrittenAnswer') {
      collapseField = null;
    }
    if (type === 'ParliamentaryRecord') {
      collapseField = 'contentUrl';
    }
    if (parliamentaryRecordItem) {
      requestProperties = { ...requestProperties, doNotSearchTitle: true };
    }
    if (type === 'tweet' && activeResults === 'allResults') {
      sort = { field: 'dateTimeTimestamp', order: 'DESC' };
    }

    if (sort !== undefined) {
      requestProperties = { ...requestProperties, sort };
    }

    if (collapseField !== undefined) {
      requestProperties = {
        ...requestProperties,
        collapseField: collapseField,
        collapseSort: { field: 'contributionOrder', order: 'asc' },
      };
    }

    if (listToApply !== null && listToApply.id !== null && enableStakeholders && !freeSearch) {
      requestProperties = {
        ...requestProperties,
        keywordListId: listToApply.id,
      };
      if (stakeholderSearchCondition) {
        requestProperties = {
          ...requestProperties,
          includeExplanations: true,
        };
      }
    } else {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch ?? ''),
      };
    }

    let searchApplied = activeSearch || freeSearch;
    let parliamentaryCondition = parliamentaryRecordItem && searchApplied;

    let keyUpdateHighlighted =
      type === 'KeyUpdate' && ((searchApplied && list !== null) || (searchApplied && listToApply === null));
    if (keyUpdateHighlighted || parliamentaryCondition) {
      requestProperties = {
        ...requestProperties,
        includeHighlights: true,
      };
    }
    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication,
      bigInt,
      requestSource: sourceProp ?? source,
      notShowErrorMessage: true,
    });
    if (results) {
      let hitsResults = pageNumber ? contentRelated[type].hits : [];
      let explanations = pageNumber ? contentRelated[type].explanations : [];
      let hitsHighlighted =
        results?.highlights?.length > 0
          ? results?.hits?.map((item, index) => {
              return {
                ...item,
                highlights: results?.highlights[index],
              };
            })
          : results.hits;
      hitsHighlighted = results.collapsedHits
        ? hitsHighlighted.map((item, index) => {
            return {
              ...item,
              collapsedHits: results.collapsedHits[index],
            };
          })
        : hitsHighlighted;

      hitsResults = hitsResults.concat(hitsHighlighted);
      explanations = explanations.concat(results.explanations);
      let numberOfResourcesToCompare = contentType ? contentRelatedpageSize : resourcesToShow;
      return {
        ...results,
        hits: hitsResults,
        explanations: explanations,
        thereIsMoreContent:
          results.hits.length === numberOfResourcesToCompare && results.totalHits > numberOfResourcesToCompare,
      };
    }
  };

  const callLatestTweets = async (props) => {
    const { filtersToApply, content, sourceProp } = props;
    const { officalName, ptId } = content;
    const valueWithin = searchPageQuery;
    const searchWithinConditional = checkSearchTitleValue(valueWithin, officalName);
    const pathname = `/api/search/query`;
    const method = 'post';
    const requestProperties = {
      type: 'tweet',
      query: '',
      pageSize: 2,
      pageNumber: 1,
      filters: [{ field: 'ptUserId', value: `${ptId}`, operator: 'STR_EQ' }],
      sort: { field: 'dateTimeTimestamp', order: 'DESC' },
      includeExplanations: false,
      searchWithinQuery: filtersToApply && !searchWithinConditional ? ChangeSearchQuery(searchPageQuery) : '',
    };
    let latestTweets = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: isAuthenticated,
      bigInt: true,
      requestSource: sourceProp ?? source,
    });
    return latestTweets;
  };

  const transformMatchingInformationCall = ({ ranking }) => {
    const {
      keywordMatchedPositions,
      keywordMatchedLordSelectCommittees,
      keywordMatchedAppgs,
      keywordMatchedSelectCommittees,
    } = ranking ?? {};
    const positions = keywordMatchedPositions ?? [];
    let appgs = keywordMatchedAppgs
      ? keywordMatchedAppgs?.map((item) => {
          return { name: item, type: 'All Party Groups' };
        })
      : [];
    let lordSelectCommittees = keywordMatchedLordSelectCommittees
      ? keywordMatchedLordSelectCommittees.map((item) => {
          return { name: item, type: 'Lords Select Committees' };
        })
      : [];
    let selectCommittees = keywordMatchedSelectCommittees
      ? keywordMatchedSelectCommittees?.map((item) => {
          return { name: item, type: 'Select Committees' };
        })
      : [];
    const groupMemberships = [...appgs, ...lordSelectCommittees, ...selectCommittees];
    return { positions, groupMemberships };
  };

  const addMetaData = (props) => {
    const { content } = props;
    const { name, descriptionMeta, categories, officalName } = content;
    let hashToShow = '';
    if (!enableStakeholders || !isAuthenticated) {
      let getViewParam = () => {
        const viewParam = getUrlParam('view');
        return viewParam && !location.search.includes('view=')
          ? `${location.search && location.search !== '' ? '&' : '?'}view=${viewParam}`
          : '';
      };
      const params = new URLSearchParams(location.search + getViewParam());
      params.delete('topic-id');
      params.delete('search');
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      );
    }
    let documentTitle = `${contentType !== undefined ? `${changeContentypeFromUrl(contentType)} - ` : ''}${officalName}${categories.includes('Special Adviser') ? ', Special Adviser' : ''} - PolicyMogul`;
    let title = documentTitle;
    let hash = hashToShow;
    let description = descriptionMeta;
    let contentUrl = `/stakeholders/${id}/${normalize(name)}`;
    if (contentType === 'parliamentary-record') {
      navigate(`${contentUrl}/spoken-parliamentary-contributions`);
    }
    addMetaTags({ title, hash, location, dispatch, contentUrl, description });
  };

  const callAgregatedSentiment = async ({ stakeholderId, keywordListId }) => {
    let pathname = `/api/stakeholder/ranking?stakeholderId=${stakeholderId}&keywordListId=${keywordListId}&country=uk`;
    let method = 'get';
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, notShowErrorMessage: true });
    return results;
  };

  const changeTopicOrQuery = async ({ topic, freeSearch }) => {
    if (topic || freeSearch) {
      setFilterSentiment(null);
      if (activityContainer.current) {
        activityContainer.current.scrollToTop();
        activityContainer.current.scrollToLeft();
      }
      setIsLoadingActivity(true);
      if (topic) {
        setLocalTopic(topic);
        setSearchPageQuery('');
      }
      if (freeSearch) {
        setSearchPageQuery(freeSearch);
      }
      const content = Stakeholders.contact;
      const contentRelated = await relatedContentCall({ content, topic, freeSearch });
      let ranking;
      let matchInformation;
      if (!!topic && topic?.id !== null) {
        ranking = await callAgregatedSentiment({ stakeholderId: content?.id, keywordListId: topic?.id });
        matchInformation = transformMatchingInformationCall({ ranking });
      }

      let resultsForState = {
        ...contentResults,
        [activeResults]: {
          Stakeholders: {
            ...Stakeholders,
            contact: { ...content, matchInformation: matchInformation ?? {}, ranking },
            contentRelated: contentRelated,
          },
        },
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
      navigate(
        `${location.pathname}?${topic ? `topic-id=${topic.id === null ? 'all' : topic.id}` : ''}${freeSearch ? `search=${encodeURIComponent(freeSearch)}` : ''}`,
        { replace: true }
      );
      setIsLoadingActivity(false);
      if (topic) {
        collectCall({ contact: Stakeholders.contact, topic });
      }
    }
  };

  const filterForSentimentFunction = async ({ sentiment }) => {
    setFilterSentiment(sentiment);
    setIsLoadingActivity(true);
    const content = Stakeholders.contact;
    const contentRelated = await relatedContentCall({ content, sentiment });
    if (contentRelated) {
      let resultsForState = {
        ...contentResults,
        [activeResults]: {
          Stakeholders: {
            ...Stakeholders,
            contentRelated,
          },
        },
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
      setIsLoadingActivity(false);
    }
  };

  const collectCall = async ({ contact, topic }) => {
    const { objectId, objectType, name } = contact;
    const contentUrl = `/stakeholders/${id}/${normalize(name)}`;
    await callCollectContent({ objectId, objectType, topic, contentUrl });
  };

  const load_more_results = async () => {
    if (contentType !== undefined) {
      let contentTypeToStore = changeContentType(contentType);
      let { thereIsMoreContent, pageNumber } = contentRelated[contentTypeToStore] ?? {};
      if (thereIsMoreContent) {
        let contentRelatedFromType = await callRelatedContent({
          type: contentTypeToStore,
          content: Stakeholders.contact,
          pageNumber: pageNumber + 1,
          searchWithinQuery: searchPageQuery,
          topic: localTopic,
        });
        let resultsForState = {
          ...contentResults,
          [activeResults]: {
            Stakeholders: {
              ...Stakeholders,
              contentRelated: { [contentTypeToStore]: contentRelatedFromType },
            },
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
      }
    }
  };

  const seeAllActivity = () => {
    //AE: It's necessary to get the mastertopic here for those cases when the user only has 1 topic.
    let masterTopic = masterTopicObject(activePlan, keywordsLists);
    changeTopicOrQuery({ topic: masterTopic });
  };

  const sendEmailToContact = async ({ e }) => {
    let requestHeaders = {
      cancelToken: source.token,
    };
    await sendEmailToContacts({
      contactsToSendIds: [Stakeholders?.contact?.contactInformation?.[0].id],
      getAccessToken,
      customApiCall,
      query: new URLSearchParams(location.search),
      navigate,
      requestHeaders,
      filters: [],
      setIsLoading: () => {},
      focusToField: true,
      listDomainsVerified: listDomainsVerified,
    });
  };

  const updateChampionStatus = ({ value }) => {
    if (Stakeholders?.contact) {
      let newContactInformation = [...Stakeholders?.contact?.contactInformation];
      newContactInformation[0] = {
        ...newContactInformation[0],
        championStatus: value,
      };
      let resultsForState = {
        ...contentResults,
        [activeResults]: {
          Stakeholders: {
            ...Stakeholders,
            contact: {
              ...Stakeholders?.contact,
              contactInformation: newContactInformation,
            },
          },
        },
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
    }
  };

  return (
    <Stakeholder
      isLoading={isLoading}
      StakeholderInformation={Stakeholders?.contact ?? {}}
      contentRelated={Stakeholders?.contentRelated ?? {}}
      activePlan={activePlan}
      enableStakeholders={enableStakeholders}
      stakeholderSearchCondition={stakeholderSearchCondition}
      activeSearch={activeSearch}
      list={list}
      contentType={contentType}
      isLoadingActivity={isLoadingActivity}
      changeTopicOrQuery={changeTopicOrQuery}
      localTopic={localTopic}
      setLocalTopic={setLocalTopic}
      searchPageQuery={searchPageQuery}
      keywordsLists={keywordsLists.filter((item) => item.id !== null)}
      load_more_results={load_more_results}
      seeAllActivity={seeAllActivity}
      activityContainer={activityContainer}
      clickOnSeeMore={clickOnSeeMore}
      setClickOnSeeMore={setClickOnSeeMore}
      sendEmailToContact={sendEmailToContact}
      filterSentiment={filterSentiment}
      filterForSentimentFunction={filterForSentimentFunction}
      updateChampionStatus={updateChampionStatus}
    />
  );
};

export default StakeholderPage;

import React from 'react';
import TextForCell from '../../tableComponents/TextForCell';
import { useRef } from 'react';
import CrmEmailDummyItem from './CrmEmailDummyItem';
import thousandCommas from '../../../helpers/thousandCommas';
import { normalizedDateUK } from '../../../utilities/normalizedDateUK';
import { useNavigate } from 'react-router-dom-v5-compat';

export const generateStateCell = (status, subNav) => {
  return (
    <span
      className={`crm-table-item-state ${
        status ? `crm-table-item-${status.toLowerCase()}` : 'crm-table-item-no-status'
      } ${subNav ? `email-state-${status.toLowerCase()}` : ''}`}
    >
      {status === 'PartiallySent' ? 'Sending' : status.split(/(?=[A-Z])/).join(' ') || ''}
    </span>
  );
};

const CrmEmailTableItem = ({ item, dummyItem, team }) => {
  const {
    title,
    status,
    senderDisplayName,
    crmEmailId,
    openedRecordCount,
    finishedSendingAt,
    lastUpdated,
    sendRecordCount,
    lastEditedByUserId,
  } = item;

  const navigate = useNavigate();
  const titleCellRef = useRef();
  const lastEditCellRef = useRef();
  const fromCellRef = useRef();
  const toListCellRef = useRef();
  const opensCellRef = useRef();
  const lastUpdatedCellRef = useRef();
  const sentCellRef = useRef();

  const renderEmptyCell = () => {
    return <div className='empty-cell' />;
  };
  if (dummyItem) {
    return <CrmEmailDummyItem dummyItem={dummyItem} />;
  }

  const getUserName = (id) => {
    const user = team.allMembers.find((member) => member.id === id);
    if (user) {
      return user.fullName;
    }
  };

  return (
    <tr className='crm-table-item crm-table-item-emails'>
      <td
        ref={titleCellRef}
        onClick={() => {
          if (status === 'Sent' || status === 'PartiallySent') {
            navigate(`/influence/email-performance/${crmEmailId}`, {
              state: { prepopulatedActiveTab: 'Email' },
            });
          } else {
            navigate(`/influence/emails/${crmEmailId}`);
          }
        }}
      >
        {title ? <TextForCell text={title.trim()} ref={titleCellRef} /> : <div>Untitled</div>}
      </td>
      <td className='text-center'>{generateStateCell(status) || renderEmptyCell()}</td>
      <td ref={lastEditCellRef}>
        <TextForCell text={getUserName(lastEditedByUserId)} ref={lastEditCellRef} />
      </td>
      <td ref={fromCellRef}>
        <TextForCell text={senderDisplayName} ref={fromCellRef} />
      </td>
      <td ref={toListCellRef}>
        {(
          <div
            style={{ pointerEvents: status === 'Sent' ? 'all' : 'none' }}
            onClick={() =>
              navigate(`/influence/email-performance/${crmEmailId}`, {
                state: { prepopulatedActiveTab: 'Recipients' },
              })
            }
            className={status === 'Sent' ? 'crm-table-item-title' : ''}
          >
            <TextForCell text={thousandCommas(sendRecordCount)} ref={toListCellRef} />
          </div>
        ) || renderEmptyCell()}
      </td>
      <td
        ref={opensCellRef}
        className={!!openedRecordCount ? 'crm-table-item-title' : ''}
        onClick={() => {
          if (!!openedRecordCount) {
            navigate(`/influence/email-performance/${crmEmailId}`, {
              state: {
                prepopulatedActiveTab: 'Recipients',
                reipientsTab: 'Opened',
              },
            });
          }
        }}
      >
        <TextForCell text={openedRecordCount} ref={opensCellRef} />
      </td>
      <td ref={lastUpdatedCellRef}>
        <TextForCell text={normalizedDateUK(lastUpdated).fromNow()} ref={lastUpdatedCellRef} />
      </td>
      <td ref={sentCellRef}>
        {item.status === 'Sent' ? normalizedDateUK(finishedSendingAt ?? lastUpdated).fromNow() : renderEmptyCell()}
      </td>
    </tr>
  );
};

export default CrmEmailTableItem;

import React, { useState, useRef } from 'react';
import useOutsideElement from '../../utilities/useOutsideElement';
import { useAuth0 } from '@auth0/auth0-react';
import NavigationSentEmails from './NavigationSentEmails';
import { useParams } from 'react-router-dom-v5-compat';

const SearchBarWithEmailPerformance = ({ isMainHome, setIsOpen, isOpen, sentEmails }) => {
  const [maxHeightContainer, setMaxHeightContainer] = useState(293);
  const params = useParams();
  const { crmEmailId: crmEmailIdStr } = params;
  const crmEmailId = parseFloat(crmEmailIdStr);
  const selectedContact = sentEmails.find((item) => item.crmEmailId === crmEmailId)?.title;
  const showOptionsList = () => {
    if (!!setIsOpen) {
      return isOpen;
    } else {
      return showDropdownContent;
    }
  };
  const [showDropdownContent, setShowDropdownContent] = useState(false);

  const searchBarElement = useRef(null);

  const { isAuthenticated } = useAuth0();

  const hideSearchBar = () => {
    if (!!setIsOpen) {
      setIsOpen(false);
    } else {
      setShowDropdownContent(false);
    }
  };
  useOutsideElement(searchBarElement, hideSearchBar);

  let cursorConditional = !showOptionsList() ? 'pointer' : 'auto';

  const calculateMaxHeightContainer = useRef();
  calculateMaxHeightContainer.current = () => {
    let elements = document.querySelectorAll('.dropdown-keywordlist');
    let newHeight = 38;
    for (let i = 0; i < elements.length; i++) {
      let el = elements[i];
      let currentHeight = newHeight + el.getBoundingClientRect().height;
      if (currentHeight > 293) {
        newHeight = currentHeight - el.getBoundingClientRect().height / 3;
        break;
      } else {
        newHeight = currentHeight;
      }
    }
    if (elements.length > 3) {
      setMaxHeightContainer(newHeight);
    }
  };

  return (
    <>
      {showOptionsList() && <div className='close-tips'></div>}
      <div
        className={`dropdown-keywords-lists search-bar-email-performance ${
          showOptionsList() ? 'dropdown-keywords-lists-open' : ''
        } ${!isAuthenticated ? 'dropdown-kewyord-lists-logged-out' : ''} ${
          !isAuthenticated && isMainHome ? 'dropdown-kewyord-lists-home' : ''
        }`}
        ref={searchBarElement}
        data-intercom-target='Topics dropdown'
        style={{
          pointerEvents: sentEmails.length > 0 ? undefined : 'none',
        }}
      >
        <div
          className='dropdown-keywords-activeitem flex-centered'
          style={{
            cursor: cursorConditional,
          }}
          onClick={() => {
            if (!!setIsOpen) {
              setIsOpen(true);
            } else {
              setShowDropdownContent(true);
            }
            setTimeout(() => {
              calculateMaxHeightContainer.current();
            }, 20);
          }}
        >
          {/* TYPE ICON */}
          {sentEmails.length > 0 && (
            <span
              className={`icon-email paragraph-p4`}
              style={{
                margin: '3px 9px 0 2px',
              }}
            />
          )}
          {/*INPUT SEARCHBAR */}
          <p
            className={`search-main-text-value`}
            style={{
              cursor: cursorConditional,
            }}
          >
            <span className={`d-block hidden-lines hidden-one-line`} style={{ paddingTop: '1px' }}>
              {selectedContact ?? ''}
            </span>
          </p>

          {/* ARROW BUTTON */}
          {isAuthenticated && (
            <span
              className={`search-dropdown ${showOptionsList() ? 'search-dropdown-open' : ''}`}
              data-tooltip-content={`${isAuthenticated && !showOptionsList() ? `Select sent email` : ''}`}
              data-tooltip-id='general-tooltip'
              data-tooltip-place='right'
              onClick={(e) => {
                if (showOptionsList()) {
                  e.stopPropagation();
                  hideSearchBar();
                }
              }}
            ></span>
          )}
        </div>
        <div className={`open-dropdown d-${showOptionsList() ? 'block' : 'none'}`}>
          <div className='open-dropdown-container'>
            {!!sentEmails.length && (
              <NavigationSentEmails
                sentEmails={sentEmails}
                maxHeightContainer={maxHeightContainer}
                selectedItem={crmEmailId}
                setIsOpen={setIsOpen}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarWithEmailPerformance;

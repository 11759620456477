import React, { useEffect } from 'react';
import { useState } from 'react';
import { store } from '../../../Store.js';
import { useContext } from 'react';
import SearchBarWithEmailPerformance from './SearchBarWithEmailPerformance.js';
import addMetaTags from '../../utilities/addMetaTags.js';
import { generateStateCell } from '../CrmEmails/CrmEmailTableItem/CrmEmailTableItem.js';
import useEmailComponentFunctions from '../CrmEmails/useEmailComponentFunctions.js';
import { useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat';
import Axios from 'axios';

const EngagementStatisticsDropdown = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { listDomainsVerified } = state;

  const [sentEmails, setSentEmails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  const { crmEmailId } = params;

  const { loadSentEmails } = useEmailComponentFunctions();

  const showSearchBarCondition =
    sentEmails.length > 0 || listDomainsVerified?.verifiedDomains?.some((domain) => domain?.sentEmailCount > 0);

  // INITIAL LOAD
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    loadSentEmails({ setSentEmails, source });
    return () => {
      source.cancel('Email performance api call canceled by the user');
    };
  }, []);

  useEffect(() => {
    return dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailPerformanceOnLoad',
      value: true,
    });
  }, []);

  useEffect(() => {
    if (!crmEmailId && !!sentEmails.length) {
      navigate(`/influence/email-performance/${sentEmails[0].crmEmailId}`);
    }
  }, [sentEmails, location.pathname]);

  useEffect(() => {
    if (crmEmailId) {
      let title = `Email performance - Influence - PolicyMogul`;
      addMetaTags({ title, hash: null, location, dispatch });
    }
  }, [crmEmailId]);

  return showSearchBarCondition ? (
    <div className='flex-centered flex-grow-1'>
      <SearchBarWithEmailPerformance sentEmails={sentEmails} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className='d-md-block d-none ml-3'>{generateStateCell('Sent', true)}</div>
    </div>
  ) : (
    <div className='flex-grow-1'></div>
  );
};

export default EngagementStatisticsDropdown;

import React, { useCallback, useRef } from 'react';
import LegislationNewContent from '../Dashboard/Legislation/LegislationNewContent';
import ParliamentaryNewContent from '../Dashboard/Parliamentary_record/ParliamentaryNewContent';
import KeyUpdateNewContent from '../Dashboard/key_updates/KeyUpdateNewContent';
import ConsultationNewContent from '../Dashboard/consultations/ConsultationNewContent';
import SkeletonKeyUpdatesContent from '../Dashboard/key_updates/SkeletonKeyUpdatesContent';
import MenuItems from './MenuItems';
import TransformFromPascalCase from '../Settings/Utilities/TransformFromPascalCase';
import normalizeString from '../Dashboard/utilities/normalizeString';
import EmbedList from './EmbedList';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import CustomScrollbar from '../Common/CustomScrollbar';
import { hex_is_light } from '../Reports/ui/ReportDesignButton';
import { TitleWidget } from '../EmbedAnalytics';

const SubtextOneResource = (finalResources) => {
  let text = TransformFromPascalCase(finalResources[0])?.toLowerCase();
  if (text) {
    switch (text) {
      case 'tweets':
        return 'Showing the latest Tweets';
      case 'events':
        return 'Showing upcoming events';
      default:
        return `Showing the latest ${text}`;
    }
  }
};

const Embed = (props) => {
  const {
    resources,
    isLoading,
    results,
    typeOfContent,
    topicName,
    content,
    setContent,
    scrollbarContentContainer,
    design,
    header,
    widgetKeywordsLists,
    topicState,
    isFromPopup,
    selectedTopics,
    userId,
  } = props;
  const { headerColour, background, includeBranding } = design;
  const { headerText, subheaderText } = header;
  const { topic, setTopic } = topicState;
  const finalResources = resources.split(',');

  const embedContent = useCallback(
    (node) => {
      if (node !== null) {
        let links = document.querySelectorAll('.embed-content-container a');
        links.forEach((item) => {
          item.href += utmLinks();
          item.setAttribute('target', '_blank');
        });
      }
    },
    [results]
  );

  const utmLinks = () => {
    if (!!results && Object.keys(results).length > 0) {
      return `?utm_source=${normalizeString(results.teamName)}-${normalizeString(topicName)}-embed-widget&utm_medium=referrer`;
    } else {
      return '';
    }
  };

  const contentContainer = useRef(null);
  const { agencyUser } = useTopicOrClientWord();
  const embedElement = useRef();
  return (
    <>
      <div className='embed-content dashboard-root-container' ref={embedElement}>
        <div style={{ background: headerColour, color: hex_is_light(headerColour) ? '#00122b' : '#fff' }}>
          <TitleWidget
            isFromPopup={isFromPopup}
            selectedTopics={selectedTopics}
            topic={topic}
            setTopic={setTopic}
            analyticsKeywordsLists={widgetKeywordsLists}
            agencyUser={agencyUser}
            header={headerText}
            subheader={subheaderText}
            userId={userId}
          />
        </div>

        {content === null && (
          <>
            {finalResources.length > 1 && <MenuItems {...props} />}
            <EmbedList background={background} topic={topic} {...props} />
          </>
        )}
        {content !== null && (
          <div className='flex-grow-1 dashboard-root-container'>
            <div className='embed-padding menu-embed-items embed-back-container mb-0'>
              <button
                className='general-button embed-button-back'
                onClick={() => {
                  setContent(null);
                }}
              >
                Back to {TransformFromPascalCase(typeOfContent)}
              </button>
            </div>
            {content.isLoading && <SkeletonKeyUpdatesContent />}
            {!content.isLoading && (
              <>
                <div className='embed-content-container flex-grow-1' ref={embedContent}>
                  <div ref={contentContainer} />
                  <CustomScrollbar
                    ref={scrollbarContentContainer}
                    className={'simple-scrollbar'} //
                    maximalThumbYSize={100}
                  >
                    <div className='px-3'>
                      {(() => {
                        const currentNew = content.currentNew;
                        const shareButtonWidget = true;
                        const contributionId = content.contributionId;
                        const handPickedNew = results.handPickedTopic;
                        const widgetContentUrl = content.currentNew.canonicalUrl;
                        const utmLink = utmLinks();
                        const props = {
                          currentNew,
                          shareButtonWidget,
                          contributionId,
                          scrollBarRef: scrollbarContentContainer,
                          widgetContentUrl,
                          utmLink,
                          handPickedNew,
                          contentContainer,
                        };

                        switch (typeOfContent) {
                          case 'Legislation':
                            return (
                              <LegislationNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />
                            );
                          case 'ParliamentaryRecord':
                            return (
                              <ParliamentaryNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />
                            );
                          case 'KeyUpdates':
                          case 'HandPickedKeyUpdates':
                            return (
                              <KeyUpdateNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />
                            );
                          case 'Consultations':
                            return <ConsultationNewContent key={`${typeOfContent}-content`} {...props} embed />;
                          default:
                            return '';
                        }
                      })()}
                    </div>
                  </CustomScrollbar>
                </div>
              </>
            )}
          </div>
        )}
        {!isLoading && (typeof includeBranding === 'string' ? includeBranding === 'true' : includeBranding) && (
          <div className='embed-padding text-right embed-link-bottom' style={{ backgroundColor: background }}>
            <a
              className='embed-link'
              href={`${process.env.REACT_APP_APPURL}${utmLinks()}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: hex_is_light(background) ? '#00122b' : '#fff' }}
            >
              Political updates powered by
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo${hex_is_light(background) ? '' : '_white'}.svg`}
                alt='PolicyMogul logo'
                width={'90px'}
                className={`d-inline-block ml-1`}
              />{' '}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export { SubtextOneResource };
export default Embed;

import axios from 'axios';
import LegislationNewContent from '../../Dashboard/Legislation/LegislationNewContent';
import ParliamentaryNewContent from '../../Dashboard/Parliamentary_record/ParliamentaryNewContent';
import RelatedContentContainer from '../../Dashboard/UserContent/RelatedContentContainer';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import ConsultationNewContent from '../../Dashboard/consultations/ConsultationNewContent';
import KeyUpdateNewContent from '../../Dashboard/key_updates/KeyUpdateNewContent';
import SkeletonKeyUpdatesContent from '../../Dashboard/key_updates/SkeletonKeyUpdatesContent';
import highlightWidget from '../../Dashboard/apiCalls/callHighlightWidget';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import ReportsInfluenceContent from './ReportsInfluenceComponent';
import { useState, useRef, useEffect } from 'react';
import ModalComponent from '../../Common/Modal';
import ChangeKeywordsCategory from '../../Dashboard/utilities/changeKeywordsCategory';
import { useContext } from 'react';
import { store } from '../../Store';
import LibraryMaterialMainContent from '../../Dashboard/LibraryMaterial/LibraryMaterialMainContent';
import useReportIconAutoselect from '../useReportIconAutoselect';
import { useParams } from 'react-router-dom-v5-compat';

const SpecificUpdateComponent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { updateToCreateReport } = state;

  const { item, modifyItemLayout, previewMode, widgetData, setWidgetData, setLayout, titleState, defaultProperty } =
    props;
  const { reportTitle, setReportTitle } = titleState ?? {};
  const { specificUpdate, topic, highlightUpdate } = item?.data?.state ?? {};
  const [isOpen, setIsOpen] = useState(!previewMode && !item?.data?.state?.closed);
  const [isLoading, setIsLoading] = useState(false);
  const { generalApiCall } = useGeneralApiCall();
  const { callSuggestionIcon } = useReportIconAutoselect();

  const params = useParams();

  const closeModal = (value) => {
    setIsLoading(false);
    setIsOpen(value);
    if (widgetData === null) {
      modifyItemLayout({ item, state: { ...item?.data?.state, closed: true } });
    }
    if (specificUpdate === null && item?.definition?.prebuild) {
      setLayout([]);
    }
  };

  const contentContainer = useRef();
  const childContainer = useRef();

  const loadUpdate = useRef();
  loadUpdate.current = async (source) => {
    try {
      if (!widgetData) {
        setIsLoading(true);
      }
      const initialItem = specificUpdate?.initialItem;
      const { contentType, id, questionHasAnswer } = initialItem;
      const type = initialItem?.objectType ?? initialItem?.type;
      const url = initialItem?.contentUrl ?? initialItem?.canonicalUrl;
      const urlType = url.split('/')?.[2];

      const TransformApi = (type) => {
        switch (type) {
          case 'Legislation':
            return `${urlType?.toLowerCase()}legislation`;
          case 'CountrySpecificParliamentaryRecord':
            switch (urlType) {
              case 'scottish-official-report':
                return 'country-specific-parliamentary-record/scotland';
              case 'welsh-official-report':
                return 'country-specific-parliamentary-record/wales';
              default:
                return urlType;
            }
          case 'ParliamentaryRecord':
            switch (urlType) {
              case 'welsh-written-q-and-a':
                return 'country-specific-question-and-answer/wales';
              case 'scottish-written-q-and-a':
                return 'country-specific-question-and-answer/scotland';
              case 'northern-irish-written-q-and-a':
                return 'country-specific-question-and-answer/northernireland';
              case 'written-q-and-a':
                return 'question-and-answer';
              case 'edm':
                return 'edm';
              default:
                return 'parliamentaryrecord';
            }
          case 'SeneddCommitteeTranscript':
            return 'senedd-committee-transcript';
          case 'UserContent':
          case 'PolicyAsk':
          case 'BriefingMaterial':
          case 'NewsRelease':
          case 'Research':
            return `user-generated-content/${TransformFromPascalCase(contentType ?? initialItem?.type)
              .toLowerCase()
              .replace(' ', '-')}`;
          case 'CommonsLibraryArticle':
            return 'commons-library-article';
          default:
            return type.toLowerCase();
        }
      };

      const FirstChildToHighlight = (type) => {
        switch (type) {
          case 'Legislation':
            return urlType === 'Primary' ? 'billTitleHighlighted' : 'titleHighlighted';
          case 'KeyUpdate':
            return 'subjectHighlighted';
          case 'ParliamentaryRecord':
            return urlType === 'QuestionAndAnswer' ? 'questionForHighlighted' : 'titleHighlighted';
          default:
            return 'titleHighlighted';
        }
      };

      const SecondChildToHighlight = (type) => {
        switch (type) {
          case 'Legislation':
            return urlType === 'Primary' ? 'summary' : 'explanatoryNote';
          case 'ParliamentaryRecord':
            return urlType === 'QuestionAndAnswer' ? 'questionText' : 'body';
          default:
            return 'body';
        }
      };

      const pathname = `/api/${TransformApi(type)}/${id}`;
      const method = 'get';
      const currentNew = await generalApiCall({ pathname, method, requestSource: source, notShowErrorMessage: true });

      const { objectId, objectType } = currentNew;
      const topicId = topic?.id;
      let newData = { ...currentNew };

      let objectTypeToApi = contentType === 'QuestionAndAnswer' ? 'WrittenQuestion' : objectType;
      if (highlightUpdate) {
        let highlightedData = await highlightWidget(objectTypeToApi, objectId, topicId);

        let highlightedAnswer;
        if (questionHasAnswer) {
          highlightedAnswer = await highlightWidget('WrittenAnswer', objectId, topicId);
        }

        if (!!highlightedData) {
          if (!!highlightedData.highlightedTitle) {
            newData[FirstChildToHighlight(type)] = highlightedData.highlightedTitle;
          }
          if (!!highlightedData.highlightedHtmlBody) {
            newData[SecondChildToHighlight(type)] = highlightedData.highlightedHtmlBody;
          }
        }

        //AE: THIS PART ONLY IS NECESSARY FOR QUESTIONS AND ANSWERS
        if (!!highlightedAnswer) {
          if (!!highlightedAnswer.highlightedHtmlBody) {
            newData['answerText'] = highlightedAnswer.highlightedHtmlBody;
          }
        }
      }
      setWidgetData({
        ...newData,
        contributionId: contentType === 'ParliamentaryContribution' ? specificUpdate.contributionId : null,
      });
      if (!widgetData) {
        modifyItemLayout({ item, state: { ...item.data?.state, widgetName: createUpdateTitle(currentNew) } });
        if (!params.id && (!!updateToCreateReport || defaultProperty === 'commentary')) {
          let title = createUpdateTitle(currentNew);
          let icon = await callSuggestionIcon({ value: title });
          setReportTitle({ ...reportTitle, title, icon });
        }
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const createUpdateTitle = (currentNew) => {
    const { billTitle, subject, title } = currentNew ?? {};
    return billTitle ?? subject ?? title;
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (specificUpdate) {
      loadUpdate.current(source);
    }
    return () => {
      source.cancel('General api call cancelled by the user');
    };
  }, [specificUpdate, highlightUpdate]);

  const hiddingParentElement = useRef();
  hiddingParentElement.current = () => {
    let parent = contentContainer.current?.parentElement;
    let secondParentElement = parent?.parentElement?.parentElement;
    if (parent) {
      parent.style.setProperty('padding-top', '0px', 'important');
    }
    if (secondParentElement && previewMode) {
      secondParentElement?.parentElement?.classList.add('reports-preview-specific-update-container');
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    hiddingParentElement.current();
    return () => {
      source.cancel('General api call cancelled by the user');
    };
  }, []);

  const calculateWidgetHeight = useRef();
  calculateWidgetHeight.current = () => {
    const contentElement = contentContainer.current;
    if (!isLoading && !!contentElement) {
      let height = contentElement?.getBoundingClientRect()?.height;
      let childContainerHeight = childContainer?.current?.getBoundingClientRect()?.height;
      let scrollHeight = contentElement.scrollHeight;
      let rows;
      if (scrollHeight > height) {
        rows = Math.round((scrollHeight + 79) / 116);
      } else if (childContainerHeight < height) {
        rows = Math.round((childContainerHeight + 79) / 116);
      }

      let newItem = { ...item };
      newItem = { ...newItem, definition: { ...newItem.definition, minRowSpan: rows }, rowSpan: rows };
      modifyItemLayout({ item, newCompleteItem: newItem });
    }
  };

  useEffect(() => {
    if (!item.saved && !previewMode) {
      setTimeout(() => {
        calculateWidgetHeight.current();
      }, 200);
    }
  }, [isLoading, item?.columnSpan]);

  useEffect(() => {
    if (item?.data?.state?.commentary) {
      if (!previewMode && contentContainer.current) {
        let commentaryHeight =
          contentContainer.current
            ?.closest("div[class*='awsui_content-wrapper']")
            ?.querySelector('.local-commentary')
            ?.getBoundingClientRect()?.height + 20 ?? 0;
        if (commentaryHeight) {
          contentContainer.current.style.height = `calc(100% - ${commentaryHeight}px`;
        }
      }
    } else {
      if (contentContainer.current) {
        contentContainer.current.style.height = previewMode
          ? {
              minWidth: `${458 + (item?.columnSpan - 2) * 240}px`,
              height: `${contentContainer.current?.parentElement?.getBoundingClientRect()?.height}px`,
            }
          : null;
      }
    }
  }, [item?.data?.state?.commentary]);

  return (
    <>
      {!specificUpdate ? (
        <>
          {item?.data?.state?.closed && (
            <div
              className='w-100 flex-centered justify-content-center'
              style={{ height: `${360 + (item?.rowSpan - 4) * 110}px` }}
            >
              <div className='text-center'>
                <p className='main-subtle-text title-h5-m'>No update selected</p>
                {!previewMode && (
                  <button
                    className='general-button action-button px-4 py-2 paragraph-p3'
                    onClick={() => setIsOpen(true)}
                  >
                    Select update
                  </button>
                )}
              </div>
            </div>
          )}
          <SelectSpecificUpdateModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeModal={closeModal}
            modifyItemLayout={modifyItemLayout}
            item={item}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <div className='reports-specific-update-skeleton'>
              <SkeletonKeyUpdatesContent />
            </div>
          ) : (
            <>
              <div
                className={`reports-specific-update-container ${previewMode ? 'reports-specific-update-container-preview' : ''}`}
                style={
                  previewMode
                    ? {
                        minWidth: `${458 + (item?.columnSpan - 2) * 240}px`,
                        height: `${contentContainer.current?.parentElement?.getBoundingClientRect()?.height}px`,
                      }
                    : null
                }
                ref={contentContainer}
              >
                <div ref={childContainer}>
                  {(() => {
                    const currentNew = widgetData;
                    const contributionId = widgetData?.contributionId;
                    const typeOfContent = widgetData?.objectType;
                    const props = {
                      currentNew,
                      contributionId,
                      contentContainer,
                      shareButtonWidget: true,
                      reportWidget: true,
                      hideSummary: true,
                    };
                    switch (typeOfContent) {
                      case 'Legislation':
                        return <LegislationNewContent {...props} key={`${typeOfContent}-content`} />;
                      case 'ParliamentaryRecord':
                      case 'HansardContent':
                      case 'CountrySpecificParliamentaryRecord':
                      case 'SeneddCommitteeTranscript':
                        return <ParliamentaryNewContent {...props} key={`${typeOfContent}-content`} />;
                      case 'CommonsLibraryArticle':
                        return <LibraryMaterialMainContent {...props} key={`${typeOfContent}-content`} />;
                      case 'KeyUpdate':
                        return <KeyUpdateNewContent {...props} key={`${typeOfContent}-content`} />;
                      case 'Consultation':
                        return <ConsultationNewContent {...props} key={`${typeOfContent}-content`} />;
                      case 'UserContent':
                        return <ReportsInfluenceContent {...props} key={`${typeOfContent}-content`} />;
                      default:
                        return '';
                    }
                  })()}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const SelectSpecificUpdateModal = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isOpen, setIsOpen, modifyItemLayout, item, closeModal } = props;
  const [associatedContent, setAssociatedContent] = useState([]);
  const { topic } = item?.data?.state ?? {};

  const insertContentLink = (update) => {
    let state = { ...item?.data?.state, specificUpdate: update };
    modifyItemLayout({ item, state });
    setIsOpen(false);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'autofocusCommentary', value: true });
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closeModal} maxWidth={790}>
      <div>
        <div className='topic-email-alerts-content px-4 px-lg-5 pt-3'>
          <h3 className='title-h5-m-bold pt-3 mb-0'>Search for an update</h3>
        </div>
        <div className='reports-add-update dashboard-root-container' style={{ height: `70vh` }}>
          <RelatedContentContainer
            associatedContent={associatedContent}
            setAssociatedContent={setAssociatedContent}
            inModal={true}
            insertContentLink={insertContentLink}
            customText={'Click on an item below to insert it as a widget'}
            initialQuery={ChangeKeywordsCategory(topic?.keywords)}
            includeAllResults={true}
            enabledSwiper={false}
            customResources={['KeyUpdate', 'Consultation', 'Legislation', 'ParliamentaryRecord', 'LobbyingMaterial']}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

const SpecificUpdateSettings = (props) => {
  const { state, setState } = props;
  const { highlightUpdate } = state ?? {};

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center pb-2  pt-4 mt-2`}>
        <p className='font-weight-bold'>Highlight relevant keywords</p>
        <button
          className={`general-button filter-toggle filter-toogle-${highlightUpdate ? 'active' : 'inactive'}`}
          onClick={() => {
            setState({ ...state, highlightUpdate: !highlightUpdate });
          }}
        >
          <span className='toggle-item' />
        </button>
      </div>
    </>
  );
};

export { SpecificUpdateSettings };
export default SpecificUpdateComponent;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { store, net_api_url } from '../../Store.js';
import { TrialHeader } from '../../Login/EndOfTrial';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import Loading from '../../Login/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import getActiveSubscriptions from '../Billing/getActiveSubscriptions';
import getActivePlan from '../Utilities/getActivePlan';
import getCurrentTeam from '../Team/getCurrentTeam';
import { useNavigate, useLocation, Link } from 'react-router-dom-v5-compat';
import axios from 'axios';
import makePlanCompliant from './makePlanCompliant';
import { loadKeyWords } from '../../Dashboard/utilities/loadKeyWords.js';
import createNotification from '../Utilities/CreateNotification.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';
const CheckPlan = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const location = useLocation();
  const mainSource = useRef();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    mainSource.current = source;
    loadInitialInformation(source);
    return () => {
      source.cancel('Keywords canceled by the user.');
    };
  }, []);

  const [heightContainer, containerRef] = useHeightContainer();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [activeSubscriptions, setActiveSubscriptions] = useState({});
  const [showLists, setShowLists] = useState(false);
  const [currentTeam, setCurrentTeam] = useState({});
  const [selectedKeywordLists, setSelectedKeywordLists] = useState([]);
  const [keywordsLists, setKeywordLists] = useState({});
  const mainPlanCompliant = useRef();

  const { maxListsPerTeam } = activeSubscriptions;
  const { myself } = currentTeam;
  const listElement = useRef(null);
  const hideLists = () => {
    setShowLists(false);
  };
  useOutsideElement(listElement, hideLists);
  const loadInitialInformation = async (source) => {
    try {
      let activeSubscriptions = await getActiveSubscriptions(getAccessToken, source);
      if (!!activeSubscriptions) {
        setActiveSubscriptions(activeSubscriptions);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
      }
      let activePlan = await getActivePlan(getAccessToken, source);
      if (!!activePlan) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'trialBannerHeight', value: 0 });
      }
      let keywordsLists = await loadKeyWords(getAccessToken, source, true);
      if (!!keywordsLists) {
        setKeywordLists(keywordsLists);
      }
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (!!currentTeam) {
        setCurrentTeam(currentTeam);

        if (currentTeam.myself.role === 'Owner') {
          let planCompliant = await makePlanCompliant(getAccessToken, user, source);

          activeSubscriptions = await getActiveSubscriptions(getAccessToken, source);
          setActiveSubscriptions(activeSubscriptions);

          activePlan = await getActivePlan(getAccessToken, source);
          dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });

          const { isOverMaxKeywordListLimit, isOverMaxKeywordLimit } = planCompliant;
          if (isOverMaxKeywordListLimit) {
            mainPlanCompliant.current = planCompliant;
          } else if (!isOverMaxKeywordListLimit && isOverMaxKeywordLimit) {
            let id = keywordsLists.length === 1 ? `/${keywordsLists[0].id}` : '';
            return navigate(`/settings/topics${id}`);
          } else {
            return navigate('/');
          }
        }
        let title = `Check plan - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const createKeywordList = () => {
    const checkItem = (item) => {
      let newSelectedKeywordLists = selectedKeywordLists.slice();
      if (selectedKeywordLists.includes(item.id)) {
        let position = newSelectedKeywordLists.indexOf(item.id);
        newSelectedKeywordLists.splice(position, 1);
      } else {
        if (selectedKeywordLists.length < maxListsPerTeam) {
          newSelectedKeywordLists.push(item.id);
          if (newSelectedKeywordLists.length === maxListsPerTeam) {
            setShowLists(false);
          }
        }
      }
      setSelectedKeywordLists(newSelectedKeywordLists);
    };
    return (
      <>
        {keywordsLists.map((item) => {
          return (
            <div className='checkbox-item'>
              <input
                id={`item-${item.id}`}
                onChange={() => {
                  checkItem(item);
                }}
                type={`checkbox`}
                checked={selectedKeywordLists.includes(item.id)}
              />
              <label htmlFor={`item-${item.id}`}>{item.name}</label>
            </div>
          );
        })}
      </>
    );
  };

  const DeleteList = async (id) => {
    try {
      let token = await getAccessToken();
      let response = await axios.delete(`${net_api_url}/api/keyword-list/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: mainSource.current?.token,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  return (
    <div className='check-plan-container'>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <TrialHeader />
          <div
            className='p-lg-5 p-3 end-of-trial-container d-flex flex-column justify-content-center'
            style={{ height: `${heightContainer}px` }}
            ref={containerRef}
          >
            <div className='container'>
              <div className='row mx-0 align-items-center main-content-wrapper'>
                <div className='col-lg-10 px-0 trial-end-text pt-lg-3'>
                  {myself.role === 'Owner' && (
                    <>
                      <p className='owner-trial-text'>Please choose the topics you’d like to keep</p>
                      {maxListsPerTeam === 1 && (
                        <>
                          <p className='font-weight-light check-plan-text'>
                            Your current plan allows only for 1 topic. You can edit your keywords on the next page. For
                            more topics,{' '}
                            <Link className='trial-link' to='/pricing'>
                              upgrade to Mogul.
                            </Link>
                          </p>
                        </>
                      )}
                      {maxListsPerTeam > 1 && (
                        <>
                          <p className='font-weight-light check-plan-text'>
                            Your current plan allows only for {maxListsPerTeam} topics. You can{' '}
                            <Link className='trial-link' to='/settings/billing'>
                              purchase more topics here.
                            </Link>
                          </p>
                        </>
                      )}

                      <p className='mb-3'>
                        <strong>Select {maxListsPerTeam === 1 ? 'topic' : 'topics'} to keep</strong>
                      </p>
                      <div className='position-relative' ref={listElement} style={{ maxWidth: '350px' }}>
                        <button
                          className='general-button button-select-categories px-3 py-1'
                          onClick={() => {
                            setShowLists(!showLists);
                          }}
                        >
                          {selectedKeywordLists.length < maxListsPerTeam
                            ? `Please select ${maxListsPerTeam} topic${maxListsPerTeam > 1 ? 's' : ''}`
                            : `${selectedKeywordLists.length} ${selectedKeywordLists.length === 1 ? 'topic' : 'topics'} selected`}
                        </button>
                        {showLists && (
                          <div className='hour-drowpdown px-3 py-2 resources-dropdown check-plan-lists'>
                            <button
                              className='general-button close-check-plan-lists'
                              onClick={() => {
                                setShowLists(false);
                              }}
                            ></button>
                            {createKeywordList()}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {myself.role !== 'Owner' && (
                    <>
                      <p>Your PolicyMogul account is over your plan's limits</p>
                      <p className='team-trial-text'>
                        Please contact your <strong>account Owner</strong> to continue using PolicyMogul.
                      </p>
                    </>
                  )}
                </div>
                <div className='col-lg-6 show-lg'>
                  <div className='trial-image-container ml-auto mr-0'>
                    <img
                      className='d-block mx-auto'
                      src={`${process.env.REACT_APP_CDNURL}/images/parliamentarian.gif`}
                      alt='calendar gif'
                    />
                  </div>
                </div>
              </div>

              <button
                className='general-button px-5 py-2 mt-2 choose-plan-button'
                style={{
                  visibility:
                    selectedKeywordLists.length > 0 && selectedKeywordLists.length <= maxListsPerTeam
                      ? 'visible'
                      : 'hidden',
                }}
                onClick={async () => {
                  setButtonLoading(true);
                  let listsToDelete = [];
                  keywordsLists.forEach((item) => {
                    if (!selectedKeywordLists.includes(item.id)) {
                      listsToDelete.push(item.id);
                    }
                  });
                  let listToCompare = listsToDelete.slice();
                  const newKeywordLists = keywordsLists.slice();
                  listsToDelete.forEach(async (id) => {
                    let deleteList = await DeleteList(id);
                    if (deleteList.status === 200) {
                      let position = listToCompare.indexOf(id);
                      listToCompare.splice(position, 1);
                      newKeywordLists.forEach((item, index) => {
                        if (item.id === id) {
                          newKeywordLists.splice(index, 1);
                        }
                      });
                      if (listToCompare.length === 0) {
                        const { maxNumKeywordsPerTeam } = activeSubscriptions;
                        if (
                          newKeywordLists.length === 1 &&
                          maxNumKeywordsPerTeam !== null &&
                          newKeywordLists[0].keywords.length > maxNumKeywordsPerTeam
                        ) {
                          navigate(`/settings/topics/${newKeywordLists[0].id}`);
                        } else {
                          navigate('/settings/topics');
                        }
                      }
                    } else {
                      setButtonLoading(false);
                      return createNotification('danger', 'An error occurred.  Please reload the page and try again');
                    }
                  });
                }}
              >
                {buttonLoading ? <i className='fas fa-spinner fa-spin'></i> : `Continue to Settings`}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckPlan;

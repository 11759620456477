import React, { useEffect, useState, useContext } from 'react';
import { store } from '../../Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import getCurrentTeam from '../../Settings/Team/getCurrentTeam.js';
import SkeletonInfluenceContent from '../Influence/skeletons/SkeletonInfluenceContent.js';
import createNotification from '../../Settings/Utilities/CreateNotification.js';
import addMetaTags from '../utilities/addMetaTags.js';
import normalizeString from '../utilities/normalizeString.js';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import transformToPascalCase from '../../Settings/Utilities/TransformToPascalCase.js';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords.js';
import uppercaseFirstLetter, { lowerCaseFirstLetter } from '../utilities/uppercaseFirstLetter.js';
import PublishedContentPage from './ui/PublishedContentPage.js';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall.js';
import useCallCollectContent from '../hooks/useCallCollectContent.js';
import useGetTweetsInfo from './useGetTweetsInfo.js';
import useGetMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent.js';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const InfluenceContentContainer = (props) => {
  const [itemInformation, setItemInformation] = useState({});
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const { team, search, referenceState, contentResults, activeResults } = state;
  const { activeReference } = referenceState;
  const { activeSearch } = search;
  const { Influence } = contentResults[activeResults];
  const index = Influence ? Influence?.hits?.findIndex((item) => item.id === itemInformation?.id) : null;

  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const location = useLocation();

  const { page } = props;
  const id = !!params.id ? parseInt(params.id) : 0;
  const { queryHighlight, andKeywords } = useGetHighlightingKeywords();
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const { callCollectContent } = useCallCollectContent();
  const { generalApiCall } = useGeneralApiCall();
  const { getTweetsInfo } = useGetTweetsInfo();
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();
  const { newAgencyPlan } = useTopicOrClientWord();

  const alreadyMarkedFeedback = !!itemInformation?.feedback;

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getResults(source);
    return () => {
      source.cancel('Influence item canceled by the user');
    };
  }, [id]);

  const getResults = async (source) => {
    setIsLoading(true);
    let method = 'get';
    let pathname = `/api/user-generated-content/${page === 'policy-asks' ? 'policy-ask' : page}/${id}`;
    let token = isAuthenticated ? await getAccessToken() : '';
    let requestHeaders = {
      cancelToken: source.token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (Object.keys(team).length === 0 && isAuthenticated) {
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
    }

    let results = await generalApiCall({ method, pathname, requestHeaders, notShowErrorMessage: true });
    if (!!results) {
      const { objectId, objectType, title, canonicalUrl } = results;
      results.rawTitle = title;

      if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
        results.relatedContent = await getTweetsInfo({
          relatedContent: results.relatedContent,
        });
      } else {
        results.relatedContent = results.relatedContent.filter((item) => item.contentType !== 'Tweet');
      }

      let propertiesHighlight = [
        objectId,
        objectType,
        queryHighlight,
        ChangeKeywordsExclusions(andKeywords ?? []),
        source,
      ];
      let contentHightlighted;
      let highlightCondition = activeSearch || activeReference;
      let notSetInnerHtml = true;
      if (highlightCondition) {
        notSetInnerHtml = false;
        contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
      }
      if (contentHightlighted?.highlightedTitle) {
        if (results.title === contentHightlighted?.highlightedTitle) {
          notSetInnerHtml = true;
        }
        results.title = contentHightlighted.highlightedTitle;
      }
      if (contentHightlighted?.highlightedHtmlBody) {
        results = highlightContent({ results, highlightedHtmlBody: contentHightlighted.highlightedHtmlBody });
      }

      let { feedback, keywordsFromLists } = await getMatchingKeywordsListsAndFeedback({ objectId });
      results = { ...results, feedback, keywordsFromLists, notSetInnerHtml };
      setItemInformation(results);

      let titleUrl = `${title} - ${page === 'policy-asks' ? 'Policy asks' : `${uppercaseFirstLetter(page?.replace('-', ' '))}`} - PolicyMogul`;
      let contentUrl = canonicalUrl;
      addMetaTags({ title: titleUrl, location, dispatch, contentUrl });
      setIsLoading(false);
      await callCollectContent({ objectId, objectType, contentUrl });
    } else {
    }
  };

  const highlightContent = (props) => {
    const { results, highlightedHtmlBody } = props;
    const finalResults = { ...results };
    const { type } = finalResults;
    const sections = [
      { name: 'aims' },
      { name: 'who will be impacted' },
      { name: 'political support', field: 'whatKindOfPoliticalSupport' },
      { name: 'summary' },
      { name: 'key information' },
    ];
    const parser = new DOMParser();
    const document = parser.parseFromString(highlightedHtmlBody, 'text/html');
    for (let i = 0; i < sections.length; i++) {
      let section = sections[i];
      let sectionId = normalizeString(section.name);
      let sectionField = section.field ?? transformToPascalCase(section.name);
      let sectionElement = document.getElementById(sectionId);
      if (sectionElement && sectionElement.innerText !== '') {
        finalResults[`${lowerCaseFirstLetter(type)}Content`][lowerCaseFirstLetter(sectionField)] =
          document.getElementById(sectionId).outerHTML;
      }
    }
    return finalResults;
  };

  const isSupporter = itemInformation?.supporters?.findIndex((item) => item.email === team?.myself?.emailAddress) > -1;
  const typeOfUser = () => {
    let typeOfUser = null;
    if (isAuthenticated) {
      const { askerUserId, ownerUserId, teamId } = itemInformation;
      const { myself, allMembers } = team;
      let paramId = askerUserId ?? ownerUserId;
      let position = allMembers.findIndex((member) => member.id === parseInt(paramId));
      if (position >= 0) {
        if (newAgencyPlan && teamId !== team?.teamId) {
          typeOfUser = 'Client';
        } else if (myself.id === parseInt(paramId)) {
          typeOfUser = 'Creator';
        } else if (myself.role === 'Admin') {
          typeOfUser = 'Admin';
        } else if (myself.role === 'Owner') {
          typeOfUser = 'TeamOwner';
        } else {
          typeOfUser = 'TeamMember';
        }
      } else {
        typeOfUser = 'Suporter';
      }
    }
    return typeOfUser;
  };

  const createButton = () => {
    let button = null;
    if (!!typeOfUser()) {
      switch (typeOfUser()) {
        case 'TeamOwner':
        case 'Creator':
        case 'Admin':
          button = 'Edit';
          break;
        case 'TeamMember':
        case 'Client':
          button = null;
          break;
        default:
          button = isSupporter ? 'Supported' : 'Support this';
      }
    } else {
      button = 'Support this';
    }
    return button;
  };

  const supportItem = async (e) => {
    let el = e.target;
    el.disabled = true;
    let method = 'post';
    let pathname = `/api/user-generated-content/${isSupporter ? 'remove' : 'add'}-support${isSupporter ? '' : '?website=policymogul'}`;
    let requestProperties = {
      id: id,
      type: page === 'policy-asks' ? 'PolicyAsk' : transformToPascalCase(page?.replace('-', ' ')),
    };
    let token = await getAccessToken();
    let requestHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let newResults = await generalApiCall({ method, pathname, requestProperties, requestHeaders });
    if (!!newResults) {
      el.disabled = false;
      setItemInformation({ ...itemInformation, supporters: newResults?.supporters });
      if (isSupporter) {
        createNotification(
          `success`,
          `Your support has been removed from this ${page === 'policy-asks' ? 'policy ask' : page?.replace('-', ' ')}`
        );
      } else {
        return newResults;
      }
    }
  };

  const updateSupport = async ({ e, properties }) => {
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/user-generated-content/update-support`;
    let method = 'post';
    let requestProperties = properties;
    let results = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    e.disabled = false;
    if (!!results) {
      setItemInformation({ ...itemInformation, supporters: results?.supporters });
      return results;
    }
  };

  const updateFeedback = ({ feedback }) => {
    setItemInformation({ ...itemInformation, feedback });
  };

  return (
    <>
      {isLoading && <SkeletonInfluenceContent />}
      {!isLoading && (
        <>
          <PublishedContentPage
            data={itemInformation}
            button={createButton()}
            supportItem={supportItem}
            updateSupport={updateSupport}
            team={team}
            alreadyMarkedFeedback={alreadyMarkedFeedback}
            item={{ objectID: itemInformation?.objectId }}
            index={index}
            updateFeedback={updateFeedback}
            page={page}
          />
        </>
      )}
    </>
  );
};

export default InfluenceContentContainer;

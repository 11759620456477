import { useContext, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useCompileFiltersFunction from './useCompileFilters';
import useCompileInitialFilters from './useCompileInitialFilters';

const useGetSectionFilters = (section) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentFilters, search, referenceState } = state;
  const { list } = search;

  const { compileInitialFilters } = useCompileInitialFilters();

  const { compileFiltersFunction, activeResultsConditional } = useCompileFiltersFunction(section);
  const { mainSection } = useParams();
  const mountedItem = useRef(false);
  const location = useLocation();

  const { inboxSection, referenceSection, searchResultsSection } = useMainSectionParameter();

  //AE: THIS CONDITION IS NECESSARY TO AVOID THAT IF YOU'RE IN A DIFFERENT SECTION FROM KEYUPDATES AND GO HERE, THE LIST GETS THE VALUE OF THE PREVIOUS SECTION AND CREATES A DUPLICATED CALL.
  const createCallConditional = () => {
    let callConditional;
    if (inboxSection) {
      callConditional = list !== null;
    } else if (referenceSection || searchResultsSection) {
      callConditional = list === null;
    } else if (
      location.pathname.includes('/stakeholder-mapping') ||
      location.pathname.includes('/policymakers') ||
      location.pathname.includes('/campaigns') ||
      location.pathname.includes('/general-election-2024/candidates') ||
      location.pathname.includes('/general-election-2024/key-updates')
    ) {
      callConditional = true;
    } else {
      callConditional = false;
    }
    return callConditional;
  };

  useEffect(() => {
    mountedItem.current = true;
  }, [referenceState]);

  const initialFilters = useRef();
  const createSectionStored = () => {
    if (mainSection) {
      return mainSection;
    } else if (location.pathname.includes('/stakeholder-mapping')) {
      return 'stakeholder-mapping';
    } else if (location.pathname.includes('/have-your-voice-heard')) {
      return 'have-your-voice-heard';
    } else if (location.pathname.includes('/influence/contacts')) {
      return 'influence-contacts';
    } else if (location.pathname.includes('/general-election-2024/candidates')) {
      return 'election-candidates';
    }
  };
  const sectionStored = createSectionStored();

  const getSectionFilters = () => {
    //AE: Small adjustment for the stakeholder-mapping page, probably related to the parameters that is not clear why sometimes is ok and sometimes not.
    initialFilters.current =
      Object.keys(contentFilters).length > 0 && contentFilters[sectionStored]
        ? contentFilters[sectionStored]
        : compileInitialFilters(sectionStored);

    let sectionFilters = initialFilters.current.filter(
      (filter) => filter.pages.includes(section) && activeResultsConditional(filter, initialFilters.current)
    );
    let filters = compileFiltersFunction(sectionFilters);
    return filters;
  };

  return {
    getSectionFilters,
    callConditional: createCallConditional(),
    sectionStored,
  };
};

export default useGetSectionFilters;

import React from 'react';
import AddKeywordsComponent from './AddKeywordsComponent';
const AdminTopicKeywords = (props) => {
  return (
    <>
      <div className='topic-items-container padding-topic-component pb-5'>
        <div className='mb-3'>
          <h3 className='topic-title mb-0'>Keywords and phrases</h3>
          <p className='mb-0 main-light-text'>Enter the keywords and phrases related to this topic</p>
        </div>

        <div className='topics-search-items'>
          <div className='px-3 pb-4 pt-3'>
            <p className='keywords-rule-title mb-2 pl-2'>
              <span className='text-uppercase'>
                <strong>Any of</strong> these keywords
              </span>
            </p>
            <AddKeywordsComponent {...props} parameter={'keywords'} />
          </div>
          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>+ And also</strong> any of these keywords
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent {...props} parameter={'andKeywords'} />
          </div>

          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>+ But not</strong> any of these keywords
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent {...props} parameter={'keywordExclusions'} />
          </div>

          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>Parliament Today hand-picked keywords</strong>
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent {...props} parameter={'ptHandPickedContentKeywords'} excludeDuplicatedWordsRule />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTopicKeywords;

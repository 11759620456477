import React, { useEffect, useContext, useState, useRef } from 'react';
import { store } from '../../Store.js';
import axios from 'axios';
import EmptyMessage from '../components/EmptyMessage';
import StakeholderItem from './StakeholderItem';
import SkeletonHolderListSkeleton, { StakeholderSkeletonItem } from './StakeHolderListSkeleton';
import { useAuth0 } from '@auth0/auth0-react';
import addMetaTags from '../utilities/addMetaTags';
import WaitingAnimationScreen from './WaitingAnimationScreen';
import callCollectList from '../utilities/callCollectList';
import useNumberOfUnseenItems from '../hooks/useNumberOfUnseenItems';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import useGetSectionFilters from '../Filters/useGetSectionFilters';
import ResearchPortalButton from '../components/ResearchPortalButton';
import parliamentarianUsers from '../sidebar/parliamentarianUsers';
import Filters from '../Filters';
import useIsMobile from '../utilities/useIsMobile';
import RemoveContactsModal from '../Influence/CrmContacts/RemoveContactsModal/RemoveContactsModal';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import idsWithoutImages from '../key_updates/idsWithoutImages';
import ScrollbarList from '../../Common/ScrollbarList';
import getUrlParam from '../utilities/getUrlParam.js';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom-v5-compat';
import { createSectionName } from '../helpers/controlActiveFilters.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const useCallContactIdsResults = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contactIdsCheck } = state;
  const { generalApiCall } = useGeneralApiCall();

  const setContactIdsCheck = (results) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contactIdsCheck',
      value: results,
    });
  };

  const callContactsIdsResults = async ({ contactIdsResult, source }) => {
    try {
      const pathname = `/api/crm-contact/have-stakeholders-been-added-as-contacts`;
      const method = 'post';
      const requestProperties = {
        contactIds: contactIdsResult,
      };
      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      if (results) {
        setContactIdsCheck(results);
      }
    } catch (error) {}
  };

  return { callContactsIdsResults, setContactIdsCheck, contactIdsCheck };
};

const StakeHolderList = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    contentResults,
    activeResults,
    search,
    contentFilters,
    referenceState,
    activePlan,
    contactIdsCheck,
    showRemoveContactsModal,
    keywordsLists,
  } = state;
  const { setShowStakeholdersCrmPopup } = props;

  const { enableReferenceSection, code } = activePlan;
  const { activeReference } = referenceState;
  const { query, activeSearch } = search;
  const [isLoading, setIsLoading] = useState(true);
  const [contactIdsResult, setContactIdsResult] = useState([]);
  const [currentlyProcessing, setCurrentlyProcessing] = useState({
    attempt: 0,
    state: false,
  });
  const [stakeholdersHash, setStakeholdersHash] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { generalApiCall } = useGeneralApiCall();
  const noUnmountedComponent = useRef(true);

  const topicIdQuery = getUrlParam('topic-id');
  const searchQuery = getUrlParam('search') || getUrlParam('or');
  const list = keywordsLists.find((item) => item.id === (topicIdQuery === 'all' ? null : parseInt(topicIdQuery)));
  const sectionName = createSectionName();
  const pageSize = parseInt(process.env.REACT_APP_STAKEHOLDER_PAGE_SIZE);
  const { searchResultsSection, mainSectionTitle } = useMainSectionParameter(params);
  const scrollCondition = contentResults[activeResults]?.[sectionName]?.scrollPosition;
  let masterTopic = !!list && list?.id === null;
  let listConditional = !masterTopic && !activeReference && search?.list !== 'noListsCreated';
  const isMobile = useIsMobile(1280);

  const { getSectionFilters, callConditional } = useGetSectionFilters(sectionName);
  const { searchWithin, sort, filters, hash, sortMode, nationsFilter } = getSectionFilters();
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const { callContactsIdsResults, setContactIdsCheck } = useCallContactIdsResults();

  const initialCall = useRef();
  initialCall.current = (source) => {
    setCurrentlyProcessing({ attempt: 0, state: false });
    const loadResults = async () => {
      try {
        await createResults(null, null, null, source);
      } catch (error) {}
    };
    if (callConditional) {
      if (!scrollCondition) {
        loadResults();
      } else {
        let title = `${mainSectionTitle}${
          mainSectionTitle !== '' || searchResultsSection ? sectionName : 'Stakeholder mapping'
        } - PolicyMogul`;
        if (location?.pathname === '/general-election-2024/candidates') {
          title = `Candidates - Election hub - PolicyMogul`;
        }
        addMetaTags({ title, hash: window.location.hash, location, dispatch });
        setIsLoading(false);
        //AE: Necessary to have a setTimeOut to update the scrollPosition and allow continue using the list correctly. (Without this if you clear the search or change the topic it will not work.)
        setTimeout(() => {
          let StakeholdersWithoutScroll = {
            ...contentResults[activeResults]?.[sectionName],
            scrollPosition: null,
          };
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'contentResults',
            value: {
              ...contentResults,
              [activeResults]: {
                [sectionName]: StakeholdersWithoutScroll,
              },
            },
          });
        }, 300);
      }
    }
  };

  useEffect(() => {
    return () => {
      noUnmountedComponent.current = false;
    };
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Stakeholders canceled by the user.');
    };
  }, [search, topicIdQuery, searchQuery, activeResults, referenceState, contentFilters]);

  useEffect(() => {
    if (!isLoading && scrollCondition) {
      scrollBarRef.current.scrollTop(contentResults[activeResults]?.[sectionName].scrollPosition);
    }
  }, [isLoading, contentResults[activeResults]?.[sectionName]]);

  useEffect(() => {
    //UPDATE SELECTED STAKEHOLDERS STATE WHEN FILTERS ARE CHANGED
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedStakeholders',
      value: {
        selectedAll: false,
        selectedAllVisible: [],
        selectedIds: [],
      },
    });
  }, [topicIdQuery, stakeholdersHash]);

  useEffect(() => {
    //REMEMBER HASH FOR STAKEHOLDERS MAPPING
    if (location.hash !== stakeholdersHash) {
      setStakeholdersHash(location.hash);
    }
  }, [location.hash]);

  const checkReadyState = useRef();
  checkReadyState.current = (source) => {
    const callAgainResults = () => {
      setTimeout(async () => {
        try {
          await createResults(false, true, false, source);
        } catch (error) {
          console.error(error);
        }
      }, 2500);
    };
    if (currentlyProcessing.state) {
      setIsLoading(false);
      callAgainResults();
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    checkReadyState.current(source);
    return () => {
      if (currentlyProcessing.state) {
        source.cancel('Stakeholders canceled by the user.');
      }
    };
  }, [currentlyProcessing]);

  const { numberOfUnseenItems } = useNumberOfUnseenItems();

  async function createResults(pageNumber, processing, getAll, sourceProp) {
    try {
      if (!pageNumber && !processing) {
        setIsLoading(true);
      }

      let token = isAuthenticated ? await getAccessToken() : '';
      let pathname = `/api/${listConditional && !!list ? 'stakeholder/query-ranked' : 'search/query'}`;
      let requestProperties = {
        type: 'stakeholder',
        pageSize: getAll ? 10000 : searchResultsSection && !enableReferenceSection ? 3 : pageSize,
        filters: filters,
        pageNumber: pageNumber ? pageNumber : 1,
      };

      if (sort) {
        requestProperties = { ...requestProperties, sort: sort };
      }

      if (listConditional && !!list) {
        requestProperties = {
          ...requestProperties,
          keywordListId: list?.id,
          includeExplanations: false,
        };

        if (nationsFilter) {
          let selectedOption = nationsFilter.options.find((item) => nationsFilter.optionSelected === item.name);
          requestProperties = {
            ...requestProperties,
            country: selectedOption.filterValue,
          };
        }
      } else {
        if (activeReference) {
          const { keywords, andKeywords, keywordExclusions } = referenceState;
          requestProperties = {
            ...requestProperties,
            query: ChangeKeywordsCategory(keywords),
            andQueries: ChangeKeywordsExclusions(andKeywords),
            mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
            smartMatch: false,
          };
        } else {
          requestProperties = {
            ...requestProperties,
            query: masterTopic || list === 'noListsCreated' ? '' : query,
            smartMatch: false,
          };
        }
        requestProperties = {
          ...requestProperties,
          includeHighlights: true,
        };
      }

      if (sectionName === 'Candidates') {
        if (searchQuery) {
          requestProperties.sort = null;
        }
      } else if (!(listConditional && !!list) || (searchWithin && list !== null)) {
        requestProperties.sort = null;
      }

      if (listConditional) {
        requestProperties = {
          ...requestProperties,
          sortMode: sortMode ?? 'relevance',
        };
      }

      if (searchWithin && list !== null) {
        if (list.id !== null) {
          requestProperties = { ...requestProperties, ...searchWithin };
        } else {
          requestProperties = {
            ...requestProperties,
            query: searchWithin.searchWithinQuery,
          };
        }
      }

      if (activeSearch && list === null) {
        //AE: TODO - check a way to update the filters object without need to clear the search and move this to the filters data object
        requestProperties = {
          ...requestProperties,
          sort: { field: 'positionCategoryRank', order: 'DESC' },
        };
      }

      //AE: EXCEPTION THAT ONLY APPLIES FOR STAKEHOLDERS FOR NOW
      if (masterTopic || list === 'noListsCreated') {
        requestProperties = {
          ...requestProperties,
          sort: { field: 'lastName', order: 'ASC' },
        };
      }

      let results = await generalApiCall({
        pathname,
        method: 'post',
        requestProperties,
        needsAuthentication: isAuthenticated,
        requestSource: sourceProp,
        notShowErrorMessage: true,
      });

      if (!!results) {
        if (list !== 'noListsCreated' && searchResultsSection) {
          await numberOfUnseenItems();
        }
        if (getAll) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'allStakeholders',
            value: results?.hits,
          });
        } else {
          let contactsIds = pageNumber ? contentResults[activeResults]?.[sectionName]?.contactsIds : [];
          let hitsResults = pageNumber ? contentResults[activeResults]?.[sectionName]?.hits : [];
          let highlightResults = pageNumber ? contentResults[activeResults]?.[sectionName]?.highlights : [];

          if (results?.hits !== null) {
            contactsIds = contactsIds?.concat(results.contactsIds);
            hitsResults = hitsResults?.concat(results.hits);
            highlightResults = highlightResults?.concat(results.highlights);
          }
          let currentId = results.hits !== null && results.hits.length > 0 ? results.hits[0].id : 0;
          let ResultsToState = {
            ...results,
            currentId: currentId,
            contactsIds: contactsIds,
            currentPositionId: contactsIds.indexOf(currentId),
            hits: hitsResults,
            highlights: highlightResults,
            thereIsMoreContent: results.hits !== null ? results.hits.length === pageSize : false,
          };

          let StakeHoldersResults = {
            ...contentResults,
            [activeResults]: {
              [sectionName]: ResultsToState,
            },
          };
          if (!!list && list.id !== null) {
            setCurrentlyProcessing({
              attempt: currentlyProcessing.attempt + 1,
              state: results.currentlyProcessing,
            });
          }
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'contentResults',
            value: StakeHoldersResults,
          });

          if (!pageNumber) {
            await loadImages({ items: [...results.hits].splice(0, 4) });
          }

          let urlToHistory = `${location.pathname}${location.search}${hash !== '' ? `#${hash}` : ''}`;
          navigate(
            {
              search: window.location.search,
              hash: hash !== '' ? `#${hash}` : '',
            },
            { replace: true }
          );

          let newContactsIds = [
            ...contactIdsResult,
            ...results.hits.map((stakeholder) => stakeholder.id).filter((id) => !contactIdsResult.includes(id)),
          ];
          setContactIdsResult(newContactsIds);
          if (newContactsIds.length && isAuthenticated) {
            await callContactsIdsResults({ contactIdsResult: newContactsIds, source: sourceProp });
          }

          let title = `${mainSectionTitle}${
            mainSectionTitle !== '' || searchResultsSection
              ? sectionName
              : parliamentarianUsers(code)
                ? 'Policymakers'
                : 'Stakeholder mapping'
          } - PolicyMogul`;

          if (location?.pathname === '/general-election-2024/candidates') {
            title = `Candidates - Election hub - PolicyMogul`;
          }
          addMetaTags({ title, hash, location, dispatch });
          setIsLoading(false);

          if (!pageNumber) {
            let contentType = 'Stakeholder';
            if (list !== null && list !== 'noListsCreated' && isAuthenticated && !processing) {
              await callCollectList(contentType, token, list.id, urlToHistory);
            }
            if (list !== 'noListsCreated') {
              await numberOfUnseenItems();
            }
          }
        }
      }
    } catch (error) {}
  }

  const scrollBarRef = useRef(null);

  const BlueBannerText = () => {
    return (
      <>
        {((searchResultsSection && referenceState.keywords.length > 0) || (activeSearch && list === null)) && (
          <p className='stakeholders-search-banner'>
            These results are based on names, positions and biographies only. To see all stakeholders interested in a
            topic, go to{' '}
            <Link
              to={`/stakeholder-mapping?topic-id=all`}
              onClick={() => {
                let stateForResults = {
                  allResults: {},
                  searchResults: {},
                };
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'search',
                  value: {
                    ...search,
                    query: '',
                    activeSearch: false,
                    list: null,
                  },
                });
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'contentResults',
                  value: stateForResults,
                });
              }}
            >
              {parliamentarianUsers(code) ? 'policymakers' : 'Stakeholder mapping'}
            </Link>
          </p>
        )}
      </>
    );
  };
  const hitsToRender = contentResults[activeResults]?.[sectionName]?.hits ?? [];

  const stakeholderMapping =
    location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers');
  const policymakerSection = location.pathname.includes('/policymakers');
  const stakeholderIds = contentResults[activeResults]?.[sectionName]?.hits?.map((item) => item.id);

  const loadImageUrl = (imageId) => {
    return new Promise((resolve, reject) => {
      const urlImage =
        imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
          ? `https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1`
          : `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`;
      let base_image = new Image();
      base_image.src = urlImage;
      base_image.onload = () => {
        if (noUnmountedComponent.current) {
          resolve('success');
        }
      };
      base_image.onerror = reject;
    });
  };

  const loadImages = async ({ items }) => {
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (noUnmountedComponent.current) {
        await loadImageUrl(item.imageId);
      } else {
        break;
      }
    }
  };

  return (
    <div className='row mx-0'>
      <div className={`flex-grow-1 ${stakeholderMapping ? 'reference-main-content' : ''}`}>
        {isLoading && (
          <>
            <SkeletonHolderListSkeleton stakeholderMapping={stakeholderMapping} />
          </>
        )}
        {!isLoading && (
          <div className={`${policymakerSection ? 'pt-2' : ''}`}>
            {currentlyProcessing.state && <WaitingAnimationScreen waitingMessage={'the most relevant stakeholders'} />}
            {!currentlyProcessing.state && (
              <>
                <BlueBannerText />

                {hitsToRender?.length === 0 ? (
                  <div
                    style={{
                      paddingTop: stakeholderMapping ? '8px' : undefined,
                    }}
                  >
                    <EmptyMessage
                      resourceName={parliamentarianUsers(code) ? 'policymakers' : 'stakeholders'}
                      filtersFunction={createResults}
                    />
                  </div>
                ) : (
                  <>
                    <div className={`stakeholder-list-content-scrollbar`} />
                    <ScrollbarList
                      ref={scrollBarRef}
                      createResults={createResults}
                      additionalClass={stakeholderMapping ? 'stakeholder-list-scrollbar' : ''}
                    >
                      <ul className='policy-list px-0 items-list stakeholder-items-list'>
                        {hitsToRender?.map((item, index, array) => (
                          <StakeholderItem
                            array={array}
                            scrollBarRef={scrollBarRef}
                            item={item}
                            key={`item${item.id}`}
                            index={index}
                            stakeholderIds={stakeholderIds}
                            isContact={contactIdsCheck[item.id]}
                            setContactIdsCheck={setContactIdsCheck}
                            contactIdsCheck={contactIdsCheck}
                            setShowStakeholdersCrmPopup={setShowStakeholdersCrmPopup}
                            highlights={contentResults[activeResults]?.[sectionName]?.highlights?.[index]}
                            openInNewTab={!!location.pathname.includes('/general-election-2024')}
                          />
                        ))}
                        {contentResults[activeResults]?.[sectionName]?.thereIsMoreContent && (
                          <StakeholderSkeletonItem />
                        )}
                      </ul>
                      <ResearchPortalButton />
                    </ScrollbarList>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {stakeholderMapping && !isMobile && (
        <div className='ml-4 outside-filters-container outside-filters-stakeholder '>
          <Filters section={'Stakeholders'} useOutside />
        </div>
      )}
      {showRemoveContactsModal && <RemoveContactsModal contactIdsCheck={contactIdsCheck} />}
    </div>
  );
};

export { useCallContactIdsResults };
export default StakeHolderList;

import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { net_api_url, store } from '../../../../Store';
import { useHeightContainer } from '../../../utilities/useHeightContainer';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import UpdateCustomContactSkeleton from './UpdateCustomContactSkeleton';
import SalutationInfoButton from '../SalutationInfoButton';
import addMetaTags from '../../../utilities/addMetaTags';
import useGetContactById from './useGetContactById';
import { BioElementsTabs, TagsSection } from '../../../StakeHolders/StakeholderPage/ui/BioElements';
import { validateEmail } from '../../../../../utils/validateEmail';
import { Emails, Notes } from '../../../StakeHolders/StakeholderPage/ui/ActivityLog';
import { sendEmailToContacts } from '../CrmContactsTable/CrmContactsListBar/sendEmailToContacts';
import customApiCall from '../../../apiCalls/customApiCall';
import useIsMobile from '../../../utilities/useIsMobile';
import { normalizedDateUtc } from '../../../utilities/normalizedDateUK';
import Skeleton from 'react-loading-skeleton';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import InfluenceSectionTitle from '../../InfluenceSectionTitle/InfluenceSectionTitle';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import ChampionStatus from '../../tableComponents/ChampionStatus';
import useGetAccessToken from '../../../apiCalls/useGetAccessToken';

function UpdateCustomContact() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { customContactInfo: contact } = state;
  const [activeSection, setActiveSection] = useState('profile');
  const [isLoading, setIsLoading] = useState(true);
  const setContact = (val) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'customContactInfo', value: val });
  };
  const isMobile = useIsMobile();
  const location = useLocation();

  const params = useParams();
  const { id } = params;

  const { getContactById } = useGetContactById();

  const setTitle = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'updateCustomContactTitle',
      value: value,
    });
  };

  const asyncFn = async (source) => {
    try {
      const contactRes = await getContactById(id, source);
      if (contactRes) {
        setContact(contactRes);
        setTitle({
          firstName: contactRes.firstName,
          lastName: contactRes.lastName,
        });
      }
      let title = `${contactRes.firstName} ${contactRes.lastName} - Contact - PolicyMogul`;
      addMetaTags({ title, hash: null, location, dispatch });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    asyncFn(source);
    return () => {
      source.cancel('Contact cancelled by user');
    };
  }, []);

  const updateChampionStatus = ({ value }) => {
    let newContact = { ...contact };
    newContact = {
      ...newContact,
      championStatus: value,
    };
    setContact(newContact);
  };

  return (
    <div className='w-100 pr-xl-5 px-md-4 px-3 py-2 pt-lg-4 h-100 update-custom-contact-wrapper'>
      <div className='mobile-stakeholder-buttons d-flex d-lg-none box-top-rounded'>
        <button
          className={`general-button stakeholder-section-button flex-grow-1 ${activeSection === 'profile' ? 'active' : ''}`}
          onClick={() => {
            setActiveSection('profile');
          }}
        >
          Profile
        </button>
        <button
          className={`general-button stakeholder-section-button flex-grow-1 ${activeSection === 'activity' ? 'active' : ''}`}
          onClick={() => {
            setActiveSection('activity');
          }}
        >
          <span>Activity</span>
        </button>
      </div>
      {isMobile ? (
        <div className='d-block custom-contact-mobile-container'>
          {activeSection === 'profile' && (
            <ContactForm isLoading={isLoading} contact={contact} id={id} setTitle={setTitle} />
          )}
          {activeSection === 'activity' && (
            <ContactActivities
              isLoading={isLoading}
              contactInformation={contact}
              setContact={setContact}
              tags={contact?.tags}
              updateChampionStatus={updateChampionStatus}
            />
          )}
        </div>
      ) : (
        <div className='d-lg-flex'>
          <ContactActivities
            isLoading={isLoading}
            contactInformation={contact}
            setContact={setContact}
            tags={contact?.tags}
            updateChampionStatus={updateChampionStatus}
          />
          <div className='flex-grow-1'>
            <ContactForm isLoading={isLoading} contact={contact} id={id} setTitle={setTitle} />
          </div>
        </div>
      )}
    </div>
  );
}

const ContactForm = (props) => {
  const { isLoading, contact, id, setTitle } = props;
  const [error, setError] = useState({ type: '', message: '' });
  const [heightContainer, containerRef] = useHeightContainer();
  const [saveLoading, setSaveLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const globalState = useContext(store);
  const { state } = globalState;
  const { updateCustomContactTitle } = state;
  const { getAccessToken } = useGetAccessToken();

  const dataElements = useRef([]);
  const scrollBarRef = useRef();

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  let requestHeaders = {
    cancelToken: source.token,
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2 col-lg-4 mt-2 mt-lg-0 position-relative'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };

  const validateForm = () => {
    let elements = dataElements.current;
    let fields = Object.keys(elements);
    for (let i = 0; i < fields.length; i++) {
      let field = elements[fields[i]];
      let val = field.value;
      if (field.required && val === '') {
        setError({ type: fields[i], message: 'Please complete this field' });
        return false;
      } else if (!validateEmail(String(elements['email'].value))) {
        setError({
          type: 'email',
          message: 'Please enter a valid email',
        });
        return false;
      }
    }
    return true;
  };

  const saveContact = async () => {
    try {
      let token = await getAccessToken();
      let emailAddress = dataElements.current['email'].value;
      let firstName = dataElements.current['firstName'].value;
      let lastName = dataElements.current['lastName'].value;
      let salutation = dataElements.current['salutation'].value;
      let description = dataElements.current['description'].value;

      requestHeaders.headers = {
        Authorization: `Bearer ${token}`,
      };
      let requestProperties = {
        id,
        emailAddress,
        firstName,
        lastName,
        salutation,
        description,
      };

      const result = await Axios.post(`${net_api_url}/api/crm-contact/update`, requestProperties, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (result.status === 200) {
        let successMessage = `Contact ${firstName} ${lastName} saved`;
        createNotification('success', successMessage);
        setIsSaved(true);
      }
    } catch (error) {
      let warningMessage = 'Error occurred, please try again later';
      if (error?.response?.message) {
        warningMessage = error?.response?.message;
      }
      createNotification('danger', warningMessage);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleChange = (e) => {
    setIsSaved(false);
    if (e?.target?.name === error?.type) {
      setError({ type: '', message: '' });
    }
    if (e?.target?.name === 'firstName') {
      setTitle({ ...updateCustomContactTitle, firstName: e.target.value });
    }
    if (e?.target?.name === 'lastName') {
      setTitle({ ...updateCustomContactTitle, lastName: e.target.value });
    }
  };

  const saveChanges = () => {
    let validate = validateForm();
    if (validate) {
      setSaveLoading(true);
      saveContact();
    }
  };

  return (
    <>
      <div ref={containerRef} />
      <CustomScrollbar
        className={`main-content-scrollbar setting-scrollbar`}
        style={{ height: `${heightContainer}px` }}
        maximalThumbYSize={100}
        ref={scrollBarRef}
      >
        <div className='update-custom-contact-container box-top-rounded pt-4 p-3 px-lg-5 position-relative w-100 h-100'>
          {isLoading ? (
            <UpdateCustomContactSkeleton />
          ) : (
            <div className={`account-list px-0 pb-2 update-custom-contact-body`}>
              <div className='pr-3' id='settings-container'>
                <ul className='policy-list px-0 hover-content'>
                  <form className='mx-0'>
                    <div className='px-0'>
                      <li className='add-contact-list-item'>
                        <h3>First name</h3>
                        <input
                          defaultValue={contact?.firstName}
                          className='mt-2'
                          type='text'
                          ref={(input) => (dataElements.current['firstName'] = input)}
                          name='firstName'
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                        {error.type === 'firstName' && <Error />}
                      </li>

                      <li>
                        <h3>Last name</h3>
                        <input
                          defaultValue={contact?.lastName}
                          className=' mt-2'
                          type='text'
                          ref={(input) => (dataElements.current['lastName'] = input)}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                          name='lastName'
                        />
                        {error.type === 'lastName' && <Error />}
                      </li>
                      <li>
                        <h3>Email address</h3>
                        <div className='mt-2 account-email-field'>
                          <input
                            defaultValue={contact?.email}
                            type='text'
                            required
                            ref={(input) => (dataElements.current['email'] = input)}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name='email'
                          />
                        </div>
                        {error.type === 'email' && <Error />}
                      </li>
                      <li>
                        <SalutationInfoButton />
                        <div className='mt-2 account-email-field'>
                          <input
                            defaultValue={contact?.salutation}
                            type='text'
                            required
                            ref={(input) => (dataElements.current['salutation'] = input)}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name='salutation'
                          />
                        </div>
                        {error.type === 'salutation' && <Error />}
                      </li>
                      <li>
                        <h3>
                          Description <span className='font-weight-normal opacity-8'>(Optional)</span>
                        </h3>
                        <div className='mt-2 account-email-field'>
                          <textarea
                            defaultValue={contact?.body}
                            type='text'
                            style={{ resize: 'none' }}
                            ref={(input) => (dataElements.current['description'] = input)}
                            onChange={(e) => {
                              let el = e.target;
                              setTimeout(function () {
                                el.style.cssText = `height:auto; padding:0;`;
                                el.style.cssText = 'height:' + el.scrollHeight + `px;`;
                                scrollBarRef.current.scrollToBottom();
                              }, 5);
                              handleChange(e);
                            }}
                            name='description'
                          />
                        </div>
                        {error.type === 'description' && <Error />}
                      </li>
                    </div>
                  </form>
                </ul>
              </div>
            </div>
          )}
          {!isSaved && (
            <div className='py-3 account-save-container px-3 px-lg-5'>
              <button className='action-button btn general-button py-2 px-4' onClick={saveChanges}>
                {saveLoading ? <i className='fas fa-spinner fa-spin'></i> : 'Save'}
              </button>
            </div>
          )}
        </div>
      </CustomScrollbar>
    </>
  );
};

const ContactActivities = (props) => {
  const { contactInformation, isLoading, tags, updateChampionStatus } = props;
  const [activeTab, setActiveTab] = useState('Profile');
  const { email, addedByUserFullName, createdAtUtc, objectID: objectId } = contactInformation ?? {};

  const navigate = useNavigate();
  const location = useLocation();
  const [scrollBarPosition, setScrollBarPosition] = useState(0);

  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, listDomainsVerified } = state;
  const { getAccessToken } = useGetAccessToken();

  const { newAgencyPlan } = useTopicOrClientWord();

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const { isAuthenticated } = useAuth0();

  const params = useParams();
  const { id } = params;

  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarRef = useRef();
  const sendEmailToContact = async ({ e }) => {
    let requestHeaders = {
      cancelToken: source.token,
    };
    await sendEmailToContacts({
      contactsToSendIds: [id],
      getAccessToken,
      customApiCall,
      query: new URLSearchParams(location.search),
      navigate,
      requestHeaders,
      filters: [],
      setIsLoading: () => {},
      focusToField: true,
      listDomainsVerified: listDomainsVerified,
    });
  };

  useEffect(() => {
    scrollBarRef.current.scrollTo(0, 0);
  }, []);

  return (
    <div className={`box-top-rounded custom-contact-activities-box mr-lg-4`}>
      <div ref={containerRef}></div>
      <div className='px-2 px-md-0 custom-contact-section-title-wrapper box-top-rounded'>
        <InfluenceSectionTitle />
      </div>
      <CustomScrollbar
        className={`main-content-scrollbar`}
        style={{ height: `${heightContainer}px` }}
        maximalThumbYSize={100}
        ref={scrollBarRef}
        onScroll={(scrollValues) => {
          const { scrollTop } = scrollValues;
          setScrollBarPosition(scrollTop);
        }}
      >
        {isLoading ? (
          <CustomContactActivitiesSkeleton />
        ) : (
          <div className='biography-secondary-container'>
            <BioElementsTabs activeTab={activeTab} setActiveTab={setActiveTab} newAgencyPlan={newAgencyPlan} />
            {activeTab === 'Profile' && (
              <>
                <div className={`px-4 pb-3 border-bottom`}>
                  <h4 className='category-subtitle member-info-subtitle'>Email</h4>
                  <p className='mb-0 member-data'>
                    <a className='external-url mail-url' href={`mailto:${email}`}>
                      {email}
                      <button
                        className='general-button member-email-button'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let tempInput = document.createElement('input');
                          tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
                          tempInput.value = email;
                          document.body.appendChild(tempInput);
                          tempInput.select();
                          document.execCommand('copy');
                          document.body.removeChild(tempInput);
                          createNotification('success', `Email address has been copied`);
                        }}
                        data-tooltip-content='Copy email address'
                        data-tooltip-id='general-tooltip'
                      >
                        Copy
                      </button>
                    </a>
                  </p>
                  <h4 className='category-subtitle member-info-subtitle'>Champion status</h4>
                  <div className='pt-1 px-0 col-lg-8'>
                    <ChampionStatus
                      updateChampionStatus={updateChampionStatus}
                      contact={contactInformation}
                      scrollBarPosition={scrollBarPosition}
                    />
                  </div>
                  <h4 className='category-subtitle member-info-subtitle'>Added by</h4>
                  <p className='mb-0 member-data'>{addedByUserFullName}</p>
                  <h4 className='category-subtitle member-info-subtitle'>Created at</h4>
                  <p className='mb-0 member-data'>{normalizedDateUtc(createdAtUtc).format('D MMM, YYYY')}</p>
                </div>
                <TagsSection
                  newAgencyPlan={newAgencyPlan}
                  contactInformation={contactInformation}
                  isAuthenticated={isAuthenticated}
                  isAddedToMyContacts={true}
                  tags={tags}
                  customContact
                />
              </>
            )}
            {activeTab === 'Notes' && (
              <Notes
                StakeholderInformation={{ objectId }}
                activePlan={activePlan}
                isAddedToMyContacts={true}
                contentType={'CrmContact'}
              />
            )}
            {activeTab === 'Emails' && (
              <Emails
                StakeholderInformation={{ id }}
                activePlan={activePlan}
                sendEmailToContact={sendEmailToContact}
                isAddedToMyContacts={true}
                contentType={'CrmContact'}
                crmContact
              />
            )}
          </div>
        )}
      </CustomScrollbar>
    </div>
  );
};

const CustomContactActivitiesSkeleton = () => {
  return (
    <>
      <div className='flex-centered p-4'>
        <Skeleton height={30} width={'112px'} />
        <div className='px-3'>
          <Skeleton height={30} width={'112px'} />
        </div>
        <Skeleton height={30} width={'112px'} />
      </div>
      <div className={`pb-3 pt-4 section-title px-4`}>
        {Array(3)
          .fill()
          .map((item, key) => (
            <div className={`${key !== 0 ? 'pt-4' : ''} `} key={`item-text-${key}`}>
              <div className='mb-1'>
                <Skeleton height={19} width={'50%'} />
              </div>
              <div className=''>
                <Skeleton height={19} width={'30%'} />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default UpdateCustomContact;

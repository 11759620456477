import React, { useCallback, useRef, useState } from 'react';
import TagsInput from 'react-tagsinput';
import useOutsideElement from '../../../Dashboard/utilities/useOutsideElement';
import handleChangeKeywords from '../../../Settings/Topics/Helpers/handeChangeKeywords';
import { clickedOnRemoveTag } from '../../../Settings/Topics/TopicKeywords';

const AddKeywordsComponent = (props) => {
  const { parameter, currentKeywordCategory, setValue, excludeDuplicatedWordsRule } = props;
  const tagsContainer = useRef(null);
  const valueToModify = currentKeywordCategory?.[parameter];
  const [editState, setEditState] = useState(false);
  const inputRef = useRef(null);
  const keywordsElement = useRef(null);

  const deactivateBox = () => {
    tagsContainer.current.accept();
    setEditState(false);
  };
  useOutsideElement(keywordsElement, deactivateBox);

  const handleChange = (newKeywords, changed, changedIndexes) => {
    const trimmedKeywords = handleChangeKeywords({
      newKeywords,
      changed,
      changedIndexes,
      parameter,
      currentKeywordCategory,
      excludeDuplicatedWordsRule,
    });
    setValue({ property: parameter, newValue: trimmedKeywords });
  };

  const activeKeywords = (e) => {
    if (clickedOnRemoveTag(e)) {
      return;
    }
    setEditState(true);
    setTimeout(() => {
      let inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
      }
    }, 50);
  };

  const defaultRenderTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <span
        key={key}
        {...other}
        style={{
          color: `#00122B`,
          paddingRight: editState ? '25px' : '10px',
        }}
      >
        {getTagDisplayValue(tag)}
        {!disabled && editState && (
          <button className={`${classNameRemove} general-button`} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  };

  const defaultRenderLayout = (tagComponents, inputComponent) => {
    return (
      <>
        <div className='d-flex flex-wrap align-items-center'>
          {tagComponents}
          {editState && (
            <>
              <span></span>
              <span className='flex-grow-1'>{inputComponent}</span>
            </>
          )}
          {!editState && (
            <p
              tabIndex={0}
              id='topic-keywords-input'
              className={`${valueToModify.length === 0 ? 'light-text add-keywords-text' : 'edit-item'}`}
              onClick={() => {
                activeKeywords();
              }}
              onFocus={() => {
                activeKeywords();
              }}
            >
              {valueToModify.length === 0 ? `Start typing...` : 'Edit'}
            </p>
          )}
        </div>
      </>
    );
  };

  const handleInputPaste = useCallback((event) => {
    let clipboard = event.clipboardData,
      text = clipboard.getData('Text');
    if (text.trim().includes('\n')) {
      inputRef.current.value = text.split('\n').join(', ') + ', ';
      setTimeout(() => {
        inputRef.current.value = inputRef.current.value.slice(0, -(text.length - 1));
        tagsContainer.current.accept();
      }, 5);
      setTimeout(() => {
        setValue({ property: parameter, newValue: tagsContainer.current.props.value.slice(0, -1) });
      }, 10);
    }
  });

  return (
    <>
      <div
        className={`keywords-category-words keywords-category-${parameter}`}
        ref={keywordsElement}
        onClick={(e) => {
          activeKeywords(e);
        }}
      >
        <TagsInput
          ref={tagsContainer}
          onlyUnique={true}
          value={valueToModify}
          onChange={handleChange}
          renderTag={defaultRenderTag}
          renderLayout={defaultRenderLayout}
          inputProps={{
            placeholder: currentKeywordCategory?.[parameter]?.length === 0 ? 'Start typing...' : '',
            ref: inputRef,
            style: { width: `100%` },
            onPaste: handleInputPaste,
          }}
        />
      </div>
    </>
  );
};

export default AddKeywordsComponent;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import GeneralTooltips from '../Dashboard/components/GeneralTooltips';
import ReactGA from 'react-ga4';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import validateParameter from '../../utils/validateParameter';
import { useCreateAnalyticsSections } from '../Dashboard/Analytics/ui/AnalyticsSidebar';
import GraphItem from '../Dashboard/Analytics/AnalyticsDashboardGraphItem';
import Loading from '../Login/Loading';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useOutsideElement from '../Dashboard/utilities/useOutsideElement';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import { mentionsGraphNations } from '../Dashboard/Analytics/createAnalyticsState';
import normalize from '../Dashboard/utilities/normalizeString';
import CustomScrollbar from '../Common/CustomScrollbar';
import { hex_is_light } from '../Reports/ui/ReportDesignButton';
import { VocalWidgetContainer } from '../Dashboard/Analytics/ui/AnalyticsDashboard/MostVocalWidget';
import FeedNoAvailable from '../embed/FeedNoAvailable';
import EmbedAnalyticsIndividual from './EmbedAnalyticsIndividual';

const EmbedAnalytics = (props) => {
  const { analyticsSections } = useCreateAnalyticsSections();
  const {
    selectedTopics,
    chartTypesSelected,
    isFromPopup,
    keywordsLists,
    selectedCountriesProps,
    designState,
    searchValue,
    header: headerProps,
    subheader: subheaderProp,
    scrollBarPositionState,
    isLoadingContent,
  } = props;

  const { generalApiCall } = useGeneralApiCall();
  const { agencyUser } = useTopicOrClientWord();
  const [analyticsKeywordsLists, setAnalyticsKeywordsLists] = useState(
    keywordsLists ? keywordsLists.filter((list) => topicsIds.includes(list.id)) : []
  );

  const embedState = {
    topicsIds:
      selectedTopics ??
      (validateParameter('topic-id')
        ? [validateParameter('topic-id')]
        : validateParameter('topicsIds')
            ?.split(',')
            ?.map((item) => parseInt(item))),
    chartTypes:
      chartTypesSelected ??
      (validateParameter('type') ? [validateParameter('type')] : validateParameter('chartTypes')?.split(',')),
    headerColour:
      designState?.headerColour ??
      (validateParameter('titleColor') ? validateParameter('titleColor') : validateParameter('headerColour')),
    background: designState?.backgroundColour ?? validateParameter('backgroundColour'),
    includeBranding: designState?.includeBranding ?? validateParameter('includeBranding'),
    agency: agencyUser || !!validateParameter('agency'),
    header: headerProps ?? (!!validateParameter('header') ? validateParameter('header') : `Live political data`),
    search: searchValue ?? validateParameter('search'),
    subheader:
      subheaderProp ??
      (!!validateParameter('subheader')
        ? validateParameter('subheader')
        : `Real-time political data ${agencyUser ? 'relevant' : 'related'} to ${analyticsKeywordsLists?.[0]?.label}`),
  };

  const { topicsIds, chartTypes, background, headerColour, agency, search, header, subheader } = embedState;
  const [analyticsPlan, setAnalyticsPlan] = useState({});
  const { embedAnalyticsWidgetsAllowed, removeAnalyticsWidgetBrandingAllowed } = analyticsPlan ?? {};

  const sections =
    chartTypes[0] === 'Dashboard'
      ? analyticsSections
      : chartTypes.map((item) => {
          return analyticsSections.find((chart) => chart.name === item);
        });
  const includeBranding = removeAnalyticsWidgetBrandingAllowed ? embedState?.includeBranding : true;
  const singleGraph = sections.length === 1;

  const [topic, setTopic] = useState(
    keywordsLists ? keywordsLists.find((item) => parseInt(item.id) === parseInt(topicsIds[0])) : {}
  );
  const selectedCountries = useMemo(
    () =>
      selectedCountriesProps ??
      mentionsGraphNations.filter((item) => validateParameter('nations')?.split('-').includes(normalize(item.value))),
    [selectedCountriesProps]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [heightContainer, containerRef] = useHeightContainer();
  const [scrollBarPosition, setScrollBarPosition] = useState(0);
  const [currentChart, setCurrentChart] = useState(singleGraph ? sections[0]?.name : null);

  const teamId = props?.teamId ?? validateParameter('teamId');

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const scrollbarContentContainer = useRef();
  const addScrollClass = () => {
    let el = scrollbarContentContainer?.current?.contentElement?.parentElement;
    if (el?.scrollHeight > el?.clientHeight) {
      el?.classList?.add('element-with-scroll');
    } else {
      el?.classList?.remove('element-with-scroll');
    }
  };

  const loadLists = useRef();
  loadLists.current = async () => {
    setIsLoading(true);
    if (teamId) {
      if (!keywordsLists) {
        const pathname = `/api/widget/team-topics-for-analytics?teamId=${teamId}&topicIds=${topicsIds.join(',')}`;
        const method = 'get';
        const results = await generalApiCall({ pathname, method });
        if (results) {
          let lists = results.map((item) => {
            return { ...item, name: item.label, id: item.value };
          });
          setAnalyticsKeywordsLists(lists);
          setTopic(lists.find((item) => parseInt(item.id) === parseInt(topicsIds[0])));
        }
      }
      const analyticsPlan = await analyticsPlanCall();
      if (analyticsPlan) {
        setAnalyticsPlan(analyticsPlan);
      }
    }
    setIsLoading(false);
    addScrollClass();
  };

  const analyticsPlanCall = async () => {
    const method = 'get';
    const pathname = `/api/widget/plan?team-id=${teamId}`;
    const results = await generalApiCall({ method, pathname });
    return results;
  };

  useEffect(() => {
    loadLists.current();
    return () => {
      source.cancel('Embed analytics cancelled by the user');
    };
  }, []);

  useEffect(() => {
    if (keywordsLists) {
      setAnalyticsKeywordsLists(keywordsLists.filter((list) => topicsIds.includes(list.id)));
      setTopic(keywordsLists.find((item) => parseInt(item.id) === parseInt(topicsIds[0])));
    }
  }, [selectedTopics]);

  useEffect(() => {
    addScrollClass();
  }, [isLoading, chartTypes]);

  return (
    <>
      <div ref={containerRef} style={{ height: isFromPopup ? '100%' : `${heightContainer - 2}px` }}>
        <div className={`embed-content h-100`}>
          <div className='dashboard-root-container rounded border analytics-embed-container'>
            {isLoading ? (
              <div className='flex-grow-1 position-relative'>
                <Loading />
              </div>
            ) : (
              <>
                {embedAnalyticsWidgetsAllowed ? (
                  <>
                    <div style={{ background: headerColour, color: hex_is_light(headerColour) ? '#00122b' : '#fff' }}>
                      <TitleWidget
                        isFromPopup={isFromPopup}
                        selectedTopics={selectedTopics ?? analyticsKeywordsLists}
                        topic={topic}
                        setTopic={setTopic}
                        analyticsKeywordsLists={analyticsKeywordsLists}
                        agencyUser={agency}
                        header={header}
                        subheader={subheader}
                      />
                    </div>
                    <div className='flex-grow-1' style={{ background: background }}>
                      {isLoadingContent ? (
                        <Loading />
                      ) : (
                        <>
                          {currentChart ? (
                            <EmbedAnalyticsIndividual
                              topic={topic}
                              chart={currentChart}
                              setCurrentChart={setCurrentChart}
                              teamId={teamId}
                              singleGraph={singleGraph}
                            />
                          ) : (
                            <CustomScrollbar
                              className={'simple-scrollbar'}
                              maximalThumbYSize={100}
                              ref={scrollbarContentContainer}
                              onScroll={(scrollValues) => {
                                setScrollBarPosition(scrollValues?.scrollTop);
                              }}
                            >
                              <div className={`p-4 row mx-0`}>
                                {sections.map((section, index) => {
                                  const { name } = section;
                                  const mostVocal = name === 'Most vocal policymakers';
                                  const stakeholdersGraph = name === 'Top MPs and peers';
                                  const restrictedSection = mostVocal || stakeholdersGraph;
                                  return (
                                    <div
                                      key={`item-container-${name}-${index}`}
                                      className={`${singleGraph ? 'col-lg-16 ' : 'col-lg-8 mb-4 '}  dashboard-analytics px-3 ${restrictedSection ? '' : 'pointer'}`}
                                      style={singleGraph ? { height: '47vh' } : { minHeight: '375px' }}
                                      onClick={() => {
                                        if (!restrictedSection) {
                                          setCurrentChart(name);
                                        }
                                      }}
                                    >
                                      {name === 'Most vocal policymakers' ? (
                                        <VocalWidgetContainer
                                          search={search}
                                          topic={topic}
                                          scrollBarPosition={scrollBarPosition}
                                          teamId={teamId}
                                          isFromPopup={isFromPopup}
                                        />
                                      ) : (
                                        <div
                                          className={`box-outstanding dashboard-root-container p-4 position-relative box-rounded trends-ink-box h-100 ${singleGraph ? '' : 'mb-4'}`}
                                        >
                                          <h3 className='title-h5-m-bold mb-4 position-relative z-max'>{name}</h3>
                                          <div className='flex-grow-1'>
                                            <GraphItem
                                              isFromPopup={isFromPopup}
                                              key={`item-${name}-${topic?.id}`}
                                              topic={topic}
                                              chartTypesSelected={chartTypesSelected}
                                              chartType={name}
                                              teamId={teamId}
                                              selectedCountriesProps={selectedCountries}
                                              scrollBarPosition={
                                                !!scrollBarPositionState?.scrollBarPosition
                                                  ? scrollBarPositionState?.scrollBarPosition
                                                  : scrollBarPosition
                                              }
                                              totalHeight={singleGraph}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </CustomScrollbar>
                          )}
                        </>
                      )}
                    </div>
                    {(typeof includeBranding === 'string' ? includeBranding === 'true' : includeBranding) && (
                      <div className='pr-4 text-right py-3 border-top' style={{ background: background }}>
                        <a
                          className='text-decoration-none text-main-blue'
                          href={`${process.env.REACT_APP_APPURL}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ color: hex_is_light(background) ? '#00122b' : '#fff' }}
                        >
                          Political analytics powered by{' '}
                          <img
                            src={`${process.env.REACT_APP_CDNURL}/images/logo${hex_is_light(background) ? '' : '_white'}.svg`}
                            alt='PolicyMogul logo'
                            width={'90px'}
                            className={`d-inline-block ml-1`}
                          />{' '}
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <FeedNoAvailable />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <GeneralTooltips />
    </>
  );
};

const TitleWidget = (props) => {
  const { analyticsKeywordsLists, topic, setTopic, header, subheader, selectedTopics, userId } = props;
  const [openLists, setOpenLists] = useState(false);
  const topicsContainer = useRef(null);
  const topicName = useRef(null);

  const topicParameter = getUrlParam('topic-id');
  const topicNameToShow = !!topic
    ? topic.name
    : topicParameter
      ? analyticsKeywordsLists.find((item) => item.id === parseInt(topicParameter))?.name
      : analyticsKeywordsLists[0]?.name;

  const hideTopicsContainer = () => {
    setOpenLists(false);
  };
  useOutsideElement(topicsContainer, hideTopicsContainer);

  const multipleKeywordLists = analyticsKeywordsLists.length > 1;
  const left = topicName.current?.offsetLeft ?? 0;
  const top = topicName.current?.offsetTop ?? 0;

  return (
    <div className='position-relative pt-5 pb-3 embed-analytics-topic-picker embed-analytics-widget-title'>
      <div className='embed-padding'>
        <div className='widget-title-container' style={{ paddingLeft: '8px' }}>
          {!!header && <h3 className='main-title mb-2'>{header}</h3>}
          <p
            className={`${!!header ? ' main-description' : 'main-title font-weight-bold'} mb-0 pr-2`}
            style={{ lineHeight: '1.5' }}
          >
            {subheader}{' '}
            {selectedTopics?.length > 1 && !userId && (
              <span
                ref={topicName}
                className={`font-weight-bold ${
                  multipleKeywordLists
                    ? `dropdown-item-element dropdown-item-element-analytics-popup text-underline-hover pointer ${
                        openLists ? 'open text-underline' : ''
                      }`
                    : ''
                }`}
                onClick={() => {
                  if (multipleKeywordLists) {
                    setOpenLists(!openLists);
                  }
                }}
              >
                {topicNameToShow}
              </span>
            )}
          </p>
        </div>
        {openLists && (
          <div
            className='box-shadow position-absolute rounded'
            ref={topicsContainer}
            style={{
              minWidth: '300px',
              width: `${topicsContainer?.current?.getBoundingClientRect()?.width - left}`,
              top: `${top + 28}px`,
              left: `${left}px`,
              zIndex: 10000,
            }}
          >
            {analyticsKeywordsLists
              ?.filter((item) => !!selectedTopics.find((topic) => item.id === topic.id))
              ?.map((list) => {
                const { name, id } = list;
                return (
                  <div
                    key={`topic-${id}`}
                    className={`list-action-button`}
                    onClick={() => {
                      setTopic(list);
                      setOpenLists(false);
                    }}
                  >
                    <li className={`dropdown-keywordlist d-flex title-h5-m`}>
                      <div className='flex-grow-1'>
                        <p className='hidden-lines hidden-one-line dropdown-name mb-0'>{name}</p>
                        {topic?.id === id && (
                          <span className='icon-tick topic-selected-mark text-main-blue paragraph-p2' />
                        )}
                      </div>
                    </li>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const EmbedMain = () => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('embed-widget-body');
    ReactGA.initialize(`${process.env.REACT_APP_WIDGET_ANALYTICSCODE}`);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return <>{<EmbedAnalytics />}</>;
};

export { EmbedAnalytics, TitleWidget };
export default EmbedMain;

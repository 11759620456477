import React, { useRef, useState, useContext, useEffect } from 'react';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import createNotification from '../Utilities/CreateNotification';
import { store } from '../../Store.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import normalize from '../../Dashboard/utilities/normalizeString';
import changeItemNamesPascalCase from './Helpers/changeItemsPascalCase';
import createResourcesName from './Helpers/createResourcesName';
import parliamentarianUsers from '../../Dashboard/sidebar/parliamentarianUsers';
const Resources = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, activePlan } = state;
  const { planCode } = activeSubscriptions;
  const { resources, typeAlert, allow, referenceValues, adminSubscriptions, scrollPosition, includeIcon } = props;
  const enableTwitter =
    adminSubscriptions?.enableTwitter ?? activeSubscriptions?.enableTwitter ?? activePlan?.enableTwitter;
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';
  let initialContent =
    referenceValues ??
    (parliamentarianUsers(planCode)
      ? [
          'Key updates',
          'Campaigns',
          'Consultations',
          'Legislation',
          'Official records',
          'Library material',
          'Early Day Motions',
          'Answered written questions',
          'Newly tabled written questions',
        ]
      : [
          'Key updates',
          'Consultations',
          'Legislation',
          'Official records',
          'Lobbying material',
          'Library material',
          'Early Day Motions',
          'Answered written questions',
          'Newly tabled written questions',
        ]);
  let createContent = () => {
    if (showTweets && !initialContent.includes('Tweets')) {
      return [...initialContent, 'Tweets'];
    } else {
      return initialContent;
    }
  };

  let content = createContent();

  const [showResources, setShowResources] = useState(false);
  const resourcesElement = useRef(null);
  const ImmediateWarningText = `Questions & answers ${showTweets ? 'and Tweets ' : ''}often have a lot activity.<br/> Setting immediate alerts for ${showTweets ? 'these' : 'this'} resource${showTweets ? 's' : ''}<br/> may result in frequent notifications`;
  const hideResources = () => {
    setShowResources(false);
  };

  useOutsideElement(resourcesElement, hideResources);
  const [dropdownDimentions, setDropdownDimentions] = useState({ top: 0, width: 0 });

  const createDimentions = () => {
    let node = resourcesElement.current;
    if (node !== null) {
      let top = node.getBoundingClientRect().top + node.getBoundingClientRect().height - 2;
      let distanceToBottom = window.innerHeight - (top + 40);
      setDropdownDimentions({
        top: top,
        width: node.getBoundingClientRect().width,
        maxHeight: distanceToBottom < 350 ? `${distanceToBottom}px` : 'none',
      });
    }
  };

  useEffect(() => {
    //AE: To wait the popup appears and get the correct position
    setTimeout(() => {
      createDimentions();
    }, 300);
    window.addEventListener('resize', createDimentions);
    return () => {
      window.removeEventListener('resize', createDimentions);
    };
  }, [resourcesElement.current, typeAlert]);

  useEffect(() => {
    if (scrollPosition && showResources) {
      createDimentions();
    }
  }, [scrollPosition]);

  const warningConditionalClass =
    typeAlert === 'Immediate' &&
    (resources.includes('QuestionsAndAnswers') || resources.includes('Tweets')) &&
    !showResources &&
    allow;
  const WarningIconImmediate = () => {
    return (
      <>
        {warningConditionalClass && (
          <button
            className='general-button dropdown-next-icon warning-resources'
            data-tooltip-html={ImmediateWarningText}
            data-tooltip-id='resources-tooltip'
          >
            Warning
          </button>
        )}
      </>
    );
  };

  return (
    <div className={`position-relative ${showResources ? 'dropdown-open' : ''}`} ref={resourcesElement}>
      <button
        className={`general-button pl-3 pr-4 mb-0 hour-select d-block col-16 text-left
        ${allow ? '' : 'disabled-hour-select'} ${showResources ? 'hour-select-opendropdown' : ''}
      `}
        onClick={() => {
          if (allow) {
            setShowResources(!showResources);
          }
        }}
      >
        {includeIcon && (
          <span className='d-inline-block' style={{ marginTop: '1px', marginLeft: '1px' }}>
            <span
              className='icon-key-update mr-2 paragraph-p3 d-inline-block position-relative'
              style={{ verticalAlign: 'middle', top: '-1px' }}
            />
          </span>
        )}
        {createResourcesName(resources.join(', '), referenceValues, planCode)}
      </button>

      {<WarningIconImmediate />}

      {showResources && (
        <div
          className='resources-dropdown'
          style={{
            position: 'fixed',
            top: `${dropdownDimentions.top}px`,
            width: `${dropdownDimentions.width}px`,
          }}
        >
          <ul
            className='policy-list px-0 scroll-container hover-container mb-0 '
            style={{
              height: 'auto',
              maxHeight: dropdownDimentions.maxHeight,
            }}
          >
            <div className='hover-content pl-2'>
              <ResourcesItems
                {...props}
                content={content}
                enableTwitter={enableTwitter}
                ImmediateWarningText={ImmediateWarningText}
              />
            </div>
          </ul>
        </div>
      )}
      <ReactTooltip
        id={'resources-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip'}
        backgroundColor={'#00122B'}
        textColor={'#fff'}
        multiline={true}
        arrowColor={'transparent'}
        place={'right'}
      />
    </div>
  );
};

const ResourcesItems = (props) => {
  const { content, enableTwitter, resources, changeResources, typeAlert, ImmediateWarningText, showRetweets } = props;

  const warningConditional = (item) => {
    if (
      typeAlert === 'Immediate' &&
      resources.includes('UnansweredWrittenQuestion') &&
      item === 'Newly tabled written questions'
    ) {
      return true;
    } else if (
      typeAlert === 'Immediate' &&
      resources.includes('AnsweredWrittenQuestion') &&
      item === 'Answered written questions'
    ) {
      return true;
    } else if (typeAlert === 'Immediate' && resources.includes('Tweets') && item === 'Tweets') {
      return true;
    } else {
      return false;
    }
  };

  const checkItem = (name, index) => {
    const applyResources = () => {
      let newResources = resources.slice();
      if (resources.includes(name)) {
        if (newResources.length === 1) {
          createNotification('danger', `You must have at least 1 resource selected`);
        } else {
          let position = newResources.indexOf(name);
          newResources.splice(position, 1);
          if (name === 'Tweets' && newResources.includes('Retweets')) {
            let retweetsPosition = newResources.indexOf('Retweets');
            newResources.splice(retweetsPosition, 1);
          }
          changeResources(newResources);
        }
      } else {
        newResources.splice(index, 0, name);
        changeResources(newResources);
      }
    };

    if (name !== 'Tweets' || (name === 'Tweets' && (enableTwitter || resources.includes('Tweets')))) {
      applyResources();
    }
  };

  return (
    <>
      {content.map((item, index) => {
        let namePascalCase = changeItemNamesPascalCase(item);
        return (
          <div key={`resource-${item}`}>
            <li className={`checkbox-item`}>
              <input
                id={normalize(item)}
                onChange={() => {
                  checkItem(namePascalCase, index);
                }}
                type='checkbox'
                checked={resources.includes(namePascalCase)}
              />
              <label htmlFor={normalize(item)}>
                {item}
                {warningConditional(item) && <WarningElement ImmediateWarningText={ImmediateWarningText} />}
                {item === 'Tweets' && !enableTwitter && (
                  <img
                    className='padlock-title-item'
                    data-tooltip-content='Twitter monitoring is available on Mogul'
                    data-tooltip-id='resources-tooltip'
                    src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`}
                    alt='padlock'
                  />
                )}
              </label>
            </li>
            {item === 'Tweets' && showRetweets && (
              <div className='pl-4'>
                <div className={`checkbox-item`}>
                  <input
                    id={'retweets-0'}
                    onChange={() => {
                      if (enableTwitter || resources.includes('Tweets')) {
                        let newResources = resources.slice();
                        if (resources.includes('Retweets')) {
                          let position = newResources.indexOf('Retweets');
                          newResources.splice(position, 1);
                          changeResources(newResources);
                        } else {
                          newResources.push('Retweets');
                          if (!newResources.includes('Tweets')) {
                            newResources.push('Tweets');
                          }
                          changeResources(newResources);
                        }
                      }
                    }}
                    type='checkbox'
                    checked={resources.includes('Retweets')}
                  />
                  <label htmlFor={'retweets-0'}>Retweets</label>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

const WarningElement = (props) => {
  const { ImmediateWarningText } = props;
  return (
    <>
      <img
        className='warning-resource'
        src={`${process.env.REACT_APP_CDNURL}/images/warning-icon-yellow.svg`}
        alt='warning-icon'
        data-tooltip-content={ImmediateWarningText}
        data-tooltip-id='resources-tooltip'
      />
    </>
  );
};

export default Resources;

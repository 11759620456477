import React, { useState, useContext } from 'react';
import Loading from './Loading';
import getActivePlan from '../Settings/Utilities/getActivePlan';
import { store } from '../Store.js';
import { useLocation } from 'react-router-dom-v5-compat';
import axios from 'axios';
import getUrlParam, { getUrlParamRaw } from '../Dashboard/utilities/getUrlParam';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken.js';

const deleteURLParams = () => {
  let cookieItem = document.cookie.split('; ').find((row) => row.startsWith('utm_parameters='));
  if (cookieItem) {
    document.cookie = `utm_parameters=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=policymogul.com; path=/; Secure`;
  }
};

const LoginSuccess = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { getAccessToken } = useGetAccessToken();

  const location = useLocation();
  const validationsCalls = async () => {
    let activePlan = await getActivePlan(getAccessToken, source);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
    const { userOnboarded } = activePlan ?? {}; //CHECK IF THE USER HAS CREATED THE FIRST LIST
    if (!userOnboarded) {
      return window.location.replace(`/create-your-profile/1${location.search}`);
    } else {
      if (getUrlParam('returnUrl') !== undefined) {
        let returnUrl = decodeURIComponent(getUrlParamRaw('returnUrl'));
        let params = new URLSearchParams(location.search);
        params.delete('returnUrl');
        return window.location.replace(
          `${returnUrl}${params.toString() !== '' ? `${returnUrl.includes('?') ? '&' : '?'}` : ''}${params.toString()}`
        );
      } else {
        return window.location.replace(`/monitor-inbox/key-updates${location.search}`);
      }
    }
  };

  useState(() => {
    deleteURLParams();
    // We need to remove the filters stored when you're logged out
    localStorage.removeItem('contentFilters');
    localStorage.removeItem('activeFilters');
    localStorage.removeItem('seeUnreadFilter');
    validationsCalls();
    return () => {
      source.cancel('Active plan canceled by the user.');
    };
  }, []);
  return <Loading />;
};

export { deleteURLParams };
export default LoginSuccess;

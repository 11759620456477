import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { store } from '../../../../Store';
import { CrmContactImage } from '../../CrmContacts/CrmContactsTable/CrmContactItem/CrmContactItem';
import AddContactModal from '../../CrmContacts/AddContactModal';
import InfluenceNotIncludedFeaturesPopup from '../../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import { useHeightContainer } from '../../../utilities/useHeightContainer';
import useIsMobile from '../../../utilities/useIsMobile';
import useOnScreen from '../../../hooks/useOnScreen';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat/';
import thousandCommas from '../../../helpers/thousandCommas';
import CustomHighlighter from '../../../utilities/CustomHighlighter';
import useGetAccessToken from '../../../apiCalls/useGetAccessToken';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  const location = useLocation();
  const sentEmail = location.pathname.includes('email-performance');
  const [selectedListPage, setSelectedListPage] = useState(1);

  const valueContainerRef = useRef(null);
  const containerRef = useRef(null);

  const containerHeight = () => {
    let height = window.innerHeight - containerRef.current?.getBoundingClientRect()?.top - 29;
    if (!Number.isNaN(height)) {
      return height;
    } else {
      return 0;
    }
  };

  return (
    <>
      <ValueContainer {...props}>
        <div ref={containerRef} className='RefferenceHeightComponent'></div>
        <div
          ref={valueContainerRef}
          className='ValueContainerScrollbar'
          style={{
            height: containerHeight(),
            paddingTop: !sentEmail ? '43px' : undefined,
          }}
          onScroll={(e) => {
            if (
              React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null)).length >
              selectedListPage * 20
            ) {
              if (Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1) {
                setSelectedListPage(selectedListPage + 1);
              }
            }
          }}
        >
          {!sentEmail && (
            <Placeholder {...props} isFocused={props.isFocused}>
              {props.selectProps.placeholder}
            </Placeholder>
          )}
          {props?.selectProps?.inputValue !== '' && <span className='icon-close'></span>}
          {!sentEmail &&
            React.Children.toArray(children, (child) => (child && child.type !== Placeholder ? child : null)).find(
              (item) => item.type === Input
            )}
          {[
            ...React.Children.map(children, (child) =>
              child && child.type !== Placeholder && child.type !== Input ? child : null
            ).slice(0, selectedListPage * 20),
          ]}
        </div>
      </ValueContainer>
    </>
  );
};

const CustomMultiValueLabel = (props) => {
  const { imageId, tagOption, label, email } = props.data;
  const [showToolTip, setShowTooltip] = useState(false);
  const labelTextRef = useRef();
  const isMobile = useIsMobile(768);
  const multivalueRef = useRef(null);
  const isVisible = useOnScreen(multivalueRef);
  useEffect(() => {
    if (labelTextRef?.current.clientWidth >= 202 && !isMobile) {
      setShowTooltip(true);
    }
  }, [labelTextRef.current, isVisible]);

  return (
    <div className='contacts-select-multivalue-item' ref={multivalueRef}>
      {isVisible ? (
        <>{tagOption ? <TagIcon isSelected={true} asValue /> : <CrmContactImage imageId={imageId} size={20} />}</>
      ) : (
        <div style={{ width: '20px' }} />
      )}
      <div
        ref={labelTextRef}
        data-tooltip-content={showToolTip && isVisible ? `${label}\n${email}` : undefined}
        data-tooltip-id={showToolTip && isVisible ? 'general-tooltip' : undefined}
        data-tooltip-class-name={
          showToolTip && isVisible ? 'header-tooltip pricing-tooltip-description table-item-cell-tooltip' : undefined
        }
        style={{ height: '30px' }}
      >
        {isVisible && <components.MultiValueLabel {...props} />}
      </div>
    </div>
  );
};

const CustomSingleLabel = (props) => {
  const { imageId } = props.data;
  const multivalueRef = useRef(null);
  return (
    <>
      <div className='contacts-select-multivalue-item' ref={multivalueRef}>
        <CrmContactImage imageId={imageId} size={20} />
        <div className='ml-1'>
          <components.SingleValue {...props} />
        </div>
      </div>
      <div
        className='close-icon-container'
        style={{
          position: 'absolute',
          right: '20px',
        }}
      >
        <button
          className='general-button close-icon'
          onClick={() => {
            props.clearValue();
          }}
        />
      </div>
    </>
  );
};

const TagIcon = ({ isSelected, asValue }) => {
  return (
    <div
      className='custom-contact-select-tag-icon'
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDNURL}/images/${
          isSelected ? 'crm-contacts-add-tag-blue-full-opacity' : 'crm-contacts-add-tag'
        }.svg`,
        backgroundPosition: asValue ? '1px 7px' : undefined,
      }}
    />
  );
};

const Input = (props) => {
  const isMobile = useIsMobile(768);
  const location = useLocation();
  const sentEmail = location.pathname.includes('email-performance');
  if (sentEmail) return null;
  return !isMobile ? (
    <div className='w-100'>
      <span className={`icon-search`} style={{ zIndex: 1 }}></span>
      <components.Input {...props}></components.Input>
    </div>
  ) : (
    <components.Input {...props}></components.Input>
  );
};

const CustomAsyncSelect = ({
  selectRef,
  sideBar,
  disabled,
  showHideAddContactModal,
  initialValues,
  autoFocus,
  Input,
  onKeyDown,
  CustomValueContainer,
  setValue,
  multi = true,
  includeTags = true,
  placeholder,
  loadOptions,
  emptyMessage,
}) => {
  const [inputValue, setInputValue] = useState('');
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { net_api_url } = state;
  let axiosCancelToken = useRef(null);
  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const navigate = useNavigate();

  useEffect(() => {
    if (!disabled && autoFocus && selectRef.current) {
      selectRef.current?.focus();
    }
  }, [disabled]);

  const getAsyncOptions = async (inputValue) => {
    let token = isAuthenticated ? await getAccessToken() : '';
    let contactUrl = `${net_api_url}/api/autocomplete/crm-contact?term=${inputValue}`;
    let tagsUrl = `${net_api_url}/api/autocomplete/crm-contact-tag?term=${inputValue}`;
    let tagsContactsUrl = `${net_api_url}/api/crm-contact/query`;

    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel();
    }

    axiosCancelToken.current = Axios.CancelToken.source();

    let requestOptions = {
      cancelToken: axiosCancelToken.current.token,
    };

    if (isAuthenticated) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    let callContactResults = await Axios.get(contactUrl, requestOptions);

    const getContactsQntty = async (tag) => {
      const contactsQntty = await Axios.post(
        tagsContactsUrl,
        {
          filters: [
            {
              field: 'tags',
              value: tag,
              operator: 'str_eq',
            },
          ],
          query: '',
          pageSize: 1000,
          pageNumber: 1,
        },
        requestOptions
      );
      return contactsQntty.data.totalHits;
    };

    let tagResults = [];

    if (includeTags) {
      let callTagsResults = await Axios.get(tagsUrl, requestOptions);
      tagResults = await Promise.all(
        callTagsResults.data.map(async (tag) => {
          return {
            value: tag.value,
            label: tag.label,
            tagOption: true,
            contacts: await getContactsQntty(tag.value),
          };
        })
      );
    }

    const contactResults = callContactResults.data.map((contact) => {
      return {
        value: contact.crmContactId,
        imageId: contact.imageId,
        label: contact.fullName,
        id: contact.crmContactId,
        email: contact.emailAddress,
      };
    });
    return includeTags
      ? [
          { label: 'tags', options: [...tagResults] },
          {
            label: 'contacts',
            options: !!contactResults.length
              ? [{ value: 'Select all', selectAllBtn: true }, ...contactResults]
              : [{ value: 'No contacts', disabled: true }],
          },
        ]
      : !!contactResults.length
        ? [{ value: 'Select all', selectAllBtn: true }, ...contactResults]
        : [{ value: 'No contacts', disabled: true }];
  };

  //AE: Source https://github.com/JedWatson/react-select/issues/614
  const getContactOptions = useCallback(
    _.debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: 'transparent',
      outline: 'none',
      boxShadow: 'none',
      ':hover': {
        borderColor: 'transparent',
      },
      backgroundColor: 'transparent',
      cursor: 'text',
      marginLeft: '-9px',
    }),
    option: (styles) => {
      return {
        ...styles,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        color: '#00122b',
        fontSize: '16px',
        ':active': {
          ...styles[':active'],
          backgroundColor: 'transparent',
        },
      };
    },
    placeholder: (styles, state) => ({
      ...styles,
      position: sideBar ? 'absolute' : undefined,
      top: sideBar ? '8px' : undefined,
      left: sideBar ? '38px' : undefined,
      display: sideBar ? (state.selectProps.inputValue ? 'none' : 'block') : undefined,
      zIndex: sideBar ? 0 : undefined,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      pointerEvents: disabled ? 'none' : 'all',
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: '120px',
      overflow: 'auto',
    }),
    loadingIndicator: (styles) => ({
      position: !sideBar ? undefined : 'absolute',
      top: !sideBar ? undefined : '12px',
      right: !sideBar ? undefined : '45px',
      ...styles,
    }),
  };

  const handleChange = async (selected, action) => {
    if (multi) {
      if (action.action === 'select-option' && action.option?.selectAllBtn) {
        return;
      }
      let contactsByTags = [];
      let newSelectedValue = selected;
      if (action.action === 'select-option' && action.option?.tagOption) {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        let token = isAuthenticated ? await getAccessToken() : '';
        let requestOptions = {
          cancelToken: source.token,
        };
        if (isAuthenticated) {
          requestOptions.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        const contactsByTagsUrl = `${net_api_url}/api/crm-contact/query`;
        const contactsByTagsRes = await Axios.post(
          contactsByTagsUrl,
          {
            filters: [
              {
                field: 'tags',
                value: action.option.value,
                operator: 'str_eq',
              },
            ],
            query: '',
            pageSize: 1000,
            pageNumber: 1,
          },
          requestOptions
        );
        contactsByTags = contactsByTagsRes?.data?.hits ?? [];
        if (contactsByTags.length) {
          contactsByTags = contactsByTags.map((contact) => {
            return {
              value: contact.email,
              imageId: contact.imageId,
              label: contact.title,
              id: contact.id,
            };
          });
        }
        //remove tag option from recipient list
        newSelectedValue = selected.filter(
          (option) => !(option.value === action.option.value && option.label === action.option.label)
        );
        //filter duplicates
        contactsByTags = contactsByTags.filter((contact) => !newSelectedValue.some((value) => value.id === contact.id));
        selectRef.current.setValue([...newSelectedValue, ...contactsByTags], 'set-value');
      }

      const valueToState = [...newSelectedValue, ...contactsByTags].map((val) => ({
        id: val.id,
        email: val.email,
      }));
      if (setValue) {
        setValue(valueToState);
      } else {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'emailSelectedContacts',
          value: valueToState,
        });
      }
    } else {
      setValue(selected);
    }
  };
  const Option = (props) => {
    const { tagOption, selectAllBtn } = props.data;
    const { isSelected, isFocused } = props;
    const input = props.selectProps.inputValue;
    const option = props.data.label;
    const value = props.data.value;
    const id = props.data.id;
    const disabled = props.data.disabled;

    const selectedValue = props.getValue();
    const contactsResultOptions = props.options
      ?.find((item) => item.label === 'contacts')
      ?.options?.filter((item) => !item?.selectAllBtn);
    const selectedAllCondition = contactsResultOptions?.every((item) =>
      selectedValue.find((selected) => selected.id === item.id)
    );

    return disabled ? (
      option === 'find_more' ? (
        <FindMoreContactsMessage text={value} />
      ) : (
        <NoOptionsMessage inputValue={value} />
      )
    ) : (
      <div
        className={`email-component-select-option ${selectAllBtn ? 'w-100' : ''}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 0',
          backgroundColor: selectAllBtn
            ? undefined
            : isSelected
              ? '#ebf7fb'
              : isFocused
                ? 'rgba(0, 148, 204, 0.04)'
                : 'transparent',
        }}
      >
        {selectAllBtn ? (
          <div
            className='contacts-select-all-button'
            onClick={() => {
              let newContacts = contactsResultOptions;
              let valueToState = [];
              if (selectedAllCondition) {
                valueToState = selectedValue.filter((contact) => !newContacts.find((value) => value.id === contact.id));
              } else {
                newContacts = newContacts.filter((contact) => !selectedValue.some((value) => value.id === contact.id));
                valueToState = [...selectedValue, ...newContacts];
              }
              setTimeout(() => {
                selectRef.current.setValue(valueToState, 'set-value');
                if (setValue) {
                  setValue(valueToState);
                } else {
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'emailSelectedContacts',
                    value: valueToState.map((val) => ({ id: val.id, email: val.email })),
                  });
                }
              }, 10);
            }}
          >
            {selectedAllCondition
              ? `Deselect ${thousandCommas(contactsResultOptions?.length)} matching`
              : `Select ${thousandCommas(contactsResultOptions?.length)} matching`}
          </div>
        ) : (
          <components.Option {...props}>
            <div className='select-option-wrapper'>
              {!disabled && !tagOption && !selectAllBtn && (
                <div className={`custom-checkbox-input`}>
                  <input type={'checkbox'} checked={isSelected} onChange={() => {}} id={`crmContact-${value}-${id}`} />
                  <label htmlFor={`crmContact-${value}-${id}`} />
                </div>
              )}
              {tagOption && <TagIcon />}
              <div className={`contacts-select-option-wrapper ${tagOption && !sideBar ? 'w-100' : ''}`}>
                <CustomHighlighter
                  highlightClassName='highlight-text'
                  searchWord={input}
                  textToHighlight={`${option} ${tagOption ? `(${props.data.contacts})` : ''}`}
                />
              </div>
            </div>
          </components.Option>
        )}
      </div>
    );
  };

  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') {
      setInputValue(query.replace(/\\/g, ''));
    } else {
      return inputValue;
    }
  };

  const NoOptionsMessage = ({ inputValue }) => {
    if (emptyMessage) {
      return emptyMessage;
    }
    return (
      <div
        style={{
          textAlign: `${!!inputValue.length ? 'left' : 'center'}`,
          padding: `15px 0 15px ${!!inputValue.length ? '15px' : 0}`,
        }}
      >
        <p
          style={{
            color: '#999',
            marginBottom: !!inputValue.length ? '-4px' : '5px',
          }}
        >
          {!!inputValue.length ? 'No matching contacts in your ' : 'Search contacts'}
        </p>
        <p
          className='blue-link-underline-select'
          onClick={() => {
            if (!!inputValue.length) {
              navigate('/influence/contacts');
            } else {
              validateContactsOversubscribed(showHideAddContactModal, 'add_custom_contact', 1);
            }
          }}
        >
          {!!inputValue.length ? 'contact list' : 'Add a new contact'}
        </p>
      </div>
    );
  };

  const FindMoreContactsMessage = ({ text }) => {
    return (
      <div style={{ textAlign: 'center', padding: '15px 0' }}>
        <p style={{ color: '#999', marginBottom: '5px', whiteSpace: 'pre-wrap' }}>{text}</p>
      </div>
    );
  };

  return (
    <AsyncSelect
      ref={selectRef}
      inputValue={inputValue}
      onInputChange={onInputChange}
      defaultValue={initialValues}
      loadOptions={loadOptions ?? getContactOptions}
      cacheOptions={true}
      components={{
        Option,
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ClearIndicator: () => null,
        ValueContainer: CustomValueContainer ?? ValueContainer,
        SingleValue: CustomSingleLabel,
        MultiValueLabel: CustomMultiValueLabel,
        Input,
      }}
      isMulti={multi}
      noOptionsMessage={(e) => (e.inputValue.length > 0 ? <NoOptionsMessage inputValue={e.inputValue} /> : null)}
      styles={colourStyles}
      placeholder={placeholder ?? (sideBar ? 'Search by name, email or tag' : '')}
      onChange={handleChange}
      openMenuOnClick={!multi}
      hideSelectedOptions={false}
      classNamePrefix={'react-custom-select-contacts'}
      autoFocus={autoFocus}
      blurInputOnSelect={!multi}
      closeMenuOnSelect={false}
      onSelectResetsInput={false}
      onFocus={(e) => {
        if (disabled) {
          e.target.blur();
        }
      }}
      onKeyDown={onKeyDown}
    />
  );
};

const CrmContactsSelect = ({
  initialValues,
  disabled,
  autoFocus,
  sideBar,
  sentEmail,
  setActiveTab,
  setActiveRecipientsOption,
  setValue,
  multi = true,
  includeTags,
  placeholder,
  loadOptions,
  emptyMessage,
}) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { showAddContactModal, newContactFromSelect, activePlan, emailSelectedContacts } = state;
  const { enableCrm } = activePlan;

  const selectRef = useRef(null);

  const [heightContainer, containerRef] = useHeightContainer();

  useEffect(() => {
    const valueToState = multi
      ? initialValues
        ? initialValues.map((val) => ({ id: val.id, email: val.email }))
        : []
      : '';
    if (setValue) {
      setValue(valueToState);
    } else {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailSelectedContacts',
        value: valueToState,
      });
    }
  }, []);

  useEffect(() => {
    if (newContactFromSelect) {
      selectRef.current.setValue([...selectRef.current.state.selectValue, newContactFromSelect], 'set-value');
      setTimeout(() => {
        selectRef.current.focus();
      }, 100);
    }
  }, [newContactFromSelect]);

  const showHideAddContactModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddContactModal',
      value: !showAddContactModal,
    });
  };

  const SelectedContactsQntty = () => {
    const selectedContacts = emailSelectedContacts.length;
    if (!selectedContacts) {
      return null;
    }
    return (
      <div
        className={`selected-contacts-qntty ${sentEmail ? 'selected-contacts-qntty-sent' : ''}`}
        onClick={() => {
          if (sentEmail) {
            setActiveTab('Recipients');
            setActiveRecipientsOption('All');
          }
        }}
      >
        {thousandCommas(selectedContacts)} recipient{selectedContacts > 1 ? 's' : ''}
      </div>
    );
  };

  const EmptyStateMessage = () => {
    const selectedContacts = emailSelectedContacts.length;
    if (!selectedContacts) {
      return <div className='selected-contacts-qntty-emty-state'>No recipients</div>;
    }
    return null;
  };
  return (
    initialValues && (
      <>
        {showAddContactModal && (
          <>
            {enableCrm ? (
              <AddContactModal showHideAddContactModal={showHideAddContactModal} fromContactsSelect />
            ) : (
              <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={showHideAddContactModal} />
            )}
          </>
        )}
        {sideBar ? (
          <div className={`select-recipient-sidebar ${sentEmail ? 'select-recipient-sidebar-sent' : ''}`}>
            <SelectedContactsQntty />
            <div ref={containerRef}></div>
            <div className={`d-flex flex-centered sidebar-recipients-input-container ${disabled ? 'opacity-6' : ''}`}>
              <div className='flex-grow-1' style={{ height: heightContainer - 25, overflowY: 'auto' }}>
                <CustomAsyncSelect
                  sideBar
                  selectRef={selectRef}
                  disabled={disabled}
                  showHideAddContactModal={showHideAddContactModal}
                  initialValues={initialValues}
                  autoFocus={autoFocus}
                  Input={Input}
                  CustomValueContainer={CustomValueContainer}
                  onKeyDown={(e) => {
                    if (e.keyCode === 8 && e.target.value === '') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <EmptyStateMessage />
          </div>
        ) : (
          <div className='email-component-select-container' data-value={disabled ? 'disabled' : ''}>
            <CustomAsyncSelect
              disabled={disabled}
              selectRef={selectRef}
              showHideAddContactModal={showHideAddContactModal}
              initialValues={initialValues}
              autoFocus={autoFocus}
              Input={multi ? Input : components.Input}
              setValue={setValue}
              multi={multi}
              includeTags={includeTags}
              placeholder={placeholder}
              loadOptions={loadOptions}
              emptyMessage={emptyMessage}
            />
          </div>
        )}
      </>
    )
  );
};

export default CrmContactsSelect;

import React from 'react';
let monitoringData = [
  {
    name: 'Political monitoring portal',
    free: 'yes',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },

  {
    name: 'Email alerts',
    free: 'yes',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },

  {
    name: 'Topics to monitor',
    agenciesName: 'Clients',
    free: '1',
    basic: '1',
    pro: () => (
      <>
        3
        <span
          className='pricing-feature-more'
          data-tooltip-content={
            'The Pro plan includes three<br/> topics, and additional<br/> topics can be purchased<br/> (see above for pricing).'
          }
        >
          More information
        </span>{' '}
      </>
    ),
    mogul: () => (
      <>
        3
        <span
          className='pricing-feature-more'
          data-tooltip-content={
            'The Mogul plan includes three<br/> topics, and additional<br/> topics can be purchased<br/> (see above for pricing).'
          }
        >
          More information
        </span>{' '}
      </>
    ),
    mogulAgencies: 'unlimited',
    custom: 'unlimited',
    hoverText: 'Topics comprise keywords<br/> and phrases related to your<br/> organisation or interests',
    agenciesHoverText: 'hide',
  },

  {
    name: 'Keywords per topic',
    free: 'up to 5',
    basic: '10',
    pro: 'unlimited',
    mogul: 'unlimited',
    custom: 'unlimited',
    hideForAgencies: true,
  },

  {
    name: 'AI summaries',
    hoverText:
      'All political developments will be summarised using AI,<br/> showing tailored information about why the update<br/> is relevant to your organisation and work',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },

  {
    name: 'Advanced keyword logic',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Use Boolean logic to hone in on<br/> the results that matter to you,<br/> and avoid information overload',
  },

  {
    name: 'Website feeds',
    pro: () => (
      <>
        limited
        <span
          className='pricing-feature-more'
          data-tooltip-content={
            'Updates clicked by your website visitors will open the<br/> content in a separate tab in PolicyMogul.'
          }
        >
          More information
        </span>{' '}
      </>
    ),
    // custom: 'unlimited',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Embed a feed of the latest political<br/> developments related to your visitors’<br/> interests, directly on your website',
    agenciesHoverText:
      'Allow your clients to embed a feed<br/> of the latest political developments related to<br/> their visitors’ interests, directly on their website',
  },

  {
    name: 'Integration with Slack',
    mogul: 'yes',
    custom: 'yes',
    hoverText: 'Set up immediate alerts via Slack for any topic',
  },

  {
    name: 'Political Twitter monitoring',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText: 'Twitter monitoring includes<br/> Tweets from parliamentarians<br/> and other political commentators',
  },

  {
    name: 'Early access to news',
    custom: 'yes',
    hoverText:
      'News is shared in advance of publication when<br/> permitted and is conditional on adherence to<br/> distribution requirements. For details on early<br/> access conditions, please contact our team',
  },
];

if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
  let tweetsPosition = monitoringData.findIndex((item) => item.name === 'Political Twitter monitoring');
  monitoringData.splice(tweetsPosition, 1);
}

let emailAlertsData = [
  {
    name: 'Daily & weekly email digest',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },

  {
    name: 'Immediate email alerts',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },
];

let influenceData = [
  {
    name: 'Present lobbying material',
    free: 'yes',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Make research, news releases and other<br/> briefing material available to relevant political<br/> stakeholders and other organisations',
    hideForAgencies: true,
  },
  {
    name: 'Stakeholder mapping',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText: 'Identify the parliamentarians<br/> interested in your issues',
    agenciesHoverText: 'Identify the most relevant and influential<br/> political stakeholders for each client',
  },
  {
    name: 'CRM',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
  },
  {
    name: 'Contacts',
    basic: '50',
    pro: '650',
    mogul: '1,500',
    mogulAgencies: '50',
    custom: 'unlimited',
    customAgencies: '50',
    //customDropdown: true,
    hoverText: `The CRM includes contacts who <br/>can be added to your team's contact list so<br/> that you can email them, write notes,<br/> tag them, export them and take other actions.<br/> Additional contacts can be purchased for £19<br/>per month per 50 contacts`,
  },
  {
    name: 'AI assistant',
    basic: '5 credits / month',
    pro: '10 credits / month',
    mogul: '20 credits / month',
    mogulAgencies: '75 credits / month',
    custom: '100 credits / month',
    customAgencies: '200 credits / month',
    hoverText: `PolicyMogul's writing assistant is powered by OpenAI<br/> and optimised for public affairs work. Use it to write<br/> briefings, send emails to parliamentarians, prepare for<br/> media interviews and more`,
  },
];

let reportsData = [
  {
    name: 'Create reports',
    mogul: 'yes',
    custom: 'yes',
    hoverText: 'Create customised reports on any issue<br/> using political data, analytics and more',
  },

  {
    name: 'Remove PolicyMogul branding',
    custom: 'yes',
    customAgencies: 'no',
    hoverText: `The text 'Powered by PolicyMogul' will<br/> appear at the bottom of reports you create.<br/> On the Enterprise plan, you have the option<br/> to remove this text`,
  },
];

let researchData = [
  {
    name: 'Historic content',
    basic: 'yes',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Use advanced filters and keyword<br/> rules to identify historic political<br/> updates related to any issue',
  },
  {
    name: 'Advanced search',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText: 'Hone in on the results<br/> that matter to you, and avoid<br/> information overload',
  },
  {
    name: 'Analytics',
    mogul: 'yes',
    mogulAgencies: 'no include',
    custom: 'yes',
  },
  /*AE:Left for now, just in case it's needed in the future
  {
    name: 'Insights',
    custom: 'yes',
    hoverText: 'Drill into the specific contributions<br/> which make up any data set',
  }, */
  {
    name: 'Sentiment analysis',
    mogul: 'yes',
    mogulAgencies: 'no include',
    custom: 'yes',
  },
  {
    name: 'Research team',
    custom: 'yes',
    hoverText: 'Our team of parliamentary <br/>experts is on hand to help <br/>with your political research',
  },
];

let embeddablesData = [
  {
    name: 'Embed news feeds',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Embed a feed of political developments directly on your website, featuring real-time updates related to any issue',
    tooltipClass: 'header-tooltip report-popup-tooltip',
  },
  {
    name: 'Embed political data',
    pro: 'yes',
    mogul: 'yes',
    custom: 'yes',
    hoverText:
      'Embed specific charts, or a full analytics dashboard directly on your website, featuring real-time political data related to any issue',
    tooltipClass: 'header-tooltip report-popup-tooltip',
  },
  {
    name: 'Remove PolicyMogul branding',
    custom: 'yes',
    hoverText: `The text 'Powered by PolicyMogul' will appear at the bottom of feeds and charts you embed. On the Enterprise plan, you have the option to remove this text`,
    tooltipClass: 'header-tooltip report-popup-tooltip',
  },
];

let supportData = [
  {
    name: 'Customer support',
    basic: 'email & chat',
    pro: 'email, chat & phone',
    mogul: 'email, chat & phone',
    custom: 'email, chat & phone',
  },

  {
    name: 'Dedicated account manager',
    mogul: 'yes',
    custom: 'yes',
  },
];

let PricingData = [
  {
    name: 'Customer support',
    basic: 'email',
    pro: 'email',
    mogul: 'phone',
    custom: 'phone',
  },

  {
    name: 'Dedicated account manager',
    mogul: 'yes',
    custom: 'yes',
  },
];

export {
  monitoringData,
  emailAlertsData,
  supportData,
  PricingData,
  influenceData,
  researchData,
  reportsData,
  embeddablesData,
};

import React, { useContext } from 'react';
import removeLocalStorage from '../../Dashboard/Navigation/RemoveLocalStorage';
import { AdminStore } from '../AdminState';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom-v5-compat';

const AdminHeader = (props) => {
  const { headerProperites } = props;
  const location = useLocation();
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state ?? {};
  const { name } = organisation ?? {};
  const { logoutWithRedirect, urlLink } = headerProperites;

  return (
    <div className='py-3 px-3 px-lg-0 settings-header'>
      <div className='mx-0 justify-content-between align-items-center row mx-auto main-content-wrapper w-100 px-lg-5 px-3 position-relative'>
        <Link to={urlLink()}>
          <img src={`${process.env.REACT_APP_CDNURL}/images/logo.svg`} alt='Logo' className='policy-logo' />
        </Link>
        {name && location.pathname !== '/admin' && (
          <h3 className='team-header-title main-title mb-0 flex-grow-1'>{name}</h3>
        )}
        <button
          className='general-button logout-button px-4 py-2'
          onClick={() => {
            removeLocalStorage();
            logoutWithRedirect();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default AdminHeader;

import { useEffect, useRef, useState } from 'react';
import { TitleWidget } from '.';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import normalize from '../Dashboard/utilities/normalizeString';
import { hex_is_light } from '../Reports/ui/ReportDesignButton';
import CustomScrollbar from '../Common/CustomScrollbar';

const EmbedAnalyticsPreview = (props) => {
  const graphs = [
    { name: 'Parliamentary mentions', chartName: 'Mentions' },
    { name: 'Most vocal policymakers', shortName: 'Most vocal', image: '20240509-most-vocal-policymakers-preview.jpg' },
    { name: 'Sentiment snapshot' },
    { name: 'Sentiment changes' },
    { name: 'Interest by region', image: '20240505-interest-by-region.jpg' },
    { name: 'Top MPs and peers' },
  ];
  const { selectedTopics, header, keywordsLists, designState, chartTypes, subheader } = props;
  const { headerColour, backgroundColour } = designState ?? {};
  const { agencyUser } = useTopicOrClientWord();

  const [topic, setTopic] = useState(selectedTopics[0]);
  const scrollbarContentContainer = useRef();
  const addScrollClass = () => {
    let el = scrollbarContentContainer?.current?.contentElement?.parentElement;
    if (el?.scrollHeight > el?.clientHeight) {
      el?.classList?.add('element-with-scroll');
    } else {
      el?.classList?.remove('element-with-scroll');
    }
  };

  useEffect(() => {
    addScrollClass();
  }, [chartTypes]);

  useEffect(() => {
    setTopic(selectedTopics[0]);
  }, [selectedTopics]);

  return (
    <>
      <h3 className='topic-title mb-1 text-white' style={{ paddingTop: '12px' }}>
        Example
      </h3>
      <p className='main-subtle-text paragraph-p3 mb-3 text-white' style={{ opacity: '0.8', lineHeight: '1.4' }}>
        The preview below is static and does not represent live data. Once embedded, users will be able to click on
        charts and apply filters
      </p>
      <div className='website-feed-embed-main-container flex-grow-1'>
        <div className='embed-content h-100'>
          <div className='dashboard-root-container rounded border analytics-embed-container'>
            <div
              className='analytics-preview-title'
              style={{ background: headerColour, color: hex_is_light(headerColour) ? '#00122b' : '#fff' }}
            >
              <TitleWidget
                isFromPopup={true}
                selectedTopics={selectedTopics}
                topic={topic}
                setTopic={setTopic}
                analyticsKeywordsLists={keywordsLists.filter((item) => item?.id !== null)}
                agencyUser={agencyUser}
                header={header}
                subheader={subheader}
              />
            </div>
            <div className='flex-grow-1' style={{ background: backgroundColour }}>
              <CustomScrollbar ref={scrollbarContentContainer} className={'simple-scrollbar'} maximalThumbYSize={100}>
                <div className='row mx-0 p-4'>
                  {chartTypes.map((item) => {
                    const value = graphs.find((graph) => graph.name === item || graph.chartName === item);
                    const { name, shortName, image } = value ?? {};
                    const interestByRegionGraph = name === 'Interest by region';
                    const mostVocalWidget = name === 'Most vocal policymakers';
                    const singleGraph = chartTypes.length === 1;
                    return (
                      <div
                        key={`item-${normalize(name)}`}
                        className={`${singleGraph ? 'col-lg-16 preview-analytics-single' : 'col-lg-8 mb-4 '} dashboard-analytics px-3 preview-analytics-embed`}
                      >
                        <div className='h-100 d-block box-outstanding p-4 box-rounded trends-ink-box position-relative'>
                          <div className='flex-centered justify-content-between'>
                            <h3 className='title-h5-m-bold mb-0 z-max position-relative'>{shortName ?? name}</h3>
                            {mostVocalWidget && (
                              <p
                                className='mb-0 border rounded-lg px-3 paragraph-p3 main-light-text'
                                style={{ paddingTop: '5px', paddingBottom: '3px' }}
                              >
                                <span className='dropdown-item-element'>All stakeholders</span>
                              </p>
                            )}
                          </div>
                          <div className={`pt-4 ${!interestByRegionGraph ? 'position-relative' : ''}`}>
                            <img
                              className={`w-100 preview-analytics-image preview-image-${normalize(name)}`}
                              alt={`${name} preview`}
                              src={`${process.env.REACT_APP_CDNURL}/images/${image ?? `20240505-${normalize(name)}-preview.jpg`}`}
                            />
                            <div className='preview-analytics-text'>
                              <p className={`mb-0 title-h5-m main-subtle-text ${interestByRegionGraph ? 'px-4' : ''}`}>
                                Once embedded, users will be able to click on charts and apply filters
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CustomScrollbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmbedAnalyticsPreview;

import { useState, useEffect } from 'react';
const useLoadCompanyImage = (url, size) => {
  const sizeUrl = !!size ? `?size=${size}` : '';
  const cdnUrl = `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`;
  const [imageState, setImageState] = useState({
    loading: true,
    src: cdnUrl,
  });
  useEffect(() => {
    const loadImage = () => {
      let img = new Image();
      let src = `${url}${sizeUrl}`;
      img.onload = () => {
        setImageState({
          loading: false,
          src: src,
        });
      };
      img.onerror = () => {
        setImageState({
          loading: false,
          src: cdnUrl,
        });
      };
      img.src = src;
    };
    if (!!url && url !== '') {
      loadImage();
    } else {
      setImageState({
        ...imageState,
        loading: false,
      });
    }
  }, []);
  return imageState;
};

export default useLoadCompanyImage;

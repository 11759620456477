/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import TagsInput from 'react-tagsinput';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import { store } from '../../Store.js';
import handleChangeKeywords from './Helpers/handeChangeKeywords';

export const clickedOnRemoveTag = (e) =>
  e?.target?.classList && Array.from(e?.target?.classList).includes('react-tagsinput-remove');

const TopicKeywords = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, team, keywordsLists, remainingKeywords, IsSavingKeywordList, editItem } = state;
  const { myself } = team ?? {};
  const { maxNumKeywordsPerTeam, enableKeywordExclusions } = activePlan;
  //AE: Custom topic and customHandleChange are properties to reuse this component outside the topics page scope.
  const { listPosition, parameter, customTopic, customHandleChange, enforceInitialFocus } = props;

  const currentKeywordCategory = customTopic ?? keywordsLists[listPosition];
  const { isMyList } = currentKeywordCategory;
  const valueToModify = currentKeywordCategory[parameter];

  const inputRef = useRef(null);
  const tagsContainer = useRef(null);
  const [editState, setEditState] = useState(false);
  const keywordsElement = useRef(null);

  const allowUserEditCondition = isMyList || myself?.role !== 'Member' || !!customTopic;

  const deactivateBox = () => {
    tagsContainer.current.accept();
    setEditState(false);
  };
  useOutsideElement(keywordsElement, deactivateBox);

  useEffect(() => {
    let urlCondition = !!editItem && editItem === parameter;
    if ((parameter !== 'keywords' && valueToModify.length === 0) || urlCondition || enforceInitialFocus) {
      activeKeywords();
    }
    if (urlCondition) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'editItem', value: '' });
    }
  }, []);

  const changeKeywordsFunction = (trimmedKeywords, newList) => {
    if (customHandleChange) {
      customHandleChange({
        topic: { ...customTopic, [parameter]: trimmedKeywords },
      });
    } else {
      let keywordsListsToRender = keywordsLists.slice();
      keywordsListsToRender[listPosition] = newList;
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: keywordsListsToRender,
      });

      //CALCULATE REMAINING KEYWORDS
      if (remainingKeywords !== 'unlimited' && parameter === 'keywords') {
        let remainingKeywords = 0;
        let position = listPosition;
        let newKeywordLists = [...keywordsLists];
        newKeywordLists.splice(position, 1);
        newKeywordLists.push({ keywords: trimmedKeywords });
        newKeywordLists.forEach((item) => {
          remainingKeywords += item.keywords.length;
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'remainingKeywords',
          value: maxNumKeywordsPerTeam - remainingKeywords,
        });
      }
    }
  };

  const handleChange = (newKeywords, changed, changedIndexes) => {
    const trimmedKeywords = handleChangeKeywords({
      newKeywords,
      changed,
      changedIndexes,
      parameter,
      currentKeywordCategory,
    });
    const newList = {
      ...keywordsLists[listPosition],
      [parameter]: trimmedKeywords,
    };
    changeKeywordsFunction(trimmedKeywords, newList);
  };

  const defaultRenderTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <span
        key={key}
        {...other}
        style={{
          color: `${
            (!isMyList && myself?.role === 'Member' && !customTopic) || IsSavingKeywordList
              ? 'rgba(0, 18, 43, 0.3)'
              : '#00122B'
          }`,
          paddingRight: editState ? '25px' : '10px',
        }}
      >
        {getTagDisplayValue(tag)}
        {!disabled && editState && <button className={classNameRemove} onClick={(e) => onRemove(key)} />}
      </span>
    );
  };

  const defaultRenderLayout = (tagComponents, inputComponent) => {
    return (
      <>
        <div className='d-flex flex-wrap align-items-center'>
          {tagComponents}
          {editState && !IsSavingKeywordList && (
            <>
              {!disabledConditional() && <span></span>}
              <span className='flex-grow-1'>{inputComponent}</span>
            </>
          )}
          {!editState && !IsSavingKeywordList && allowUserEditCondition && (
            <p
              tabIndex={0}
              id='topic-keywords-input'
              className={`${valueToModify.length === 0 ? 'light-text add-keywords-text' : 'edit-item'}`}
              onClick={() => {
                activeKeywords();
              }}
              onFocus={() => {
                activeKeywords();
              }}
            >
              {valueToModify.length === 0 ? `Start typing...` : 'Edit'}
            </p>
          )}
          {((!isMyList && myself?.role === 'Member' && !customTopic) || IsSavingKeywordList) && <span />}
        </div>
      </>
    );
  };

  const activeKeywords = (e) => {
    if (clickedOnRemoveTag(e)) {
      return;
    }
    if (allowUserEditCondition) {
      setEditState(true);
      setTimeout(() => {
        let inputElement = inputRef.current;
        inputElement.focus();
      }, 50);
    }
  };

  const placeholderConditional = () => {
    let placeholder;
    if (parameter === 'keywords') {
      placeholder = `${
        (remainingKeywords > 0 || remainingKeywords === 'unlimited') && editState
          ? valueToModify.length === 0
            ? `Start typing...`
            : ''
          : ''
      }`;
    } else {
      placeholder = `${enableKeywordExclusions ? (valueToModify.length === 0 ? 'Start typing...' : '') : ''}`;
    }
    return placeholder;
  };

  const disabledConditional = () => {
    let disabled;
    if (parameter === 'keywords') {
      const allowKeywords = (!!customTopic || remainingKeywords > 0 || remainingKeywords === 'unlimited') && editState;
      disabled = !allowKeywords;
    } else {
      disabled = !enableKeywordExclusions && !customTopic;
    }
    return disabled;
  };

  const handleInputPaste = useCallback((event) => {
    let clipboard = event.clipboardData,
      text = clipboard.getData('Text');
    if (text.trim().includes('\n')) {
      inputRef.current.value = text.split('\n').join(', ') + ', ';
      setTimeout(() => {
        inputRef.current.value = inputRef.current.value.slice(0, -(text.length - 1));
        tagsContainer.current.accept();
      }, 5);
      setTimeout(() => {
        const newList = {
          ...keywordsLists[listPosition],
          [parameter]: tagsContainer.current.props.value.slice(0, -1),
        };
        changeKeywordsFunction(tagsContainer.current.props.value.slice(0, -1), newList);
      }, 10);
    }
  });

  return (
    <>
      <div
        className={`keywords-category-words keywords-category-${parameter} px-4 pb-4 pt-3`}
        ref={keywordsElement}
        onClick={(e) => {
          if (!editState && !IsSavingKeywordList && allowUserEditCondition) {
            activeKeywords(e);
          }
        }}
      >
        <TagsInput
          ref={tagsContainer}
          onlyUnique={true}
          value={valueToModify}
          onChange={handleChange}
          renderTag={defaultRenderTag}
          renderLayout={defaultRenderLayout}
          addOnBlur
          inputProps={{
            placeholder: placeholderConditional(),
            disabled: disabledConditional(),
            ref: inputRef,
            style: { width: `100%` },
            onPaste: handleInputPaste,
          }}
        />
      </div>
    </>
  );
};

export default TopicKeywords;

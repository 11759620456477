import React, { useState } from 'react';
import { useHeightContainer } from '../../../Dashboard/utilities/useHeightContainer';
import CountriesPopupContainer, { CountriesPopup } from '../../../Settings/Topics/CountriesPopup';
import AdminConfirmDeleteItem from './AdminConfirmDeleteItem';
import AdminTopicKeywords from './AdminTopicKeywords';
import AdminTopicName from './AdminTopicName';
import AdminTopicOwner from './AdminTopicOwner';
import CustomScrollbar from '../../../Common/CustomScrollbar';

const AdminTopicPage = (props) => {
  const { getCurrentTopic } = props;
  let initialTopicInformation = getCurrentTopic();
  const [topicInformation, setTopicInformation] = useState(getCurrentTopic());
  const setValue = ({ property, newValue }) => {
    let newIformation = { ...topicInformation, [property]: newValue };
    setTopicInformation(newIformation);
  };
  const [heightContainer, containerRef] = useHeightContainer();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRegionsPopup, setShowRegionsPopup] = useState(false);

  const setCountries = (value) => {
    setValue({ property: 'countries', newValue: value });
  };

  return (
    <>
      <div className={`pt-4 pt-lg-5`}>
        <div className='settings-box'>
          <AdminTopicName
            {...props}
            topicInformation={topicInformation}
            setValue={setValue}
            setShowDeletePopup={setShowDeletePopup}
            initialTopicInformation={initialTopicInformation}
          />
          <div ref={containerRef} />
          <CustomScrollbar
            className={'topics-scrollbar'}
            style={{ height: `${heightContainer}px` }}
            maximalThumbYSize={100}
          >
            <AdminTopicOwner
              {...props}
              setValue={setValue}
              topicInformation={topicInformation}
              setTopicInformation={setTopicInformation}
            />
            <div className='topic-items-container padding-topic-component pb-5'>
              <h3 className='topic-title mb-2'>Description</h3>
              <input
                type={'text'}
                className={'form-control main-input'}
                value={topicInformation?.description || ''}
                onChange={(e) => {
                  setValue({ property: 'description', newValue: e.target.value });
                }}
              />
              <CountriesPopupContainer
                countries={topicInformation?.countries}
                setCountries={setCountries}
                setShowPopup={setShowRegionsPopup}
              />
              <div className='checkbox-item'>
                <input
                  type='checkbox'
                  id='hybrid-check'
                  checked={topicInformation?.combineHandpickedWithKeywordMatchedContent}
                  value={topicInformation?.combineHandpickedWithKeywordMatchedContent}
                  onChange={(e) => {
                    setValue({ property: 'combineHandpickedWithKeywordMatchedContent', newValue: e.target.checked });
                  }}
                />
                <label htmlFor='hybrid-check'>
                  Hybrid Topic (matches keywords as well as allows for hand-picked content)
                </label>
              </div>
            </div>
            <AdminTopicKeywords {...props} setValue={setValue} currentKeywordCategory={topicInformation} />
          </CustomScrollbar>
        </div>
      </div>
      {showRegionsPopup && (
        <CountriesPopup
          countries={topicInformation?.countries}
          setCountries={setCountries}
          setShowPopup={setShowRegionsPopup}
        />
      )}
      {showDeletePopup && (
        <AdminConfirmDeleteItem setShowPopUp={setShowDeletePopup} topicId={topicInformation?.id} {...props} />
      )}
    </>
  );
};

export default AdminTopicPage;

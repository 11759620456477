import React, { useRef } from 'react';
import ContentRelated from '../key_updates/ContentRelated';
import SkeletonKeyUpdatesContent from '../key_updates/SkeletonKeyUpdatesContent';
import LibraryMaterialMainContent from './LibraryMaterialMainContent';
import { useHeightContainer } from '../utilities/useHeightContainer';
import NavigationLinks from '../components/NavigationLinks';
import CustomScrollbar from '../../Common/CustomScrollbar';

const LibraryMaterialContent = (props) => {
  const {
    isLoading,
    isLoadingRelatedContent,
    LibraryMaterial,
    barNavigationConditional,
    navigationLinks,
    activePlan,
    loadingCreateSummary,
  } = props;
  const { enableSummariser } = activePlan;

  const contentContainer = useRef(null);
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <>
      {isLoading && <SkeletonKeyUpdatesContent />}
      {!isLoading && (
        <div ref={containerRef}>
          <div className='row px-0 piece-content-container'>
            <div className={`general-content-column padding-to-nav p-0`}>
              <div ref={contentContainer} />
              <div className='main-content-section content-container' style={{ height: `${heightContainer}px` }}>
                <CustomScrollbar
                  className={'simple-scrollbar indented-scrollbar-small'}
                  style={{ height: `${heightContainer - (barNavigationConditional ? 43 : 0)}px` }}
                  minimalThumbYSize={55}
                  maximalThumbYSize={100}
                >
                  <LibraryMaterialMainContent
                    {...props}
                    contentContainer={contentContainer}
                    hideSummary={enableSummariser !== undefined && !enableSummariser}
                  />
                </CustomScrollbar>
                <NavigationLinks
                  barNavigationConditional={barNavigationConditional}
                  navigationLinks={navigationLinks}
                  disabled={loadingCreateSummary}
                />
              </div>
            </div>
            <div className='related-content-column show-lg px-0'>
              <ContentRelated
                data={props?.currentNew}
                content={LibraryMaterial?.contentRelated}
                relatedStakeholders={LibraryMaterial?.relatedStakeholders}
                relatedStakeholdersRendered={LibraryMaterial?.relatedStakeholdersRendered}
                section={'Library material'}
                mainSection={'LibraryMaterial'}
                isLoadingRelatedContent={isLoadingRelatedContent}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LibraryMaterialContent;

const createTypeOfResource = (resource) => {
  switch (resource) {
    case 'KeyUpdates':
    case 'Consultations':
    case 'Events':
    case 'PolicyAsks':
      return resource.substring(0, resource.length - 1);
    case 'Tweets':
      return 'tweet';
    case 'Stakeholders':
      return 'stakeholder';
    case 'UpcomingEvents':
      return 'Event';
    case 'SocialMedia':
      return 'tweet';
    case 'LibraryMaterial':
      return 'CommonsLibraryArticle';
    case 'Influence':
      return 'UserContent';
    default:
      return resource;
  }
};

const changeTypeOfResource = (section) => {
  switch (section) {
    case 'PolicyAsks':
    case 'BriefingMaterial':
    case 'LobbyingMaterial':
    case 'Campaigns':
      return 'UserContent';
    case 'LibraryMaterial':
      return 'CommonsLibraryArticle';
    case 'ElectionKeyUpdates':
      return 'KeyUpdate';
    default:
      return createTypeOfResource(section);
  }
};

export { changeTypeOfResource };
export default createTypeOfResource;

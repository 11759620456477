import React from 'react';
import { createRoot } from 'react-dom/client';
import App, { EmbedApp } from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { Auth0Provider } from '@auth0/auth0-react';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRYDSN}`,
  release: `${process.env.REACT_APP_SENTRYRELEASE}`,
  environment: `${process.env.REACT_APP_SENTRYENVIRONMENT}`,
  sampleRate: parseFloat(`${process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE}`),
  tracesSampleRate: parseFloat(`${process.env.REACT_APP_SENTRY_TRANSACTION_SAMPLE_RATE}`),
});

const onRedirectCallback = (appState) => {
  window.location.replace(
    appState && appState.returnTo
      ? `/initialise?redirectUrl=${appState.returnTo}`
      : `/initialise?redirectUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`
  );
};

if (!window.location.pathname.toLowerCase().startsWith('/embed')) {
  ReactGA.initialize(`${process.env.REACT_APP_ANALYTICSCODE}`);
}

const domNode = document.getElementById('root');
const root = createRoot(domNode);

if (!window.location.pathname.toLowerCase().startsWith('/embed')) {
  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          redirect_uri: window.location.origin,
        }}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation={'localstorage'}
        skipRedirectCallback={window.location.pathname === '/slack-oauth-callback'}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
} else {
  //AE:: REMOVE THE AUTH0PROVIDER WHEN WE IMPLEMENT THE LATEST APIS
  root.render(
    <React.StrictMode>
      <EmbedApp />
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
